import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "../CSS/Profile.css"

import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { backendURL } from "../../../constant";

function Profile() {
    const [isShown, setIsShown] = useState(false);
    const navigate = useNavigate();

    const handleClick = (current) => {

        setIsShown(current => !current);


    };
    // const [currentpassword,setCurrentPassword]= useState("");
    const [password, setPassword] = useState({
        cpassword: "",
        password: "",


    });


    const [userdata, setUserdata] = useState({
        photo: "",
        fname: "",
        lname: "",
        email: "",
        password: "",
        DOB: "",
        city: "",
        gender: "",
        mobile: "",

    });


    const handlephoto = (e) => {


        setUserdata({ ...userdata, photo: e.target.files[0] });
        console.log(e.target.files[0]);

        // setSelectedFile(e.target.files[0]);


    }

    const [msg, setMsg] = useState();
    const [pass, setPass] = useState();
    const [errors, setErrors] = useState({});
    const validate = () => {
        let isValid = true;


        const pattern = /^[a-zA-Z]+$/;

        if (!pattern.test(userdata.fname)) {
            isValid = false;
            errors.name = "First Name Only Contain Alphabets";
            setMsg(errors.name);
        }
        else if (!pattern.test(userdata.lname)) {
            isValid = false;
            errors.name = "Last Name Only Contain Alphabets";
            setMsg(errors.name);
        }

        else if (password.password.length < 8) {
            isValid = false;
            errors.passlen = "Password must have atleast 8 Characters";
            setPass(errors.passlen);
        }
        else if (!/[a-zA-Z]/.test(password.password)) {
            isValid = false;
            errors.pass = 'Password should contain at least one letter';
            setPass(errors.pass);
        }

        else if (!/[0-9]/.test(password.password)) {
            isValid = false;
            errors.pass = 'Password should contain at least one digit';
            setPass(errors.pass);
        }

        else if (!/[!@#$%^&*]/.test(password.password)) {
            isValid = false;
            errors.pass = 'Password should contain at least one special character';
            setPass(errors.pass);
        }

        setErrors(errors)
        return isValid;
    }

    const handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name, value);

        setUserdata({ ...userdata, [name]: value });

    }
    const handlepassInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name, value);

        setPassword({ ...password, [name]: value });

    }


    //     const Postpassword = async (e) => {
    //         e.preventDefault();


    //         const isValid = validate();

    //         if (isValid) {

    //         axios.patch(`/UpdatePassword`, {
    //             email: userdata.email,
    //             cpassword: password.cpassword,
    //             password: password.password
    //         })
    //             .then((response) => {
    //                 console.log(response);
    //                 // window.alert("Profile Updated Successfull");
    //                 toast.success('Password Updated Successfully', {
    //                     position: "top-center",
    //                     hideProgressBar: true,
    //                     theme: 'colored'
    //                 });
    //                 console.log("Password Updated Successfull");
    //                 //   navigate("/UserProfile");
    //                 callProfile();
    //             }).catch((error) => {
    //                 console.error(error);
    //                 if (error.response) {
    //                     toast.error(error.response.data.error, {
    //                         position: "top-center",
    //                         hideProgressBar: true,
    //                         theme: 'colored'
    //                     });
    //                 } else {
    //                     toast.error("Failed to Update", {
    //                         position: "top-center",
    //                         hideProgressBar: true,
    //                         theme: 'colored'
    //                     });
    //                 }
    //                 console.log("Failed to Update");
    //             }
    //             )
    //     }

    // }



    const Postdata = async (e) => {
        e.preventDefault();


        const isValid = validate();

        if (isValid) {
            const formData = new FormData();
            formData.append('photo', userdata.photo);
            formData.append('fname', userdata.fname);
            formData.append('lname', userdata.lname);
            formData.append('email', userdata.email);
            formData.append('mobile', userdata.mobile);
            formData.append('gender', userdata.gender);
            formData.append('city', userdata.city);
            formData.append('DOB', userdata.DOB);
            // formData.append('type', userdata.type);

            axios.patch(`${backendURL}/UpdateProfile`, formData)
                .then((response) => {
                    console.log(response);
                    console.log(formData);
                    // window.alert("Profile Updated Successfull");
                    toast.success('Profile Updated Successfully', {
                        position: "top-center",
                        hideProgressBar: true,
                        theme: 'colored'
                    });
                    console.log("Profile Updated Successfull");
                    //   navigate(0);
                    callgetAdmin();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Failed to Update", {
                        position: "top-center",
                        hideProgressBar: true,
                        theme: 'colored'
                    });
                    console.log("Failed to Update");
                });



        }
    }
    const callgetAdmin = async () => {
        try {
            
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
          
          
          
                const requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  credentials: "include",
          
                  redirect: "follow"
                };
          
                fetch(`${backendURL}/Admindash`, requestOptions)
                  .then((response) => response.text())
                  .then(async (result) => {
                    console.log("highlight", result);
          
                    const data = JSON.parse(result);
                    console.log(data);
                    setUserdata(data);
          
                  })
                  .catch((error) => console.error(error));
          
        
            // const res = await fetch('/Admindash', {
            //     method: 'GET',
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json"
            //     },
            //     credentials: "include"
            // });

            // const data = await res.json()
            // // console.log(data.req.cookies.jwtoken);

            // if (res.status === 401) {
            //     const error = new Error(data.error);
            //     throw error;
            // }
            // else if (res.status === 500) {
            //     const error = new Error(data.error);
            //     throw error;
            // }



            // // else if (res.status !== 200) {
            // //     const error = new Error(data.error);
            // //     throw error;
            // // }
            // else if (res.status !== 200) {
            //     const msg = new Error(data.message);
            //     throw msg;
            // }


            // setUserdata(data);
        } catch (err) {
            console.error(err);
            navigate("/AdminLogin");
        }
    }

    useEffect(() => {

        callgetAdmin();
    }, []);

    const picurl = `${backendURL}/public/uploads/`


    return (
        <>
            <ToastContainer />
            {/* <Navbar1 /> */}
            <Sidebar />
            <div className="container rounded bg-white mt-5 mb-5">

                <div className="d-flex flex-wrap justify-content-around">
                    {/* <div className="col-md-3 border-right">
                            <div className="d-flex flex-column align-items-center text-center p-3 py-5"><img className="rounded-circle mt-5" width="150px" src={userdata.photo} alt="UserPhoto" /><span className="font-weight-bold">{userdata.name}</span><span className="text-black-50">{userdata.email}</span>
                                <input name="photo" id="photo" type="file" accept=".jpg , .jpeg , .png" className="form-control" onClick={handlephoto} />
                            </div>
                        </div> */}
                    <div className=" col-md-9">
                        <form onSubmit={Postdata} className=" d-flex flex-wrap" method="PATCH" encType="multipart/form-data" >
                            <div className="col-md-4 border-right">
                                <div className="d-flex flex-column align-items-center text-center p-3 py-5 ">{userdata.image ? <img className="rounded-circle mt-5" width="180px" height={"180px"} src={picurl + userdata.photo} alt="UserPhoto" /> : <img className="rounded-circle mt-5 " width="180px" height="180px" src={"https://www.transparentpng.com/thumb/user/gray-user-profile-icon-png-fP8Q1P.png"} alt="UserPhoto" />}<span className="font-weight-bold">{userdata.fname}</span><span className="text-black-50">{userdata.email}</span>
                                    <input name="photo" id="photo" type="file" accept=".jpg , .jpeg , .png" className="form-control" onChange={handlephoto} />
                                </div>
                            </div>
                            <div className=" col-md-8 border-right p-3 pt-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">Profile Settings</h4>
                                    {/* <Link to="/EditProfile" className="btn btn-success">Edit Profile</Link> */}
                                </div>
                                <div className="row ">
                                    <div className="col-md-6"><label className="labels">First Name</label><input name="fname" id="fname" type="text" className="form-control" placeholder="First Name" value={userdata.name} onChange={handleInput} required /></div>
                                    <div className="col-md-6"><label className="labels">Last Name</label><input name="lname" id="lname" type="text" className="form-control" placeholder="Last Name" value={userdata.lname} onChange={handleInput} required /></div>

                                </div>
                                {msg && <p className="bg-danger fw-bold text-white">{msg}</p>}
                                <div className="row ">
                                    <div className="col-md-12"><label className="labels">Mobile Number</label><input name="mobile" type="tel" pattern="[0-9]{4}[0-9]{7}" className="form-control" placeholder="Format (03123456789)" value={userdata.mobile} onChange={handleInput}></input></div>
                                    <div className="col-md-12"><label className="labels">Date of Birth</label><input name="DOB" type="date" className="form-control" placeholder="DOB" value={userdata.DOB} onChange={handleInput} required></input></div>
                                    {/* <div className="col-md-12"><label className="labels">Address Line 1</label><input type="text" className="form-control" placeholder="enter address line 1" value="">dfds</input></div>
                    <div className="col-md-12"><label className="labels">Address Line 2</label><input type="text" className="form-control" placeholder="enter address line 2" value="">dfds</input></div>
                    <div className="col-md-12"><label className="labels">Postcode</label><input type="text" className="form-control" placeholder="enter address line 2" value="">dfds</input></div>
                    <div className="col-md-12"><label className="labels">State</label><input type="text" className="form-control" placeholder="enter address line 2" value="">dfds</input></div>
                    <div className="col-md-12"><label className="labels">Area</label><input type="text" className="form-control" placeholder="enter address line 2" value="">dfds</input></div> */}
                                    <div className="col-md-12"><label className="labels">Email ID</label><input name="email" type="email" className="form-control" placeholder="Email id" value={userdata.email} onChange={handleInput} readOnly></input></div>
                                    {/* <div className="col-md-12"><label className="labels">Password</label><input type="text" className="form-control" placeholder="Password" value="">dfds</input></div> */}
                                    {/* <div className="col-md-12"><label className="labels">Gender</label><input name="gender" type="text" className="form-control" placeholder="Gender" value={userdata.gender} onChange={handleInput} required></input></div> */}
                                    <div className="col-md-12"><label className="labels">Gender</label><select className="form-control " placeholder="Enter Gender" value={userdata.gender} onChange={handleInput} name="gender" id="gender" required>
                                        <option className="text-secondary"></option>
                                        <option defaultValue="Male">Male</option>
                                        <option defaultValue="Female">Female</option>
                                        <option defaultValue="Others">Others</option>

                                    </select>
                                    </div>

                                </div>
                               
                                <div className="mt-5 text-center"><button type="submit" className="btn btn-primary profile-button">Save Profile</button></div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-3">
                        <div className="p-3 py-5 mt-5 ms-5">
                            <h4>Want to change Password?</h4>
                            <Link className="mt-5 fs-6 text-decoration-none text-primary" onClick={handleClick}>Change Password</Link>

                            {isShown && (
                                <div>
                                    <form method="POST">
                                        <input hidden name="email" value={userdata.email} ></input>
                                        <div className="col-md-12"><label className="labels">Enter Current Password</label><input type="text" name="cpassword" className="form-control" placeholder="Current Password" defaultValue={password.cpassword} onChange={handlepassInput}></input></div>
                                        <div className="col-md-12"><label className="labels">Enter New Password</label><input type="password" name="password" className="form-control" placeholder="New Password" defaultValue={password.password} onChange={handlepassInput} required></input></div>
                                        {pass && <p className="bg-danger fw-bold text-white">{pass}</p>}
                                        <div className="mt-3"><button type="submit" className="btn btn-success profile-button">Update</button></div>
                                    </form>
                                </div>
                            )}

                            {/* <div className="col-md-12"><label className="labels">Mobile Number</label><p type="text" className="form-control" placeholder="enter phone number" value="">dfds</p></div> */}
                            {/* <div className="d-flex justify-content-between align-items-center experience"><span>Edit Experience</span><span className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Experience</span></div><br></br>
                <div className="col-md-12"><label className="labels">Experience in Designing</label><p type="text" className="form-control" placeholder="experience" value="">dfds</p></div> <br></br>
                <div className="col-md-12"><label className="labels">Additional Details</label><p type="text" className="form-control" placeholder="additional details" value="">dfds</p></div>
             */}
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
}

export default Profile;