import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import from 'react-router-dom' instead of 'react-router'
// import SideNavbar from '../../Components/AdminDashboard';
// import AdminDashboard from '../../Components/AdminDashboard';
import logo1 from "../../Photos/Skanslogo1.png"
import house from "../../Photos/house.png"
import homepage from "../../Photos/homepage.png"
import fees from "../../Photos/fees.png"
import FacultyMember from "../../Photos/facultymember.png"
import Onlinetest from "../../Photos/online-test.png"
import schedule from "../../Photos/schedule.png"
import photos from "../../Photos/photos.png"
import stickynotice from "../../Photos/stickynotice.png"
import graduate from "../../Photos/graduate.png"







// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../../Components/sidenav.css';
import "../../CSS/color.css"
import "../../CSS/AdminDash.css"
import Popup1 from '../../Components/Popup1';
import Sidebar from './Sidebar';
import { backendURL } from '../../constant';


function Admindash() {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);


    const togglePopup3 = () => {
        setIsOpen1(!isOpen1);
    }
    const togglePopup4 = () => {
        setIsOpen2(!isOpen2);
    }
    const togglePopup5 = () => {
        setIsOpen3(!isOpen3);
    }
    const togglePopup6 = () => {
        setIsOpen4(!isOpen4);
    }

    const togglePopup7 = () => {
        setIsOpen5(!isOpen5);
    }
    const navigate = useNavigate();

    const [Data, setData] = useState('');


    const callgetAdmin = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            // Retrieve the token from localStorage
            const token = localStorage.getItem("token");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`);
            } else {
                console.error("Token is missing");
                navigate("/AdminLogin");
                return;
            }
    
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                credentials: 'include', // Include credentials if needed
                redirect: "follow"
            };
    
            const response = await fetch(`${backendURL}/Admindash`, requestOptions);
    
            if (response.ok) {
                const result = await response.json();
                console.log("Data:", result);
                setData(result);
            } else if (response.status === 401) {
                console.error("Unauthorized");
                navigate("/AdminLogin");
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error:", error);
            navigate("/AdminLogin");
        }
    };
    

    // const callgetAdmin = async () => {
    //     try {
    //         // const cookies = document.cookie;
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
            
    //         // Retrieve the token from localStorage or wherever it is stored
    //         const token = localStorage.getItem("token");
    //         if (token) {
    //             myHeaders.append("Authorization", `Bearer ${token}`);
    //         } else {
    //             // Handle case where token is missing
    //             console.error("Token is missing");
    //         }
            
    //         const requestOptions = {
    //             method: "GET",
    //             headers: myHeaders,
    //             // credentials: 'include',
    //             redirect: "follow"
    //         };
            
    //         fetch(`${backendURL}/Admindash`, requestOptions)
    //             .then((response) => {
    //                 if (response.ok) {
    //                     return response.json();
    //                 } else if (response.status === 401 || response.status === 500) {
    //                     throw new Error(response.statusText);
    //                 } else {
    //                     throw new Error("Unexpected response status: " + response.status);
    //                 }
    //             })
    //             .then(async (result) => {
    //                 console.log("highlight", result);
    //                 setData(result);
    //             })
    //             .catch((error) => console.error(error));
            

    //         // const res = await fetch('/Admindash', {
    //         //     method: 'GET',
    //         //     headers: {
    //         //         Accept: "application/json",
    //         //         "Content-Type": "application/json"
    //         //     },
    //         //     credentials: "include"
    //         // });

    //         // const data = await res.json()
    //         // // console.log(data.req.cookies.jwtoken);

    //         // if (res.status === 401) {
    //         //     const error = new Error(data.error);
    //         //     throw error;
    //         // }
    //         // else if (res.status === 500) {
    //         //     const error = new Error(data.error);
    //         //     throw error;
    //         // }



    //         // // else if (res.status !== 200) {
    //         // //     const error = new Error(data.error);
    //         // //     throw error;
    //         // // }
    //         // else if (res.status !== 200) {
    //         //     const msg = new Error(data.message);
    //         //     throw msg;
    //         // }



    //     } catch (err) {
    //         console.error(err);
    //         navigate("/AdminLogin");
    //     }
    // }

    useEffect(() => {
        callgetAdmin();
    }, []);


    // const [sidebarActive, setSidebarActive] = useState(false);

    // const toggleSidebar = () => {
    //     setSidebarActive(!sidebarActive);
    // };

    return (
        <>
            <div className={``}>

                {/* Page Content */}
                <div id="" className=''>


                    <div class=" ">
                        {/* <a class="navbar-brand" href="#">Navbar</a> */}
                        {/* <div className='col-lg-4 col-2'> */}
                        {/* <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={toggleSidebar}>
                                    <i className="fas fa-align-left text-white fs-3"></i>
                                    <span className='ps-3 text-white fs-5'>Menu</span>
                                </button> */}
                        <Sidebar />
                        {/* </div> */}

                        {/* <div className='col-lg-2 col-md-3 col-4'>
                                <img className='img-fluid' src={logo1}></img>

                            </div> */}

                        {/* <div className='col-lg-4 col-2'>

                                <ul class="navbar-nav text-white pt-lg-0 pt-4 ">
                                    <Link class="d-flex ps-lg-5 ps-0 ms-5  justify-content-lg-evenly justify-content-between align-items-center text-decoration-none " to="/AdminProfile">
                                        <p class="nav-link active text-white text-capitalize mb-0" aria-current="page" >{Data.name}</p>
                                        <img class=" rounded-circle text-white img-fluid " width={"15%"} src="https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg"></img>
                                    </Link>
                                    

                                </ul>
                           
                                
                            </div> */}

                    </div>


                    <div className='p-3 pt-5'>

                        <div className='text-center d-flex justify-content-center align-items-center flex-wrap'>
                            <h1 className='d-sm-block d-none adminheading  pb-md-0 pb-3 col-md-5 col-sm-8'>Welcome Back</h1>
                            <h2 className='d-sm-none d-block adminheading  pb-md-0 pb-3 col-12'>Welcome Back</h2>
                            <h1 className='col-md-2 col-sm-7 col-10 adminName p-2 px-3 rounded text-uppercase mt-md-0 mt-3 mb-0'>{Data.name}</h1>

                        </div>
                        <div className='d-flex flex-wrap justify-content-around pt-3'>



                            <div class="p-3 cardsbg homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={homepage} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Home</h5>
                                    {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                    <Link className="btn feature-one__button-color px-5" onClick={togglePopup3}>Edit Home</Link>




                                    {isOpen1 && <Popup1
                                        content={<>
                                            <div class="modal-header">
                                                <h5 class="modal-title text-uppercase fw-bold px-4 py-3 text-dark" id="ProgramOfferLabel">Home</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                            </div>
                                            <div className='p-3 bgdarkblue'>
                                                {/* <p className="fw-bold text-light mb-0">Which Degree Programs you want to Manage?</p> */}
                                                <div className='d-flex flex-column text-center'>
                                                    <Link className='btn feature-one__button-color my-3' to='/HomeScreen' >SCREEN IMAGES</Link>
                                                    <Link className='btn feature-one__button-color' to="/HomeHighlights">HIGHLIGHTS</Link>
                                                    <Link className='btn feature-one__button-color my-3' to='/HomeAnnouncement' >ANNOUNCEMENT</Link>
                                                    <Link className='btn feature-one__button-color' to="/HomeHighAchiever">HIGH ACHIEVERS</Link>
                                                    <Link className='btn feature-one__button-color my-3' to="/HomeCounter">Home Counter</Link>


                                                </div>
                                            </div>


                                        </>}
                                        handleClose={togglePopup3}

                                    />}
                                </div>
                            </div>




                            <div class="p-3 cardsbg homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={fees} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Fee Structure</h5>
                                    {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                    <Link className="btn feature-one__button-color px-4" onClick={togglePopup4}>Edit Fee Structure</Link>




                                    {isOpen2 && <Popup1
                                        content={<>
                                            <div class="modal-header">
                                                <h5 class="modal-title text-uppercase fw-bold px-4 py-3 text-dark" id="ProgramOfferLabel">Fee Structure</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                            </div>
                                            <div className='p-3 bgdarkblue'>
                                                {/* <p className="fw-bold text-light mb-0">Which Degree Programs you want to Manage?</p> */}
                                                <div className='d-flex flex-column text-center'>
                                                    <Link className='btn feature-one__button-color my-3 ' to="/AdminFeeStructure"  >CA Fee Structure</Link>
                                                    <Link className='btn feature-one__button-color' to="/AdminACCAFeeStructure">ACCA Fee Structure</Link>
                                                    {/* <Link className='btn feature-one__button-color my-3' to='/AdmissionSchool' >ANNOUNCEMENT</Link>
                 <Link className='btn feature-one__button-color'>HIGH ACHIEVERS</Link> */}
                                                </div>
                                            </div>


                                        </>}
                                        handleClose={togglePopup4}

                                    />}
                                </div>
                            </div>




                            <div class="p-3 cardsbg homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={FacultyMember} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Faculty</h5>
                                    {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                    <Link className="btn feature-one__button-color px-5" onClick={togglePopup5}>Edit Faculty</Link>




                                    {isOpen3 && <Popup1
                                        content={<>
                                            <div class="modal-header">
                                                <h5 class="modal-title text-uppercase fw-bold px-4 py-3 text-dark" id="ProgramOfferLabel">Faculty</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                            </div>
                                            <div className='p-3 bgdarkblue'>
                                                {/* <p className="fw-bold text-light mb-0">Which Degree Programs you want to Manage?</p> */}
                                                <div className='d-flex flex-column text-center'>
                                                    <Link className='btn feature-one__button-color my-3' to='/CAFacultyF8' >CA Faculty F8 Isalamabad</Link>
                                                    <Link className='btn feature-one__button-color mb-3' to='/ACCAFacultyF8'>ACCA Faculty F8 Isalamabad</Link>
                                                    <Link className='btn feature-one__button-color mb-3' to='/CAFacultyRAR'>CA Faculty RAR</Link>
                                                    <Link className='btn feature-one__button-color mb-3' to='/ACCAFacultyRAR'>ACCA Faculty RAR</Link>
                                                    <Link className='btn feature-one__button-color mb-3' to='/CAFacultyST'>CA Faculty ST</Link>
                                                    <Link className='btn feature-one__button-color' to='/ACCAFacultyST'>ACCA Faculty ST</Link>
                                                    <Link className='btn feature-one__button-color' to='/ACCAFacultyPeshawarRoad'>ACCA Faculty Peshawar Road</Link>



                                                    {/* <Link className='btn feature-one__button-color my-3' to='/AdmissionSchool' >ANNOUNCEMENT</Link>
                 <Link className='btn feature-one__button-color'>HIGH ACHIEVERS</Link> */}
                                                </div>
                                            </div>


                                        </>}
                                        handleClose={togglePopup5}

                                    />}
                                </div>
                            </div>





                            <div class="p-3 cardsbg homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={Onlinetest} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Complaint Box</h5>

                                    <Link className="btn feature-one__button-color px-5" to="/AdminComplaintBox">View Complaints</Link>
                                </div>
                            </div>




                            <div class="p-3 homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={schedule} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Calender</h5>
                                    {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                    <Link className="btn feature-one__button-color px-5" onClick={togglePopup6}>Edit Calender</Link>




                                    {isOpen4 && <Popup1
                                        content={<>
                                            <div class="modal-header">
                                                <h5 class="modal-title text-uppercase fw-bold px-4 py-3 text-dark" id="ProgramOfferLabel">Calender</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                            </div>
                                            <div className='p-3 bgdarkblue'>
                                                {/* <p className="fw-bold text-light mb-0">Which Degree Programs you want to Manage?</p> */}
                                                <div className='d-flex flex-column  justify-content-center text-center'>



                                                    <a href="#CALSubmenu1" data-bs-toggle="collapse" aria-expanded="false" className="btn feature-one__button-color">
                                                        Islamabad
                                                        <i class="ps-2 bi bi-chevron-down"></i>
                                                    </a>

                                                    <ul className="collapse list-unstyled px-3" id="CALSubmenu1">
                                                        <li className='d-flex justify-content-between mt-3'>
                                                            <Link className='text-decoration-none thover' to="/AdminF8CACalender" style={{ color: "#FAF9F6" }}>CA Calender<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none mx-3 thover' to="/AdminF8ACCACalender" style={{ color: "#FAF9F6" }}>ACCA Calender<i class=" ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="/AdminCompletedEventF8" style={{ color: "#FAF9F6" }}>Completed Event<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="/AdminUpcomingEventF8" style={{ color: "#FAF9F6" }}>Upcoming Event<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                        </li>

                                                    </ul>

                                                    <a href="#CALSubmenu2" data-bs-toggle="collapse" aria-expanded="false" className="my-3 btn feature-one__button-color">
                                                        Satellite Town
                                                        <i class="ps-2 bi bi-chevron-down"></i>
                                                    </a>

                                                    <ul className="collapse list-unstyled px-3" id="CALSubmenu2">
                                                        <li className='d-flex justify-content-between mt-3'>
                                                            <Link className='text-decoration-none thover' to="/AdminSTCACalender" style={{ color: "#FAF9F6" }}>CA Calender<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none mx-3 thover' to="/AdminSTACCACalender" style={{ color: "#FAF9F6" }}>ACCA Calender<i class=" ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="/AdminCompletedEventST" style={{ color: "#FAF9F6" }}>Completed Event<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="/AdminUpcomingEventST" style={{ color: "#FAF9F6" }}>Upcoming Event<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>

                                                        </li>

                                                    </ul>

                                                    <a href="#CAlSubmenu3" data-bs-toggle="collapse" aria-expanded="false" className=" btn feature-one__button-color">
                                                        RAR Campus
                                                        <i class="ps-2 bi bi-chevron-down"></i>
                                                    </a>

                                                    <ul className="collapse list-unstyled px-3" id="CAlSubmenu3">
                                                        <li className='d-flex justify-content-between mt-3'>
                                                            <Link className='text-decoration-none thover' to="/AdminRARCACalender" style={{ color: "#FAF9F6" }}>CA Calender<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none mx-3 thover' to="/AdminRARACCACalender" style={{ color: "#FAF9F6" }}>ACCA Calender<i class=" ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="/AdminCompletedEventRAR" style={{ color: "#FAF9F6" }}>Completed Event<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="/AdminUpcomingEventRAR" style={{ color: "#FAF9F6" }}>Upcoming Event<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                        </li>

                                                    </ul>

                                                    {/* <Link className='btn feature-one__button-color mb-3'>ACCA Faculty Isalamabad</Link> */}

                                                </div>
                                                {/* <div className='d-flex  text-center'>

                                                    <Link className='btn feature-one__button-color mb-3'>CA Faculty Rawalpindi</Link>
                                                    <Link className='btn feature-one__button-color'>ACCA Faculty Rawalpindi</Link>

                                                </div> */}
                                            </div>


                                        </>}
                                        handleClose={togglePopup6}

                                    />}
                                </div>
                            </div>



                            <div class="p-3 homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={photos} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Life @ SKANS</h5>

                                    <Link className="btn feature-one__button-color px-4" to="/AdminlifeatSkans">Edit Life@SKANS</Link>
                                </div>
                            </div>




                            <div class="p-3 homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={stickynotice} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Noticboard</h5>
                                    {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                    <Link className="btn feature-one__button-color px-5" onClick={togglePopup7}>Edit Noticeboard</Link>


                                    {/*  to="/AdminCollegeNoticeBoard"*/}

                                    {isOpen5 && <Popup1
                                        content={<>
                                            <div class="modal-header">
                                                <h5 class="modal-title text-uppercase fw-bold px-4 py-3 text-dark" id="ProgramOfferLabel">Noticeboard</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                            </div>
                                            <div className='p-sm-3 p-1 bgdarkblue'>
                                                {/* <p className="fw-bold text-light mb-0">Which Degree Programs you want to Manage?</p> */}
                                                <div className='d-flex flex-column  justify-content-center text-center'>



                                                    <Link to="/AdminCollegeNoticeBoard" className="btn feature-one__button-color">
                                                        Colleges
                                                        {/* <i class="ps-2 bi bi-chevron-down"></i> */}
                                                    </Link>

                                                    {/*href="#homeSubmenu1" data-bs-toggle="collapse" aria-expanded="false"<ul className="collapse list-unstyled px-3" id="homeSubmenu1">
                                                        <li className='d-flex justify-content-between mt-3'>
                                                            <Link className='text-decoration-none thover' to="#" style={{ color: "#FAF9F6" }}>CA ISB<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="#" style={{ color: "#FAF9F6" }}>ACCA ISB<i class=" ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="#" style={{ color: "#FAF9F6" }}>CA RWP<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' to="#" style={{ color: "#FAF9F6" }}>ACCA RWP<i class="ms-3 bi bi-box-arrow-up-right"></i></Link>
                                                        </li>

                                                    </ul> */}

                                                    <Link to="/AdminSchoolNoticeBoard" className="my-3 btn feature-one__button-color">
                                                        Schools
                                                        {/* <i class="ps-2 bi bi-chevron-down"></i> */}
                                                    </Link>

                                                    {/*href="#homeSubmenu2" data-bs-toggle="collapse" aria-expanded="false" <ul className="collapse list-unstyled px-lg-3 px-0" id="homeSubmenu2">
                                                        <li className='d-flex flex-column mt-3'>
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>AECHS Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                           
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>PWD Junior Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                           
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>Harley Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                           
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>Chakri Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                        </li>
                                                        <li className='d-flex flex-column '>
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>Westridge Junior Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>Westridge Senior Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>Golra Mor Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                            <div className='border border-top-0 border-bottom-0'></div>
                                                            <Link className='text-decoration-none thover' href="#" style={{ color: "#FAF9F6" }}><span className='d-flex justify-content-between'><p className='mb-0'>Peshawar Road Campus</p><i class="ms-3 bi bi-box-arrow-up-right"></i></span></Link>
                                                        </li>
                                                      

                                                    </ul> */}

                                                    {/* <Link className='btn feature-one__button-color mb-3'>ACCA Faculty Isalamabad</Link> */}

                                                </div>
                                                {/* <div className='d-flex  text-center'>

                                                    <Link className='btn feature-one__button-color mb-3'>CA Faculty Rawalpindi</Link>
                                                    <Link className='btn feature-one__button-color'>ACCA Faculty Rawalpindi</Link>

                                                </div> */}
                                            </div>


                                        </>}
                                        handleClose={togglePopup7}

                                    />}
                                </div>
                            </div>




                            <div class="p-3 homiehover d-flex flex-column  text-center align-items-center card border border-0 shadow-lg mt-4" style={{ width: '18rem' }}>
                                <img className='homieimg' src={graduate} width={"80%"} class="" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Merit List</h5>

                                    <Link className="btn feature-one__button-color px-5" to="/AdminHighAchievers">Edit Merit List</Link>
                                </div>
                            </div>











                        </div>




                        {/* <h2>Collapsible Sidebar Using Bootstrap 4</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>

            <div className="line"></div>

            <h2>Lorem Ipsum Dolor</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>

            <div className="line"></div>

            <h2>Lorem Ipsum Dolor</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>

            <div className="line"></div>

            <h3>Lorem Ipsum Dolor</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admindash;
