import React, { useState, useEffect } from 'react';
import RingLoader from 'react-spinners/RingLoader';
import logo from "./Photos/skansschoolwhite.png"

const LoadingOverlay = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
    <div className="loading-overlay d-flex flex-row-md flex-column ">
      
      {/* <RingLoader
        color={"#fff"}
        loading={isLoading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> */}
<span className='blink-class d-flex justify-content-center'>
  <img width={"25%"} className='img-fluid mt-md-0 pt-5' src={logo}></img>
</span>
      
    </div>
    </>
  );
};

export default LoadingOverlay;
