// import React, { useEffect, useRef } from 'react';
import { FaGlobe, FaRocket } from 'react-icons/fa';
import "../CSS/counter.css"; // You can style the counters using a separate CSS file

// const Counter = () => {
//   const counters = useRef([]);

//   useEffect(() => {
//     counters.current.forEach((counter) => {
//       const targetValue = parseInt(counter.dataset.target, 10);
//       const duration = 3500;
//       const startTime = performance.now();

//       const updateCounter = (currentTime) => {
//         const elapsedTime = currentTime - startTime;
//         const progress = Math.min(elapsedTime / duration, 1);
//         const currentValue = Math.floor(progress * targetValue);

//         counter.textContent = currentValue;

//         if (progress < 1) {
//           requestAnimationFrame(updateCounter);
//         }
//       };

//       requestAnimationFrame(updateCounter);
//     });
//   }, []);

//   return (
//     <div className="container bg-lightgrey">
//       <div className="row">
//         <div className="col-md-3 col-sm-6">
//           <div className="counter">
//             <div className="counter-icon">
//               <FaGlobe />
//             </div>
//             <span
//               className="counter-value"
//               ref={(el) => counters.current.push(el)}
//               data-target="320"
//             >
//               0
//             </span>
//             <h3>Web Designing</h3>
//           </div>
//         </div>
//         <div className="col-md-3 col-sm-6">
//           <div className="counter red">
//             <div className="counter-icon">
//               <FaRocket />
//             </div>
//             <span
//               className="counter-value"
//               ref={(el) => counters.current.push(el)}
//               data-target="302"
//             >
//               0
//             </span>
//             <h3>Web Development</h3>
//           </div>
//         </div>
//         <div className="col-md-3 col-sm-6">
//           <div className="counter green">
//             <div className="counter-icon">
//               <FaRocket />
//             </div>
//             <span
//               className="counter-value"
//               ref={(el) => counters.current.push(el)}
//               data-target="3025"
//             >
//               0
//             </span>
//             <h3>Web Development</h3>
//           </div>
//         </div>
//         <div className="col-md-3 col-sm-6">
//           <div className="counter blue">
//             <div className="counter-icon">
//               <FaRocket />
//             </div>
//             <span
//               className="counter-value"
//               ref={(el) => counters.current.push(el)}
//               data-target="301"
//             >
//               0
//             </span>
//             <h3>Web Development</h3>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Counter;


// import React, { useEffect, useRef } from 'react';
// import { CountUp } from 'https://cdnjs.cloudflare.com/ajax/libs/countup.js/2.0.7/countUp.js';

// const Counter = () => {
//   const counterRefs = useRef([]);

//   useEffect(() => {
//     counterRefs.current.forEach((counterRef) => {
//       if (counterRef) {
//         const startValue = 0;
//         const endValue = parseInt(counterRef.getAttribute('data-value'), 10);
//         const duration = 5000;
//         const startTime = Date.now();

//         const updateCounter = () => {
//           const currentTime = Date.now();
//           const elapsedTime = currentTime - startTime;

//           if (elapsedTime < duration) {
//             const progress = elapsedTime / duration;
//             const currentValue = Math.ceil(startValue + progress * (endValue - startValue));
//             counterRef.textContent = currentValue;
//             requestAnimationFrame(updateCounter);
//           } else {
//             counterRef.textContent = endValue;
//           }
//         };

//         requestAnimationFrame(updateCounter);
//       }
//     });
//   }, []);







import React, { useEffect, useState } from 'react';
import CountUp from "react-countup"
import ScrollTrigger from "react-scroll-trigger"
import { backendURL } from '../constant';

const Counter = () => {
  const [counton, setCounton] = useState(false);
  const [counter, setCounter] = useState({
    counter1: 0,
    counter2: 0,
    counter3: 0,
    counter4: 0,
  });

  const callCounter = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomecounter`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          if (data.length > 0) {
            setCounter(data[0]); // Access the first object in the array
          }

        })
        .catch((error) => console.error(error));
        
      
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    callCounter();
  }, []);

  return (
    <ScrollTrigger onEnter={() => setCounton(true)} onExit={() => setCounton(false)}>
      <div className="container">
        <div className="d-flex flex-wrap justify-content-around">
          {counter ? (
            <>
              <div className="col-md-3 col-sm-6" data-aos="fade-right">
                <div className="counter">
                  <div className="counter-icon"><i className="fas fa-user-graduate fs-1"></i></div>
                  <span className="counter-value">
                    {counton && <CountUp start={0} end={counter.counter1} duration={2} delay={0} />}
                    +
                  </span>
                  <p className="title js-count-up" data-value="3456"></p>
                  <h3>Happy Students</h3>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" data-aos="fade-up">
                <div className="counter red">
                  <div className="counter-icon"><i className="fa fa-university fs-1"></i></div>
                  <span className="counter-value">
                    {counton  && <CountUp start={0} end={counter.counter2} duration={2} delay={0} />}
                    +
                  </span>
                  <h3>Cool Campuses</h3>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" data-aos="fade-down">
                <div className="counter green">
                  <div className="counter-icon"><i className="fs-1 fas fa-chalkboard-teacher"></i></div>
                  <span className="counter-value">
                    {counton  && <CountUp start={0} end={counter.counter3} duration={2} delay={0} />}
                    +
                  </span>
                  <h3>Experienced Faculty</h3>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" data-aos="fade-left">
                <div className="counter blue">
                  <div className="counter-icon"><i className="fa-solid fa-users fs-1"></i></div>
                  <span className="counter-value">
                    {counton  && <CountUp start={0} end={counter.counter4} duration={2} delay={0} />}
                    +
                  </span>
                  <h3>Enthusiastic Employers</h3>
                </div>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Counter;
