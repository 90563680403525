import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function ViewComplaint() {
    const { id } = useParams();


    const [Complaint, setComplaint] = useState({
        name: "",
        email: "",
        mobile: "",
        campus: "",
        complaint: ""
    });

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewComplaint/${id.trim()}`);
            const announcementData = response.data;
            setComplaint(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);


    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fontcolordark fs-1 text-center text-uppercase">Complaint Box Detail</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-md-5 mx-0 pb-1 col-md-11 shadow-lg my-4 ">
                    
                        <div className="container pt-5">
                            <p><span className='fw-bold'>Name: </span>{Complaint.name}</p>
                            <p><span className='fw-bold'>Email: </span>{Complaint.email}</p>
                            <p><span className='fw-bold'>Mobile: </span>{Complaint.mobile}</p>
                            <p><span className='fw-bold'>Campus: </span>{Complaint.campus}</p>
                            <p><span className='fw-bold'>Complaint: </span>{Complaint.complaint}</p>
                            
                        </div>

                    
                </div>
            </div>
        </>
    )
}

export default ViewComplaint;
