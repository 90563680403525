import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/CAFeeStructure.css"
import { backendURL } from '../../constant';
import CollegeNavbar from '../../Components/CollegeNavbar';

function ACCAFeeStructure() {
    const [detail, setDetail] = useState([]);

    const callProfile = async () => {
        try {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getACCAFeeStructure`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                console.log("highlight", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);

      
              })
              .catch((error) => console.error(error));
            // const res = await fetch("/getACCAFeeStructure", {
            //     method: "GET",
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            //     credentials: "include",
            // });

            // if (res.status === 200) {
            //     const data = await res.json();
            //     console.log(data);
            //     setDetail(data);
            //     console.log(detail);
            // } else {
            //     const error = new Error(res.statusText);
            //     throw error;
            // }
        } catch (err) {
            console.log(err);

        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>

            <CollegeNavbar />
            <div className='FeeStructurebg'>
                <div className=' container-fluid ps-sm-5 ps-0 text-light filter15 text-center'>
                    <p >Home / ACCA FEE STRUCTURE</p><br></br>
                    <h1 className='fw-bold'>ACCA FEE STRUCTURE</h1>
                    {/* <p className='fs-2 fw-bold'>(COMPUTER BASED EXAM)</p> */}
                </div>
            </div>

            <div className=''>

            

            <div className='container py-5 '>

                <table class="table2">
                    <tr class="tr2">
                        <th class="th2">Paper</th>
                        <th class="th2 text-center">Part</th>
                        <th class="th2 text-center">Tuition Fee(PKR)</th>
                        <th class="th2 text-center">CBE Fee(£)</th>


                    </tr>

                    {Array.isArray(detail) && detail.length > 0 ? (
                        detail.map((ann, id) => (
                            // Add a condition to check if the type is "rwp"
                           
                                    <tr class="tr2" key={id}>
                                        <td class="td2 p-sm-2 p-1 fw-bold">{ann.paper}</td>
                                        <td class="td2 text-center p-sm-2 p-1">{ann.part}</td>
                                        <td class="td2 text-center p-lg-2 p-1 text-success fw-bold">{ann.tuition_fee}/-</td>
                                        <td class="td2 p-lg-2 p-1 text-center text-success fw-bold">{ann.cbe_fee}</td>


                                    </tr>


                        ))
                    ) : (

                        <p>Not Available</p>
                    )}

                </table>


            </div>
            </div>

        </>
    )
}

export default ACCAFeeStructure
