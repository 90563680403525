import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/GulzareQuaid.css"
import Junior from "../../Photos/juniorHarley.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"

import peshawarroad from "../../Photos/bgpeshawarroad.jpeg"
import STCampus from "../../Photos/STCAMPUS.png"
import RARCampus from "../../Photos/RAR campus.jpeg"
import { currentYear } from '../../constant'
import { Link } from 'react-router-dom'
import CollegeNavbar from '../../Components/CollegeNavbar'

function F8Campus() {
    return (
        <>

            <CollegeNavbar />
            <div className='AdmissionF8bg'>
                <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-md-1 fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>F8 Islamabad CAMPUS <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
                            <h2 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>F8 Islamabad CAMPUS <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>


                            <div className='fw-bold fs-md-1 fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className=''>
                <div className='container-fluid'>
                    <div className='container d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded' data-aos="zoom-in">
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 text-uppercase d-md-block d-none text-center'>SKANS F8 Islamabad CAMPUS</h3>&nbsp;&nbsp;
                            <h6 className='text-light fw-bold mb-0 text-uppercase d-md-none d-block text-center'>SKANS F8 Islamabad CAMPUS</h6>&nbsp;&nbsp;

                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>



                    <div>
                        {/* <p><span className='fw-bold'>Address:</span> House No 53-B Harley Street Campus – Rawalpindi</p>
                    <p><span className='fw-bold'>Email: </span>            <a className='text-decoration-none' href=""> school.info@skans.pk</a></p>
                    <p><span className='fw-bold'>Mobile:  </span>          (+92)-332-8922225</p>
                    <p><span className='fw-bold'>Telephone: </span>    (+92)-51-5176138</p>
                    <p><span className='fw-bold'>Telephone 1: </span>  (+92)-51-5176066</p> */}



                        <div className='d-flex flex-wrap justify-content-around'>
                            <div className='col-lg-3 col-12 p-3 bg-lightgrey rounded-4 mt-lg-0 mt-3' data-aos="fade-right">
                                <h4 className='fw-bold text-center fontcolordark'>Quick Links</h4>
                                <ul className="nav nav-tabs d-flex flex-column fontsmaller" >

                                    <li className="nav-item quicklink p-0 rounded-3">
                                        <Link className="nav-link d-flex justify-content-between  " to="/CollegeAdmission">
                                            Admissions
                                            <i class="bi bi-arrow-right  fs-5 fw-bold text-info"></i>
                                        </Link>

                                    </li>
                                    <li className="nav-item quicklink p-0 rounded-3">
                                        <Link className="nav-link  d-flex justify-content-between  black" to="/CAFeeStructure">
                                            CA Fee Structure
                                            <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                        </Link>
                                    </li>
                                    <li className="nav-item quicklink p-0 rounded-3">
                                        <Link className="nav-link  d-flex justify-content-between  black" to="/ACCAFeeStructure">
                                            ACCA Fee Structure
                                            <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                        </Link>
                                    </li>
                                    <li className="nav-item quicklink p-0 rounded-3">
                                        <Link className="nav-link  d-flex justify-content-between  black" to="/F8IslamabadNoticeBoard">
                                            Noticeboard
                                            <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                        </Link>
                                    </li>
                                    <li className="nav-item quicklink p-0 rounded-3">
                                        <Link className="nav-link  d-flex justify-content-between  black" to="/EventIslamabad">
                                            Events
                                            <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                            <div className='col-lg-8 col-12'>
                               <table className='table table-light table-striped ' data-aos="fade-up">

                                <th className='fw-bold fs-2 th1 ps-3 rounded-top' colspan="2">Contact Information</th>

                                <tbody>
                                    <tr>
                                        <td className='fw-bold fs-5 text-center'>Address:</td>
                                        <td>14A-Haroon Building F8, Markaz Islamabad</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold fs-5 text-center'>Email:</td>
                                        <td><a href="mailto:inquiry@skans.pk">inquiry@skans.pk</a></td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold fs-5 text-center'>Telephone 1:</td>
                                        <td>(+92)-51-2287313</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold fs-5 text-center'>Telephone 2:</td>
                                        <td>(+92)-51-2287315</td>
                                    </tr>
                                    {/* <tr>
                            <td className='fw-bold fs-5'>Telephone 1:</td>
                            <td>(+92)-51-5176066</td>
                        </tr> */}
                                </tbody>
                            </table> 
                            </div>
                            
                        </div>


                        <div className='mt-5 container'>
                            <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13275.589552305766!2d73.035687!3d33.711604!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbe531e328d2d%3A0x2d7c5767270ff30!2sSkans%20School%20of%20Accountancy!5e0!3m2!1sen!2sus!4v1696331851547!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                            {/* <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                        </div>
                    </div>





                    <div className='container'>
                        <div className='d-flex align-items-center ' data-aos="fade-up">
                            <div className='b'>

                            </div>
                            <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                                <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                <h3 className='text-light fw-bold mb-0 d-flex align-items-center'> <span class=" fw-bold fs-1 pe-3"> + </span> OTHER CAMPUSES</h3>&nbsp;&nbsp;
                                <img height={"10%"} src={Sectiontitle2}></img>
                            </div>
                            <div className='b'>
                            </div>
                        </div>



                        <p className='text-center opacity-75 fw-bold fs-5' data-aos="flip-right">We build your child’s future, view our branches near you so we will be in your reach.</p>

                        <div class="row row-cols-1 row-cols-md-3 g-4 d-flex justify-content-around">
                            <div class="col" data-aos="fade-right">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img className='card-img' src={RARCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Raja Akram Road, Saddar Campus</h5>
                                        <p class="card-text">16-Raja Akram Road, Saddar, Rawalpindi Cantt</p>
                                        <p className=' fw-bold'>(+92)-51-5125148–49<br></br>
                                            (+92)-51-5125155<br></br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img className='card-img' src={STCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Satellite Town Campus</h5>
                                        <p class="card-text">House No. 77-A,Block A,Satellite Town Rehmanabad
                                            Rawalpindi<br></br>

                                        </p>
                                        <p className=' fw-bold'>
                                            (+92)-51-2716089
                                            <br></br>
                                            <br></br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col" data-aos="fade-left">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={peshawarroad} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">ACCA Peshawar Road Campus</h5>
                                        <p class="card-text">304 Bangash Plaza Near Maryam Memorial Hospital Peshawar Road, Rawalpindi</p>
                                        <p className=' fw-bold'>051 5490876<br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col">
                        <div class="card border-0 shadow-lg rounded-5">
                            <img src={Junior} class="card-img-top rounded-top-5" alt="..." />
                            <div class="card-body">
                                <h5 class="fontcolordark fw-bold">Gulzar-e-Quaid Campus</h5>
                                <p class="card-text">
                                    House # 119/2, Street # 4,<br></br>
                                    Sector-2, Airport Housing Society,<br></br>
                                    Gulzar-e-Quaid,<br></br>
                                    Rawalpindi
                                </p>
                                <p  className=' fw-bold'>
                                    (+92)-51-5497155<br></br>
                                </p>
                            </div>
                        </div>
                    </div> */}

                        </div>

                    </div>

                </div>




                <div className='bgdarkblue py-4 mt-4'>
                    <div className='d-flex justify-content-around text-white'>
                        <div>
                            © Copyright {currentYear} - All rights reserved
                        </div>
                        <div>
                            <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                        </div>

                    </div>


                </div>
            </div>

        </>
    )
}

export default F8Campus
