import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/GulzareQuaid.css"
import Junior from "../../Photos/juniorHarley.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"


import SeniorWestridge from "../../Photos/SeniorWestridgeCampus.png"
import JuniorWestridge from "../../Photos/JuniorWestridge.png"
import HarleyCampus from "../../Photos/HarleyCampus.png"
import ChakriCampus from "../../Photos/ChakriCampus.png"
import GolraCampus from "../../Photos/GolraMor.png"
import PWDpic from "../../Photos/PWDcampus.JPG"
import AECHSCampus from "../../Photos/AECHSCampus.png"
import skanspeshawarroadCampus from "../../Photos/skanspeshawarroad.png"
import { currentYear } from '../../constant'
import SchoolNavbar from '../../Components/SchoolNavbar'
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules'
import {  Swiper,SwiperSlide } from 'swiper/react'


function PWDCampus() {
    return (
        <>

            <SchoolNavbar />
            <div className='PWDbg'>
                <div className='  container-fluid  ps-md-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>SKANS PWD CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h1>
                            <h3 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>SKANS PWD CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h3>
                            
                            <div className='fw-bold fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='bg-lightgrey'>
            <div className='container-fluid'>
                <div className='d-flex align-items-center ' data-aos="fade-up">
                    <div className='b'>

                    </div>
                    <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                        <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                        <h3 className='text-light fw-bold mb-0 d-md-block d-none'>SKANS PWD CAMPUS</h3>&nbsp;&nbsp;
                        <h5 className='text-light fw-bold mb-0 d-md-none d-block text-center'>SKANS PWD CAMPUS</h5>&nbsp;&nbsp;
                        
                        <img height={"10%"} src={Sectiontitle2}></img>
                    </div>
                    <div className='b'>
                    </div>
                </div>


                <div className='d-flex flex-wrap justify-content-evenly'>
                        <div className='col-lg-3 col-6 ms-lg-5 ms-0'>
                        <img className='img-fluid ' src={require("../../Photos/PWDPrincipal.jpeg")} style={{ objectFit: 'cover', width: '80%', height: '100%' }}/>

                            {/* <img className='img-fluid align-self-stretch' src={require("../../Photos/HarleyCampusPrincipal.jpeg")} style={{ objectFit: 'cover', width: '100%', height: '100%' }}></img> */}
                        </div>
                        <div className='col-lg-8 col-12  pt-3'>

                            <blockquote class="otro-blockquote">
                            <span>Welcome to SKANS School System PWD Campus,</span>As the Vice Principal of PWD Campus, I firmly believe in working together with our teachers, staff, parents, and students. By sharing best practices, new ideas, and innovative approaches through open and honest communication, we can create the best learning experience for everyone. We are all lifelong learners, always exploring and improving new teaching methods and strategies. I aim to strengthen our school culture so that every member, from students to teachers, can celebrate our shared achievements. Together, we can build a successful and supportive educational community.
                                <span>Principal:<br></br>Ms. Sana Asim</span>
                            </blockquote>
                        </div>
                    </div>

                    {/* <p className='container py-5 text-justify lh-lg'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p> */}
                   
                    <div className="swiper-container my-5">
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={4} // Display 4 slides at once
                            spaceBetween={30}
                          


                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                                
                            }}
                            pagination={true}
                            autoplay={{ delay: 2000 }}
                            modules={[EffectCoverflow, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery1.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery2.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery3.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery4.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery5.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery6.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery7.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery8.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery9.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery10.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery11.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery12.jpeg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/PWDgallery13.jpeg")} />
                            </SwiperSlide>
                            
                        </Swiper>
                    </div>



                <div className='container'>
                    {/* <p><span className='fw-bold'>Address:</span> House No 53-B Harley Street Campus – Rawalpindi</p>
                    <p><span className='fw-bold'>Email: </span>            <a className='text-decoration-none' href=""> school.info@skans.pk</a></p>
                    <p><span className='fw-bold'>Mobile:  </span>          (+92)-332-8922225</p>
                    <p><span className='fw-bold'>Telephone: </span>    (+92)-51-5176138</p>
                    <p><span className='fw-bold'>Telephone 1: </span>  (+92)-51-5176066</p> */}




                    <table className='table table-light table-striped' data-aos="fade-up">
                        
                            <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>
                        
                        <tbody>
                            <tr>
                                <td className='fw-bold fs-5'>Address:</td>
                                <td>233, Block A Sector A PWD, Islamabad, Punjab</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Email:</td>
                                <td><a href="mailto:school.info@skans.pk">school.info@skans.pk</a></td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Mobile:</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone:</td>
                                <td>(051) 8773775</td>
                            </tr>
                            {/* <tr>
                            <td className='fw-bold fs-5'>Telephone 1:</td>
                            <td>(+92)-51-5176066</td>
                        </tr> */}
                        </tbody>
                    </table>



                    <div className='mt-5'>
                    <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106318.69717320455!2d73.02391750129357!3d33.61933143040843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfed431e1b520d%3A0xf2f2788e3d47e5c2!2sskans%20pwd%20campus!3m2!1d33.5728222!2d73.14698539999999!5e0!3m2!1sen!2s!4v1712122103531!5m2!1sen!2s" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>





                <div className='container'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 d-flex align-items-center text-center'> <span class=" fw-bold fs-1 pe-3"> + </span> OTHER CAMPUSES</h3>&nbsp;&nbsp;
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>



                    <p data-aos="flip-right" className='text-center opacity-75 fw-bold fs-5'>We build your child’s future, view our branches near you so we will be in your reach.</p>

                    <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={HarleyCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Harley Street Campus</h5>
                                    <p class="card-text">House No 53-B Harley Street Campus – Rawalpindi</p>
                                    <p className=' fw-bold'>(+92)-332-8922225<br></br>
                                        (+92)-51-5176138</p>
                                </div>
                            </div>
                        </div>
                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={GolraCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Golra Mor Campus</h5>
                                    <p class="card-text">Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad
                                    </p>
                                    <p className=' fw-bold'>
                                        (051) 2227475
                                        <br></br><br></br>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={ChakriCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior  Chakri Road Campus</h5>
                                    <p class="card-text pb-0">Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>0310-3331131<br></br>
                                        051- 5575082
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={JuniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior Westridge Campus</h5>
                                    <p class="card-text pb-0">H.No. 9-B, Hali Road, Westridge-1, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>051-8773091<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>



                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={SeniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Senior Westridge Campus</h5>
                                    <p class="card-text pb-0">91 Hali Road, Street no. 8, Westridge-1, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8355546<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={skanspeshawarroadCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Peshawar Road Campus</h5>
                                    <p class="card-text pb-0">House No. 308-A, Main Peshawar Road, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8890534<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={PWDpic} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">PWD Campus</h5>
                                    <p class="card-text pb-0">233, Block A Sector A PWD, Islamabad, Punjab
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8773775<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={AECHSCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">AECHS Campus</h5>
                                    <p class="card-text">37 Street 17, Sector 1 Airport Employees CHS, Rawalpindi
                                    </p>
                                    <p className=' fw-bold'>
                                        (051) 8468475
                                        <br></br><br></br>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>




            <div className='bgdarkblue py-4 mt-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
            </div>

        </>
    )
}

export default PWDCampus
