import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function HighAchievers() {
    const navigate = useNavigate();

    const [achiever, setAchiever] = useState({
        photos: "",
        name: "",
        description:""

    });
    const [detail, setdetail] = useState({})

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAchiever({ ...achiever, [name]: value });
    };
    const handlePhotoChange = (e) => {
        setAchiever({ ...achiever, photos: e.target.files[0] });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("photos", achiever.photos);
            formData.append("name", achiever.name);
            formData.append("description", achiever.description);

      
            const response = await axios.post(`${backendURL}/HighAchievers`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAchiever({
                photos: "",
                name: "",
                description:""
            }); // Clear the input fields after successful upload
            setTimeout(() => {
                navigate("/AdminHighAchievers");
            }, 100);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = useCallback( async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getHighAchievers`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setdetail(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    },[navigate,setdetail])

    const deleteFaculty = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteHighAchievers/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Member Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, [callProfile]);
    const picurl = `${backendURL}/public/uploads/`;
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <h1 className="text-center fw-bold fontcolordark">Merit List</h1>
                <form method="POST" className="pt-3" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">

                        <div className="col-md-5 border-right">
                            <input
                                name="photos"
                                id="photos"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handlePhotoChange}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="name"
                                type="text"
                                id="name"
                                className="form-control"
                                placeholder="Enter name here"
                                value={achiever.name}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="description"
                                type="text"
                                id="description"
                                rows="2"
                                className="form-control"
                                placeholder="Enter description here"
                                value={achiever.description}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        

                        

                    

                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Member
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        {Array.isArray(detail) && detail.length > 0 ? (
                            <table className="table2">
                                
                                    <tr className="tr2">
                                        <th className="td2 p-sm-2 p-1">#</th>
                                        <th className="td2 p-sm-2 p-1">Photo</th>
                                        <th className="td2 p-sm-2 p-1">Name</th>
                                        <th className="td2 p-sm-2 p-1">Action</th>
                                    </tr>
                                
                                    {detail.map((ann, id) => (
                                        <tr key={id} className="tr2">
                                            <td  className="td2 p-sm-2 p-1 fw-bold">{id + 1}</td>
                                            <td className="td2 p-sm-2 p-1">
                                                <img
                                                    className="img-fluid"
                                                    width="100"
                                                    src={picurl + ann.photos}
                                                    alt={ann.name}
                                                />
                                            </td>
                                            <td className="td2 p-sm-2 p-1">{ann.name}</td>
                                            <td className="td2 p-sm-2 p-1">
                                                <Link to={`/UpdateHighAchievers/${ann.id}`} className=" me-3 ">
                                                <i className="bi bi-pencil-square fs-5 text-primary"></i>
                                                </Link>
                                                <Link
                                                    onClick={() => deleteFaculty(ann.id)}
                                                    className=""
                                                >
                                                    <i className="bi bi-trash fs-5 text-danger"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                
                            </table>
                        ) : (
                            <p>Not Available</p>
                        )}
                    </div>


                </form>
            </div>
        </>
    );
}

export default HighAchievers;
