import React from 'react'
import Navbar from '../Components/navbar'
import "../CSS/Facilities.css"
import multimedias from "../Photos/multimedias.jpg"
import PlayArea from "../Photos/PlayArea.jpg"
import ComputerLab from "../Photos/computerLab.jpg"
import PrePlay from "../Photos/preplay.jpg"
import ActivityRoom from "../Photos/ActivityRoom.jpg"
import Library from "../Photos/Library.jpg"
import Sectiontitle1 from "../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import { currentYear } from '../constant'
import SchoolNavbar from '../Components/SchoolNavbar'


function Facilities() {
  return (
    <>
      <SchoolNavbar />
      <div className='facilitybg'>
        <div className='container-fluid filter13 ps-5 text-light filter3 text-center'>
          <p className='fs-5'>Home / Facilities</p><br></br>
          <h1 className='fw-bold d-md-block d-none'>FACILITIES</h1>
          <h3 className='fw-bold d-md-none d-block fs-2'>FACILITIES</h3>


        </div>


      </div>


      <div data-aos="fade-up" className='mt-5 container bg-lightgrey py-md-5 py-3 border border-top-0 border-bottom-0 border-end-0 border-5 rounded-5 border-primary'>
        {/* <h2 className='fw-bold textdarkblue'>CA is offered at:</h2> */}
        <div className='d-md-block d-none' >
          <p className='fs-5 fw-bold'>By three methods we may learn wisdom: First, by reflection, which is noblest; second by imitation, which is easiest; and third by experience, which is the bitterest</p>
          <p className='fs-2 textdarkblue fw-bold'>    - By Confucius.</p>
        </div>
        <div className='d-md-none d-block'>
          <p className='fs-6 fw-bold'>By three methods we may learn wisdom: First, by reflection, which is noblest; second by imitation, which is easiest; and third by experience, which is the bitterest</p>
          <p className='fs-3 textdarkblue fw-bold'>    - By Confucius.</p>
        </div>


      </div>


      <div className='container fs-6 mt-5 lh-lg text-justify'>

        <p data-aos="fade-up">Learning is a continuous art and a reflection of what we are. We believe in being the best and in our pursuit to becoming the best SKANS has made a significant investment providing the best environment to our scholars. We have a well-equipped range of facilities and resource for all students from kindergarten to matriculation to help them achieve a well-rounded education in a child-friendly atmosphere. A fully equipped library, a well-established Computer, Chemistry, physics and Biology laboratories. It is our vision that each student enjoys their years of education</p>

      </div>

      <div className="container fs-6 my-5 lh-lg text-justify">
        <div className="d-flex flex-wrap align-items-center" data-aos="fade-up">
          <div className="col-md-6 col-12 pe-md-5 pe-0" data-aos="fade-right">
            <div className='d-flex justify-content-center align-items-center fw-bold bgdarkblue rounded-3'>
              <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-2 text-white'>Multimedia</span>&nbsp; &nbsp;
              <img height={"60%"} src={Sectiontitle2}></img>
            </div>
            <p className='pt-3'>Children are exposed to information and Communication Technology from an early age. Power point presentations and overhead projectors are used as teaching aids. This enables to create a sense of interest in students as they see and learn.</p>
          </div>
          <div className="col-md-6 col-12" data-aos="fade-left">
            <img src={multimedias} className='img-fluid rounded-5'></img>
          </div>
        </div>
        <div className="d-flex flex-wrap mt-5  align-items-center" data-aos="fade-up">

          <div className="col-md-6 col-12 ps-md-5 ps-0 order-md-2" data-aos="fade-left">
            <div className='d-flex justify-content-center align-items-center fw-bold bgdarkblue rounded-3'>
              <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-2 text-white'>Play Area</span>&nbsp; &nbsp;
              <img height={"60%"} src={Sectiontitle2}></img>
            </div>
            <p className='pt-3'>Play Area is the one of the essential key to physical, mental, intellectual and social well-being of children. School playgrounds are vitally important to children for their fun and relaxation as well as for their good health therefore, by encouraging physical activity, the schools have large play areas that provide a safe and secure environment for fun filled activities.</p>
          </div>
          <div className="col-md-6 col-12 order-md-1" data-aos="fade-right">
            <img src={PlayArea} className='img-fluid rounded-5'></img>
          </div>
        </div>
        <div className="d-flex flex-wrap mt-5  align-items-center" data-aos="fade-up">
          <div className="col-md-6 col-12 pe-md-5 pe-0" data-aos="fade-right">
            <div className='d-flex justify-content-center align-items-center fw-bold bgdarkblue rounded-3'>
              <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-2 text-white'>Activity Room</span>&nbsp; &nbsp;
              <img height={"60%"} src={Sectiontitle2}></img>

            </div>

            <p className='pt-3'>The activity room is designed to make learning more fun and exciting for our Montessori students. Students’ love this room as is has many toys and has is fully equipped with the entire Montessori and EPL material. The concept of the activity room is about learning through play in a totally different environment. All games are conducted as a part of the planned educational program.</p>
          </div>
          <div className="col-md-6 col-12" data-aos="fade-left">
            <img src={ActivityRoom} className='img-fluid rounded-5'></img>

          </div>
        </div>




        <div className="d-flex flex-wrap mt-5  align-items-center" data-aos="fade-up">

          <div className="col-md-6 col-12 ps-md-5 ps-0  order-md-2" data-aos="fade-left">
            <div className='d-flex justify-content-center align-items-center fw-bold bgdarkblue rounded-3'>
              <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-2 text-white'>Pre- Play</span>&nbsp; &nbsp;
              <img height={"60%"} src={Sectiontitle2}></img>

            </div>
            {/* <h4 className="fontcolordark fw-bold "></h4> */}
            <p className='pt-3'>When children are young they learn many social cues from peers. Little one in Pre-Play spends time near kids of similar age and learns from them. It is a great place to learn sharing and how to start relationship with peers.  Pre- Play is a great advantage for all our working women, so that you can work tension free while we are here to look after your baby.</p>
          </div>
          <div className="col-md-6 col-12  order-md-1" data-aos="fade-right">
            <img src={PrePlay} className='img-fluid rounded-5'></img>

          </div>
        </div>




        <div className="d-flex flex-wrap mt-5  align-items-center" data-aos="fade-up">
          <div className="col-md-6 col-12 pe-md-5 pe-0" data-aos="fade-right">
            <div className='d-flex justify-content-center align-items-center fw-bold bgdarkblue rounded-3'>
              <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-2 text-white'>Library</span>&nbsp; &nbsp;
              <img height={"60%"} src={Sectiontitle2}></img>

            </div>
            {/* <h4 className="fontcolordark fw-bold "></h4> */}
            <p className='pt-3'>“Today a reader tomorrow a leader.” Margaret Fuller. SKANS is providing an enjoyable experience to the students by providing a library to read books of their own choice. The library is well stocked with a variety of books. Students are participating in the reading competition with Oxford Big Read to enhance their skills of reading.</p>
          </div>
          <div className="col-md-6 col-12" data-aos="fade-left">
            <img src={Library} className='img-fluid rounded-5'></img>

          </div>
        </div>






        <div className="d-flex flex-wrap mt-5" data-aos="fade-up">

          <div className="col-md-6 col-12 ps-md-5 ps-0 order-md-2" data-aos="fade-left">
            <div className=' d-flex justify-content-center align-items-center fw-bold bgdarkblue rounded-3 bgdarkblue rounded-3'>
              <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-2 text-white text-center'>Computer Laboratory</span>&nbsp; &nbsp;
              <img height={"60%"} src={Sectiontitle2}></img>

            </div>
            {/* <h4 className="fontcolordark fw-bold "></h4> */}
            <p className='pt-3'>The importance of technology in our daily lives is undeniable. In today’s dynamic world, life without technology is meaningless.</p>
            <p>It has found its way into classroom in all sorts of ways, from projectors and televisions to computer labs and student laptops. Along with improving the way of students are taught, it is also important that students learn to use computers to improve their own work and prepare for their careers.</p>
            <p>Computers play a vital role in the modern world. Teaching students how to use computers help them prepare for any number of possible careers, and classes based in computer education can get even more specific which will help them becoming successful individual.</p>
          </div>
          <div className="col-md-6 col-12 order-md-1" data-aos="fade-right">
            <img src={ComputerLab} className='img-fluid rounded-5'></img>

          </div>
        </div>


      </div>


      <div className='bgdarkblue py-4 mt-4'>
        <div className='d-flex justify-content-around text-white'>
          <div>
            © Copyright {currentYear} - All rights reserved
          </div>
          <div>
            <span className='fw-bold'>SKANS</span> Group of Educational Institutes
          </div>
        </div>
      </div>

    </>
  )
}

export default Facilities
