import React, { useEffect, useState } from 'react'
import Navbar from '../../../Components/navbar'
import { Link } from 'react-router-dom'
import "../../../CSS/ACCAEventIslamabad.css"
import ReactPaginate from 'react-paginate';
import { backendURL, currentYear } from '../../../constant';
import CollegeNavbar from '../../../Components/CollegeNavbar';

function ACCAEventIslamabad() {
    const [visible, setVisible] = useState(true);

    const [visibleDetails, setVisibleDetails] = useState([]);

    const toggleDetails = (id) => {
        // Create a copy of the current state to modify it
        const updatedVisibleDetails = [...visibleDetails];

        // Toggle the visibility for the clicked event
        updatedVisibleDetails[id] = !updatedVisibleDetails[id];

        // Update the state
        setVisibleDetails(updatedVisibleDetails);
    };
    const [event, setEvent] = useState([]);
    const [upcomingevent, setUpcomingEvent] = useState([]);
    const [currentUpcomingPage, setCurrentUpcomingPage] = useState(0); // Separate state for upcoming events pagination
    const [currentCompletedPage, setCurrentCompletedPage] = useState(0); // Separate state for completed events pagination
    const universitiesPerPage = 3;
    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getF8CompletedEvent`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setEvent(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
        }
    };

    const callUpcomingEvent = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getF8UpcomingEvent`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setUpcomingEvent(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {

        // Promise.all([callProfile(), callUpcomingEvent()]);
        callProfile()
        callUpcomingEvent();
    }, []);

    const picurl = `${backendURL}/public/uploads/`;

    const indexOfLastUpcomingEvent = (currentUpcomingPage + 1) * universitiesPerPage;
    const indexOfFirstUpcomingEvent = indexOfLastUpcomingEvent - universitiesPerPage;
    const currentUpcomingEvents = upcomingevent
        ? upcomingevent.slice(indexOfFirstUpcomingEvent, indexOfLastUpcomingEvent)
        : [];

    const indexOfLastCompletedEvent = (currentCompletedPage + 1) * universitiesPerPage;
    const indexOfFirstCompletedEvent = indexOfLastCompletedEvent - universitiesPerPage;
    const currentCompletedEvents = event
        ? event.slice(indexOfFirstCompletedEvent, indexOfLastCompletedEvent)
        : [];
    const [currentImagePages, setCurrentImagePages] = useState({});
    const imagesPerPage = 3; // Number of images per page

    useEffect(() => {
        // Initialize the currentImagePages state with default values
        const initialImagePages = {};
        if (event) {
            event.forEach((ann) => {
                if (Array.isArray(ann.photos) && ann.photos.length > 0) {
                    initialImagePages[ann._id] = 0; // Initialize each event with the first page
                }
            });
        }
        setCurrentImagePages(initialImagePages);
    }, [event]);





    const renderPhotos = (photos) => {
        return photos.map((photo, index) => (
            <div key={index} className='col-md-4 col-7 p-1'>
                <img className='img-fluid' src={picurl + photo} alt="photos"></img>
            </div>
        ));
    };

    const renderEvents = () => {
        return event.map((event, index) => (
            <div key={index} className=' border border-start-0 border-top-0 border-end-0  mb-3 shadow-lg rounded bg-white px-2' data-aos="fade-up">
                <div className='d-flex flex-wrap py-4'>
                    <div className='col-md-2 col-12 me-5 '>
                        <div className='event_dateevent text-white fw-bold' >
                            <div className=' text-center event-date-wrap1 text-white'><p className='fw-bold '>{event.date}</p></div>
                            {/* <span className=''>{event.time}</span> */}
                        </div>
                    </div>
                    <div className='col-md-9 col-12 ps-1 mt-md-0 mt-3'>
                        <h2>{event.title}</h2>
                        <p>{event.description}</p>
                    </div>
                </div>

                {visibleDetails[index] && (
                    <div className='pb-4 d-flex flex-wrap justify-content-around'>
                        {event.photos && renderPhotos(event.photos.split(','))}
                    </div>
                )}

                <a
                    style={{ cursor: "pointer" }}
                    className='text-decoration-none text-primary text-center'
                    onClick={() => toggleDetails(index)}
                >
                    {visibleDetails[index] ? 'Hide Details' : 'Show Details'}
                </a>
            </div>
        ));
    };



    // UPCOMING


    const renderupcomingEvents = () => {
        return currentUpcomingEvents.map((event, index) => (
            <div key={index} className=' border border-start-0 border-top-0 border-end-0  mb-3 shadow-lg rounded bg-white px-2' data-aos="fade-up">
                <div className='d-flex flex-wrap py-4'>
                    <div className='col-md-2 col-12 me-5 '>
                        <div className='event_dateevent text-white fw-bold' >
                            <div className=' text-center event-date-wrap1 text-white'><p className='fw-bold '>{event.date}</p></div>
                            <span className=''>{event.time}</span>
                        </div>
                    </div>
                    <div className='col-md-9 col-12 ps-1 mt-md-0 mt-3'>
                        <h2>{event.title}</h2>
                        <p>{event.description}</p>
                    </div>
                </div>

                {visibleDetails[index] && (
                    <div className='pb-4 d-flex flex-wrap justify-content-around'>
                        {event.photos && renderPhotos(event.photos.split(','))}
                    </div>
                )}

                <a
                    style={{ cursor: "pointer" }}
                    className='text-decoration-none text-primary text-center'
                    onClick={() => toggleDetails(index)}
                >
                    {visibleDetails[index] ? 'Hide Details' : 'Show Details'}
                </a>
            </div>
        ));
    };


    return (
        <>
            <CollegeNavbar />
            <div className='CANoticeboardbg'>
                <div className=' container-fluid text-light filter13 text-center'>
                    <p >Home / F8 Islamabad EVENTS</p><br></br>
                    <h1 className='fw-bold d-md-block d-none'>F8 ISLAMABAD EVENTS</h1>
                    <h1 className='fw-bold d-md-none d-block fs-1'>F8 ISLAMABAD EVENTS</h1>


                </div>
            </div>

            <div className='bgFaculty mb-0'>
                <div className='container pt-5 '>
                    <div className='d-flex flex-row-reverse pt-3'>
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item bg-white rounded">
                                <a class="nav-link active" data-bs-toggle="tab" href="#tabs-1" role="tab">Upcoming Events</a>
                            </li>
                            <li class="nav-item bg-white rounded">
                                <a class="nav-link" data-bs-toggle="tab" href="#tabs-2" role="tab">Completed Events</a>
                            </li>
                        </ul>
                    </div>
                </div>






                <div class="tab-content overflow-hidden">
                    <div className='container-fluid px-md-1 px-0 pb-5 pt-5 tab-pane active' id="tabs-1" role="tabpanel">

                        <h1 className='fw-bold fontcolordark ms-5 mb-5'>UPCOMING EVENT</h1>
                        <div className='d-lg-flex ms-lg-5 ms-md-3 ms-0'>
                            <div className='col-lg-3 col-12 border border-bottom-0 border-end-0 border-top-0  order-lg-2 order-1 ' data-aos="fade-left">
                                <p className='text-center fontcolordark fw-bold fs-3 bgdarkblue text-white mb-0'>Categories</p>

                                <ul className='list-unstyled text-primary '>
                                    <Link className='text-decoration-none d-flex noticecat ps-3 pe-3 py-2 fs-6' to="/EventRAR">RAR Campus Event</Link>
                                    <Link className='text-decoration-none d-flex noticecat ps-3 pe-3 py-2 fs-6' to="/EventSatellitetown">Satellite Town Event</Link>
                                    <Link className='text-decoration-none d-flex noticeAtive ps-3 pe-3 py-2 fs-6' to="/EventIslamabad">F8 Islamabad Event</Link>
                                    <Link className='text-decoration-none d-flex noticecat ps-3 pe-3 py-2 fs-6' to="/EventPeshawarRoad">Peshawar Road Event</Link>


                                </ul>
                            </div>
                            <div className='col-lg-9 col-12 px-md-3 px-0 text-justify'>
                                {/* {Array.isArray(currentUpcomingEvents) && currentUpcomingEvents.length > 0 ? (
                                    currentUpcomingEvents.map((ann, id) => (
                                        <div className=' border border-start-0 border-top-0 border-end-0  mb-3 shadow-lg rounded bg-white px-2' data-aos="fade-up">
                                            <div className='d-flex flex-wrap py-4'>
                                                <div className='col-md-2 col-12 me-5 '>
                                                    <div className='event_dateevent text-white fw-bold'>
                                                        <div className=' text-center event-date-wrap1 text-white'><p className='fw-bold '>{ann.date}</p></div>
                                                        <span className=''>{ann.time}</span>
                                                        <span className='fs-6 d-sm-block d-none'>{ann.time}</span>



                                                    </div>
                                                </div>
                                                <div className='col-md-9 col-12 ps-1 mt-md-0 mt-3'>
                                                    <h2>{ann.title}</h2>
                                                    <p>{ann.description}</p>

                                                </div>
                                            </div>

                                            {visibleDetails[id] && <div className='pb-4 d-flex flex-wrap justify-content-around'>
                                                {
                                                Array.isArray(ann.photos) && ann.photos.length > 0 ? (
                                                    ann.photos.split(',').map((photo, id) => (
                                                        <div key={id} className='col-md-6 col-9 mb-md-0 mb-3'>
                                                            <img className='img-fluid' src={picurl + photo} alt="eventsphotos"></img>
                                                        </div>
                                                    )
                                                    )
                                                    ) : (<p className='d-flex justify-content-center'><div class="spinner-border text-primary" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                  </div></p>
                                                  )
                                                  }



                                            </div>}
                                            <a style={{ cursor: "pointer" }} className='text-decoration-none text-primary' onClick={() => setVisible(!visible)}>{visible ? 'Hide Details' : 'Show Details'}</a>
                                            <a
                                                style={{ cursor: "pointer" }}
                                                className='text-decoration-none text-primary text-center'
                                                onClick={() => toggleDetails(id)}
                                            >
                                                {visibleDetails[id] ? 'Hide Details' : 'Show Details'}
                                            </a>



                                        </div>

                                    ))) : (<p className='d-flex justify-content-center'><div class="spinner-border text-primary" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                  </div></p>)
                                } */}
                                {renderupcomingEvents()}

                                <ReactPaginate
                                    pageCount={Math.ceil(upcomingevent.length / universitiesPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={({ selected }) => setCurrentUpcomingPage(selected)}
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    previousLabel={<i className="bi bi-chevron-left"></i>}
                                    nextLabel={<i className="bi bi-chevron-right"></i>}
                                />


                            </div>
                        </div>
                    </div>


                    {/* SECOND TAB */}


                    <div className='container-fluid pb-5 pt-5 tab-pane px-md-1 px-0' id="tabs-2" role="tabpanel">
                        <h1 className='fw-bold fontcolordark ms-md-5 ms-2 mb-5'>COMPLETED EVENT</h1>
                        <div className='d-lg-flex ms-lg-5 ms-md-3 ms-0'>


                            <div className='col-lg-11 col-12 px-md-3 ps-0 text-justify mb-5'>
                                {/* {Array.isArray(currentCompletedEvents) && currentCompletedEvents.length >= 0 ? (
                                    currentCompletedEvents.map((ann, id) => (
                                        <div key={id} className='border border-start-0 border-top-0 border-end-0 my-5 mb-3 shadow-lg rounded bg-white px-2' data-aos="fade-up">
                                            <div className='d-flex flex-wrap py-4' >
                                                <div className='col-md-2 col-12 me-md-5 me-0'>
                                                    <div className='event_dateevent2 text-white fw-bold'>
                                                        <div className=' text-center event-date-wrap1 text-white'><p className='fw-bold '>{ann.date}</p></div>



                                                    </div>
                                                </div>
                                                <div className=' col-md-9 col-12 ps-md-5 ps-2 mt-md-0 mt-3'>
                                                    <h2>{ann.title}</h2>
                                                    <p>{ann.description}</p>


                                                </div>

                                            </div>

                                            <div className='pb-4 d-flex flex-wrap justify-content-around'>
                                                {Array.isArray(ann.photos) && ann.photos.length > 0 ? (
                                                    ann.photos
                                                        .slice(
                                                            currentImagePages[ann._id] * imagesPerPage,
                                                            (currentImagePages[ann._id] + 1) * imagesPerPage
                                                        ).map((photo, id) => (
                                                            <>
                                                                <div key={id} className='col-md-4 col-7 p-1' data-aos="fade-up">
                                                                    <img width={"100%"} className='img-fluid ' src={picurl + photo}></img>
                                                                </div>
                                                            </>
                                                        )
                                                        )
                                                ) : (<p className='d-flex justify-content-center'><div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                              </div></p>)
                                                }






                                            </div>

                                            {visible && Array.isArray(ann.photos) && ann.photos.length > 0 && (
                                                <ReactPaginate
                                                    pageCount={Math.ceil(ann.photos.length / imagesPerPage)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={({ selected }) => {
                                                        setCurrentImagePages((prevImagePages) => ({
                                                            ...prevImagePages,
                                                            [ann._id]: selected,
                                                        }));
                                                    }}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    activeClassName="active"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    previousLabel={<i className="bi bi-chevron-left"></i>}
                                                    nextLabel={<i className="bi bi-chevron-right"></i>}
                                                />
                                            )}


                                        </div>
                                    ))) : (<p className='d-flex justify-content-center'><div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div></p>)
                                } */}
                                {renderEvents()}
                            </div>


                        </div>
                        <ReactPaginate
                            pageCount={Math.ceil(event.length / universitiesPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={({ selected }) => setCurrentCompletedPage(selected)}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            previousLabel={<i className="bi bi-chevron-left"></i>}
                            nextLabel={<i className="bi bi-chevron-right"></i>}
                        />
                    </div>

                </div>

            </div>



            <div className='bgdarkblue py-4 '>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>

        </>
    )
}

export default ACCAEventIslamabad
