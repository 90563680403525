import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import "../../CSS/CAFaculty.css";
import Navbar from '../../Components/navbar';
import Sectiontitle1 from "../../Photos/section-title-shape-1.png";
import Sectiontitle2 from "../../Photos/section-title-shape-2.png";
import FacultyIllustration from "../../Photos/FacultyIllustrationpng.png";
import { backendURL, currentYear } from '../../constant';
import CollegeNavbar from '../../Components/CollegeNavbar';
// import FacultyProfile from "../../Photos/Facultyprofile.jpeg";

function CA_RARFaculty() {
    const [faculty, setFaculty] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const universitiesPerPage = 10;

    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getCAFacultyRAR`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setFaculty(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
    const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
    const currentFaculty = faculty.slice(indexOfFirstUniversity, indexOfLastUniversity);

    const picurl = `${backendURL}/public/uploads/`;


    return (
        <>
            <CollegeNavbar />
            <div className='Facultybg overflow-hidden'>
                <div className=' container-fluid text-light filter13 text-center'>
                    <p >Home / CA Faculty</p><br></br>
                    <h1 className='fw-bold d-md-block d-none'>CA Faculty RAR Campus</h1>
                    <h3 className='fw-bold d-md-none d-block fs-2'>CA Faculty RAR Campus</h3>

                </div>
            </div>

            <div className='container mt-5'>
                <div className='my-5 d-flex align-items-center justify-content-center bgdarkblue border p-3 px-5' data-aos="flip-right">
                    <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;&nbsp;
                    <h3 className='text-light text-center fw-bold mb-0 text-uppercase d-md-block d-none'>Welcome to SKANS  Faculty</h3>&nbsp;&nbsp;&nbsp;
                    <h5 className='text-light text-center fw-bold mb-0 text-uppercase d-md-none d-block'>Welcome to SKANS  Faculty</h5>&nbsp;&nbsp;&nbsp;
                    
                    <img height={"10%"} src={Sectiontitle2}></img>
                </div>
                <div className='d-flex flex-wrap justify-content-around' data-aos="fade-up">
                    <div className='col-lg-4 col-12 order-2-lg order-1' data-aos="fade-left">
                        <img className='img-fluid' width={"90%"} src={FacultyIllustration}></img>
                    </div>
                    <div className='col-lg-7 col-12' data-aos="fade-right">
                        <p className='fs-5 text-justify'>
                            At SKANS School of Accountancy, our commitment to excellence is reflected in our esteemed faculty members who are experts in their respective fields. Our faculty members are dedicated to providing high-quality education, fostering a dynamic learning environment, and preparing students for successful careers in accountancy and beyond.
                        </p>
                    </div>
                </div>
            </div>

            <div className=' bgFaculty py-3'>
                <div className='container'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'></div>
                        <div data-aos="flip-down" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-md-5 px-4'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 text-uppercase d-md-block d-none text-center'>MEET OUR FACULTY MEMBER</h3>&nbsp;&nbsp;&nbsp;
                            <h6 className='text-light fw-bold mb-0 text-uppercase d-md-none d-block text-center'>MEET OUR FACULTY MEMBER</h6>&nbsp;&nbsp;&nbsp;
                            
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'></div>
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-around'>
                    {Array.isArray(currentFaculty) && currentFaculty.length > 0 ? currentFaculty.map((ann, id) => (
                        <div className='col-lg-5 col-10 Facultybluebg text-white p-4 shadow-sm mt-4 rounded-4' key={id} data-aos="flip-up">
                            <div className='d-flex flex-wrap'>
                                <div className='col-4'>
                                {ann.photos? (<img className='rounded-circle img-fluid' width={"90%"}  src={picurl + ann.photos}></img>):(<img className='rounded-circle img-fluid' width={"90%"}  src="https://www.pngmart.com/files/22/User-Avatar-Profile-PNG-Isolated-Transparent-Picture.png"></img>)}

                                </div>
                                <div className='col-8'>
                                    <p className='fs-5  fw-bold'>{ann.name}</p>
                                    <h6 className='fw-bold'>Qualification:</h6>
                                    <p>{ann.qualification}</p>
                                    <h6 className='fw-bold'>Specialization:</h6>
                                    <p>{ann.specialization}</p>
                                    <h6 className='fw-bold'>Experience:</h6>
                                    <p>{ann.experience}</p>
                                    <h6 className='fw-bold'>Designation:</h6>
                                    <p>{ann.designation}</p>
                                </div>
                            </div>
                        </div>
                    )) : <p>Not Found</p>}
                </div>
            </div>

            <ReactPaginate
                pageCount={Math.ceil(faculty.length / universitiesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
            />

            <div className='bgdarkblue py-4 '>
                <div className='d-flex flex-wrap justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>
                </div>
            </div>
        </>
    );
}

export default CA_RARFaculty;
