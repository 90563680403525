import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function HighAchiever() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  useEffect(() => {
    callProfile();
  }, []);

  const handlePhotoChange = (e) => {
    setSelectedPhotos([...e.target.files]);
  };

  const handlePhotoUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    selectedPhotos.forEach((photo) => {
      formData.append("photos", photo);
    });

    try {
      const response = await axios.patch(`${backendURL}/HomeHighAchiever`, formData);
      console.log(response);
      toast.success("Image Uploaded Successfully", {
        position: "top-center",
        hideProgressBar: true,
        theme: "colored",
      });
      setSelectedPhotos("");
      callProfile(); // Refresh the image list after upload
    } catch (error) {
      console.error(error);
      toast.error("Failed to Upload Image", {
        position: "top-center",
        hideProgressBar: true,
        theme: "colored",
      });
    }
  };
  
  const callProfile = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomeHighAchiever`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          setUser(data);

        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err);
      // navigate("/AdminLogin");
    }
  };

  const DeleteDemographic = async (id) => {
    try {
      const res2 = await fetch(`${backendURL}/DeleteHomehighAchiever/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const deletedata = await res2.json();
      console.log(deletedata);

      if (res2.status === 422 || !deletedata) {
        console.log("error");
      } else {
        toast.success("Image Deleted Successfully", {
          position: "top-center",
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        });
        callProfile(); // Refresh the image list after deletion
      }
    } catch (error) {
      console.error(error);
    }
  };

  const picurl = `${backendURL}/public/uploads/`;

  return (
    <>
      <ToastContainer />
      <Sidebar />

      <div className="my-5">
        <h1 className="fw-bold text-center fontcolordark">Home High Achiever</h1>

      </div>
      <div className="">
        <form
          className="d-flex justify-content-around"
          onSubmit={handlePhotoUpload}
          encType="multipart/form-data"
        >
          <div className="col-md-4 border-right">
            <input
              name="photos"
              id="photos"
              type="file"
              accept=".jpg, .jpeg, .png"
              className="form-control"
              onChange={handlePhotoChange}
              required
              multiple
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary profile-button">
              Upload Photo
            </button>
          </div>
        </form>
      </div>
      <div className="mt-5 container">
        <form className="" method="GET">
          <div className="shadow-lg">
            <div className="p-2">
              {user.length > 0 ? (
                user.map((photo, id) => (
                  <li
                    key={id}
                    className="d-flex border rounded-2 justify-content-between shadow-sm mt-3 px-4 py-2"
                  >
                    <div className="col-9">
                      <span className="fw-bold">{id + 1}.</span>{" "}
                      <img
                        width="180px"
                        height={"180px"}
                        src={picurl + photo.photos}
                        alt={`Photo ${id + 1}`}
                      />
                    </div>
                    <div className="d-flex justify-content-end col-1">
                      <Link to={`/UpdateHighAchiever/${photo.id}`}>
                        <i className="bi bi-pencil-square fs-5 text-primary"></i>
                      </Link>
                      <Link
                        className="ms-4"
                        onClick={() => DeleteDemographic(photo.id)}
                      >
                        <i className="bi bi-trash fs-5 text-danger"></i>
                      </Link>
                    </div>
                  </li>
                ))
              ) : (
                <p>Not Available</p>
              )}
            </div>
            <div></div>
          </div>
        </form>
      </div>
    </>
  );
}

export default HighAchiever;
