import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function Announcment() {
    const navigate = useNavigate();

    const [announcement, setAnnouncement] = useState({
        date: "",
        title: "",
        description: ""
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncement({ ...announcement, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/homeAnnouncement`, announcement);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAnnouncement({
                date: "",
                title: "",
                description: ""
            }); // Clear the input fields after successful upload
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = async () => {
        try {

            const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomeAnnouncement`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          setAnnouncement(data);

        })
        .catch((error) => console.error(error));
            // const res = await fetch("/getHomeAnnouncement", {
            //     method: "GET",
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            //     credentials: "include",
            // });

            // if (res.status === 200) {
            //     const data = await res.json();
            //     console.log(data);
            //     setAnnouncement(data);
            // } else {
            //     const error = new Error(res.statusText);
            //     throw error;
            // }
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    };

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteHomeAnnouncement/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Announcement Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="my-5">
                <h1 className="fw-bold text-center fontcolordark">Home Announcement</h1>

            </div>
            <div className="">
                <form method="PATCH" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="title"
                                type="text"
                                id="title"
                                className="form-control"
                                placeholder="Enter title"
                                value={announcement.title}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="date"
                                type="text"
                                id="date"
                                className="form-control"
                                placeholder="Enter date here"
                                value={announcement.date}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="description"
                                type="text"
                                id="description"
                                rows="4"
                                className="form-control"
                                placeholder="Enter or paste text here"
                                value={announcement.description}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Highlight
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">

                    <table class="table2">
                        <tr class="tr2">
                            <th class="th2 p-sm-2 p-1">Title</th>
                            <th colspan="2" class="th2 p-sm-2 p-1">Date</th>
                            <th class="th2 p-sm-2 p-1"></th>

                        </tr>
                        {Array.isArray(announcement) && announcement.length > 0 ? (
                            announcement.map((ann, id) => (

                                <tr class="tr2" key={id}>
                                    <td class="td2  p-sm-2 p-1 fw-bold">{ann.title}</td>
                                    <td class="td2 p-sm-2 p-1">{ann.date}</td>
                                    <td class="td2 p-lg-2 p-1"></td>

                                    <th class="th2 d-flex justify-content-start text-center">

                                        <Link to={`/UpdateAnnouncment/${ann.id}`} ><i class="bi bi-pencil-square fs-5 text-primary fw-bold"></i></Link>
                                        <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>

                                    </th>

                                </tr>
                            ))
                        ) : (
                            <p>Not Available</p>
                        )}
                    </table>
                    {/* <div className="shadow-lg">
                        <div className="p-2">
                            {Array.isArray(announcement) && announcement.length > 0 ? (
                                announcement.map((ann, id) => (
                                    <li key={id} className="d-flex border rounded-2 justify-content-between shadow-sm mt-3 px-4 py-2">
                                        <div className="col-9">
                                            <span className="d-flex"><span className="fw-bold pe-3">{id + 1}.</span><p>{ann.title}</p><p className="ps-3">{ann.date}</p></span>
                                        </div>
                                        <div className="d-flex justify-content-end col-1">
                                            <Link to={`/UpdateAnnouncment/${ann._id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                            <Link className="ms-4" onClick={() => deleteAnnouncement(ann._id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <p>Not Available</p>
                            )}
                        </div>
                        <div></div>
                    </div> */}
                </form>
            </div>
        </>
    );
}

export default Announcment;
