import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/AdmissionSchool.css"
import AdmissionOpen from "../../Photos/AdmissionOpen.jpg"
import SeniorWestridge from "../../Photos/SeniorWestridgeCampus.png"
import JuniorWestridge from "../../Photos/JuniorWestridge.png"
import HarleyCampus from "../../Photos/HarleyCampus.png"
import ChakriCampus from "../../Photos/ChakriCampus.png"
import GolraMorCampus from "../../Photos/GolraMor.png"
import PWDCampus from "../../Photos/PWDcampus.JPG"
import AECHSCampus from "../../Photos/AECHSCampus.png"
import PeshawarRoadCampus from "../../Photos/skanspeshawarroad.png"








// import Sectiontitle1 from "../Photos/section-title-shape-1.png"
// import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import { Link } from 'react-router-dom'
import SchoolNavbar from '../../Components/SchoolNavbar'

function AdmissionSchool() {
  return (
    <>
      <SchoolNavbar />
      <div className='AdmissionSchoolbg'>
        <div className=' container-fluid ps-md-5 px-0 text-light filter15 text-center'>
          <p >Home / School Admission</p><br></br>
          <h1 className='fw-bold'>SKANS ADMISSION</h1>

        </div>
      </div>


      <div className='bg-lightgrey overflow-hidden'>

        <div className='container px-1 pt-5'>

          <div class="main">
            <ul class="cards">
            <li class="cards_item" data-aos="fade-right">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={GolraMorCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold mb-0'>SKANS International Islamic School, GOLRA MOR CAMPUS</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-6'> &nbsp;&nbsp; Play Group to Intermediate&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>(051) 2227475</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to='/GolraMorCampusAdmission'>More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li class="cards_item" data-aos="fade-up">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={JuniorWestridge} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold mb-0'>SKANS International Islamic School, JUNIOR WESTRIDGE CAMPUS</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 2&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>H.No. 9-B, Hali Road, Westridge-1, Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span> 051-8773091</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to="/JuniorWestridgeCampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li class="cards_item" data-aos="fade-left">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={SeniorWestridge} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold mb-0'>SKANS International Islamic School, SENIOR WESTRIDGE CAMPUS</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 3 to 10&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>H	91 Hali Road, Street no. 8, Westridge-1, Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span> (051) 8355546</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to="/SeniorWestridgeCampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li class="cards_item" data-aos="fade-right">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={PeshawarRoadCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold mb-0'>SKANS International Islamic School, PESHAWAR ROAD CAMPUS</h5>
                      <p  className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-5'> &nbsp;&nbsp; Play Group & Prep&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>House No. 308-A, Main Peshawar Road, Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>(051) 8890534</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to='/PeshawarRoadCampusAdmission'>More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li class="cards_item" data-aos="fade-up">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img  src={HarleyCampus} alt="Admissionopen" /></div>
                  
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold mb-0'>SKANS School System, Harley Street Campus</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; Play Group to 6&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span> House No 53-B Harley Street Campus – Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span> 0312-3456789</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to="/HarleyStreetCampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>

              {/* <li class="cards_item">
      <div class="cardschool" tabIndex="0">
        <div class="card_image"><img src={AdmissionOpen} alt="admissionopen "/></div>
        <div class="card_content">
          <h2 class="card_title">Admission Open</h2>
          <div class="card_text">
          <h4 className='fw-bold'>GULZAR E QUIAD CAMPUS</h4>
            <p >Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 4&nbsp;&nbsp;&nbsp;</span></p>
            <p> <span className='fs-5'>Location: </span>119 /2 street 4 Sector 2 Airport society Rawalpindi</p>
            <p className='fs-5'> <span className='fs-5'>Contact:</span>(+92)333-5666810</p>
            
            <Link class="btn btn-primary text-center upcharge text-white" to="/GulzareQuiadCampusAdmission">More Detail </Link>
          </div>
        </div>
      </div>
    </li>     */}

              <li class="cards_item" data-aos="fade-left">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={ChakriCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold'> SKANS Schools System, JUNIOR CHAKRI ROAD CAMPUS</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; Play Group to 4&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi.</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>0310-3331131</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to='/ChakriRoadCampusAdmission'>More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>
              

              <li class="cards_item" data-aos="fade-right">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={AECHSCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold'>SKANS School System, AECHS CAMPUS</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 10&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>337 Street 17, Sector 1 Airport Employees CHS, Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>(051) 8468475</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to="/AECHSCampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>


              <li class="cards_item" data-aos="fade-up">
                <div class="cardschool" tabIndex="0">
                  <div class="card_image"><img src={PWDCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h5 className='fw-bold'>SKANS School System, PWD CAMPUS</h5>
                      <p className="mb-0">Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; Play Group to 4&nbsp;&nbsp;&nbsp;</span></p>
                      <p> <span className='fs-5'>Location: </span>233, Block A Sector A PWD, Islamabad, Punjab</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>(051) 8773775</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to="/PWDCampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>




            </ul>
          </div>


        </div>
      </div>
    </>
  )
}

export default AdmissionSchool
