import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateHighlight() {
    const { id } = useParams();
    console.log(id);

    const navigate = useNavigate();

    const [highlight, setHighlight] = useState(""); // Initialize as null

    const handleInput = (e) => {
        const value = e.target.value;
        console.log(value);
        setHighlight(value);
    };
    const callIndHighlight = useCallback(async () => {
        try {
            const myHeaders = new Headers();


            const formdata = new FormData();

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch(`${backendURL}/ViewHomeHighlight/${id}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    const data = JSON.parse(result);
                    console.log(data);
                    console.log("hdfhjbj",data)
                    setHighlight(data);
                })
                .catch((error) => console.error(error));
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndHighlight();
    }, [callIndHighlight]);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/UpdateHomeHighlight/${id}`, { highlight });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setHighlight(""); // Clear the input field after successful upload
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };


    // const picurl = "http://localhost:5000/public/uploads/";

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center pb-3 fontcolordark">Update Highlight</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form method="PATCH" onSubmit={handleDataUpload}>
                        <div className="p-3">
                            <div className="ribbon786 mb-2">
                                <span className="ribbonadd2">Update Highlight</span>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <textarea
                                        name="highlight"
                                        id="highlight"
                                        rows="4" // You can adjust the number of rows as needed
                                        className="form-control"
                                        placeholder="Enter or paste text here"
                                        value={highlight.highlight}
                                        onChange={handleInput}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            {highlight && (
                                <div className='mt-5'>
                                    <p className='img-fluid' width={'20%'} >{highlight.highlight}</p>
                                </div>
                            )}
                            <div className="my-3 text-center">
                                <button className="btn btn-primary profile-button" type="submit">Update Highlight</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateHighlight;
