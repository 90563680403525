import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../Sidebar';
import { backendURL } from '../../../../constant';


function UpdateSchoolNoticeboard() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Announcment, setAnnouncment] = useState({
        title: "",
        description: "",
        date: "",
        type:""
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncment({ ...Announcment, [name]: value });
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewSchoolsNoticeboard/${id.trim()}`);
            const announcementData = response.data;
            setAnnouncment(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/updateSchoolsNoticeboard/${id.trim()}`, Announcment);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });

            setTimeout(() => {
                navigate("/AdminSchoolNoticeBoard");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Update Noticeboard", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark mb-4">Update Noticeboard</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form method='PATCH' onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="title"
                                    type="text"
                                    id="title"
                                    className="form-control"
                                    placeholder="Enter title here"
                                    value={Announcment.title}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="date"
                                    type="text"
                                    id="date"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.date}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-8 col-12 border-right mt-3">
                                <textarea
                                    name="description"
                                    type="text"
                                    id="description"
                                    rows="4"
                                    className="form-control"
                                    placeholder="Enter description here"
                                    value={Announcment.description}
                                    onChange={handleInput}
                                    required
                                ></textarea>
                            </div>
                            <div class="input-group mb-3">
                            <label class="input-group-text" for="inputGroupSelect01">Campus</label>
                            <select name="type" class="form-select" id="inputGroupSelect01" value={Announcment.type}
                                onChange={handleInput}>
                                <option selected>Choose...</option>
                                <option value="Harley Street Campus">Harley Street Campus</option>
                                {/* <option value="Gulzar e Quaid Campus">Gulzar e Quaid Campus</option> */}
                                <option value="Junior Chakri Road Campus">Junior Chakri Road Campus</option>
                                <option value="Junior Westridge Campus">Junior Westridge Campus</option>
                                <option value="Senior Westridge Campus">Senior Westridge Campus</option>
                                <option value="Golra Mor Campus">Golra Mor Campus</option>
                                <option value="Peshawar Road Campus">Peshawar Road Campus</option>
                                <option value="Skans PWD Campus">Skans PWD Campus</option>
                                <option value="Skans AECHS Campus">Skans AECHS Campus</option>

                            </select>
                        </div>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update Noticeboard
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateSchoolNoticeboard;
