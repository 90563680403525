import React, { useState } from 'react'
import "../../CSS/AdminLogin.css"
import loginbg from "../../Photos/loginbg.png"
import Skanslogo from "../../Photos/Skanslogo1.png"
import NavbarNew from '../../Components/navbar'
import { useNavigate } from 'react-router'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { backendURL } from '../../constant'
import axios from 'axios'




function Login() {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    const x = document.getElementById('myInput');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
    setPasswordVisible(!passwordVisible);
  };

  const [admin, setAdmin] = useState({
    email: '',
    password: '',
  });
  let name, value;
  const Handleinputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAdmin({ ...admin, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();
    
    try {
        const { email, password } = admin; // Assuming admin object is properly defined

        // Make a POST request to backend for admin signin
        const response = await axios.post(`${backendURL}/signinAdmin`, {
            email,
            password,
        }, { withCredentials: 'include' }); // Ensure credentials are sent with the request

        const result = response.data;

        if (response.status === 200) {
            // Store the token in localStorage
            localStorage.setItem('token', result.token);

            // Show success toast
            toast.dismiss();
            toast.success('Login Successfully', {
                position: 'top-center',
                hideProgressBar: true,
                theme: 'colored',
            });

            // Navigate to AdminDashboard after a delay
            setTimeout(() => {
                navigate('/AdminDashboard');
            }, 1000);
        } else {
            // Handle different error scenarios based on response status
            throw new Error('Unexpected response status: ' + response.status);
        }
    } catch (error) {
        console.error(error);

        // Show error toast
        toast.dismiss();
        toast.error(error.message || 'An Error Occurred', {
            position: 'top-center',
            hideProgressBar: true,
            theme: 'colored',
        });
    }
};












  return (
    <>
      {/* <NavbarNew/> */}
      <ToastContainer />
      <div className='bgdarkblue'>
        <div className="container d-flex justify-content-around align-items-center vh-100">
          <div className='col-5 text-center d-lg-block d-none'>
            <h1 className='fw-bold text-white mb-5' style={{ fontFamily: 'serif' }}>Welcome Back Admin</h1>
            <img src={Skanslogo} width={"80%"}></img>
          </div>
          <div className="login-container col-lg-7 col-10">
            <div className="circle circle-one"></div>
            <div className="form-container">
              <img src={loginbg} alt="illustration" className="illustration" />
              {/* <h1 className="text-white">LOGIN</h1> */}
              <form onSubmit={PostData} method='POST'>

                <h2 className="text-white fw-bold" ><span className='fs-1 fontcolordark' style={{ fontFamily: 'serif' }}>SKANS</span> LOGIN</h2>
                <input type="email" name="email" autoComplete='off' value={admin.email} onChange={Handleinputs} placeholder="Email Address" required />
                <div className='password-field'>
                  <input name="password" autoComplete='off' value={admin.password} onChange={Handleinputs} id="myInput" type={passwordVisible ? "text" : "password"} placeholder="PASSWORD" required></input>
                  <span>
                    <i onClick={togglePasswordVisibility} id="toggler" className={`text-white fw-bold bi ${passwordVisible ? "bi-eye-slash" : "bi-eye"}`}></i>
                  </span>
                </div>
                <button type='submit' className=" text-white">LOG IN</button>

              </form>
            </div>
            <div className="circle circle-two"></div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Login
