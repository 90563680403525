import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateF8CACalender() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Announcment, setAnnouncment] = useState({
        paper: "",
        teacher: "",
        batch: "",
        batchstart: "",
        batchend: ""
    });


    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncment({ ...Announcment, [name]: value });
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewCACalenderF8/${id}`);
            const announcementData = response.data;
            setAnnouncment(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/updateCACalenderF8/${id}`, Announcment);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });

            setTimeout(() => {
                navigate("/AdminF8CACalender");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Update Announcement", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark mb-4">Update CA Calender F8</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form method='PATCH' onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="paper"
                                    type="text"
                                    id="paper"
                                    className="form-control"
                                    placeholder="Enter title here"
                                    value={Announcment.paper}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="teacher"
                                    type="text"
                                    id="teacher"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.teacher}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="batch"
                                    type="text"
                                    id="batch"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.batch}
                                    onChange={handleInput}
                                    required
                                />
                            </div>

                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="batchstart"
                                    type="text"
                                    id="batchstart"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.batchstart}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="batchend"
                                    type="text"
                                    id="batchend"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.batchend}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update Batch
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateF8CACalender;
