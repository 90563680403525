import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../Sidebar';
import { backendURL } from '../../../../constant';


function UpdateCollegeNoticeboard() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Announcment, setAnnouncment] = useState({
        photos: [],
        title: "",
        description: "",
        date: "",
        type:""
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncment({ ...Announcment, [name]: value });
    };
    const handlePhotoChange = (e) => {
        const updatedUser = { ...Announcment };
        updatedUser.photos = e.target.files; // Check that e.target.files contains selected files
        setAnnouncment(updatedUser);
        console.log(updatedUser); // Log updatedUser to verify files are stored
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewCollegesNoticeboard/${id.trim()}`);
            const announcementData = response.data;
            setAnnouncment(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

const handleDataUpload = async (e) => {
    e.preventDefault();

    try {
        const formData = new FormData();
        if(Announcment.photos){
            for (let i = 0; i < Announcment.photos.length; i++) {
                formData.append('photos', Announcment.photos[i]);
              }
            }
          formData.append("date", Announcment.date);
          formData.append("title", Announcment.title);
          formData.append("description", Announcment.description);
          formData.append("type", Announcment.type);

  
        const response = await axios.patch(`${backendURL}/updateCollegesNoticeboard/${id.trim()}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response);
        toast.success("Data Uploaded Successfully", {
            position: "top-center",
            hideProgressBar: true,
            theme: "colored",
        });
        setAnnouncment({
            photos: [],
            date: "",
            title: "",
            description: "",
            type:""
        }); // Clear the input fields after successful upload
        setTimeout(() => {
            navigate("/AdminCollegeNoticeboard");
        }, 1000);
    } catch (error) {
        console.error(error);
        toast.error("Failed to Upload Member", {
            position: "top-center",
            hideProgressBar: true,
            theme: "colored",
        });
    }
};
    const picurl = `${backendURL}/public/uploads/`
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark mb-4">Noticeboard</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form method='PATCH' onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="title"
                                    type="text"
                                    id="title"
                                    className="form-control"
                                    placeholder="Enter title here"
                                    value={Announcment.title}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="date"
                                    type="text"
                                    id="date"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.date}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-8 col-12 border-right mt-3">
                                <textarea
                                    name="description"
                                    type="text"
                                    id="description"
                                    rows="4"
                                    className="form-control"
                                    placeholder="Enter description here"
                                    value={Announcment.description}
                                    onChange={handleInput}
                                    required
                                ></textarea>
                            </div>
                            <div className="col-md-5 border-right mt-3">
                            <input
                                name="photos"
                                id="photos"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handlePhotoChange}
                                multiple
                                
                            />
                        </div>
                            <div class="input-group mb-3">
                            <label class="input-group-text" for="inputGroupSelect01">Campus</label>
                            <select name="type" class="form-select" id="inputGroupSelect01" value={Announcment.type}
                                onChange={handleInput}>
                                <option selected>Choose...</option>
                                <option value="F8 Islamabad Campus">F8 Islamabad Campus</option>
                                <option value="Satellite Town Campus">Satellite Town Campus</option>
                                <option value="RAR Campus">RAR Campus</option>
                                <option value="Peshawar Road Campus">Peshawar Road Campus</option>


                            </select>
                        </div>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update Noticeboard
                            </button>
                        </div>
                    </form>

                    <div className='d-flex flex-wrap mt-5 justify-content-around'>


                        {
                           typeof Announcment.photos === 'string' ? (
                            Announcment.photos.split(',').map((photo, id) => (
                                <img key={id} className='img-fluid p-2' src={picurl + photo.trim()} alt={photo} style={{ width: '25%' }} />
                            ))
                        ) : (
                            <p>No photos found</p>
                        )
                        
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCollegeNoticeboard;
