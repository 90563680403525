import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/AdmissionDetailSchool.css"
import Admissionpicdetail from "../../Photos/CollegeAdmission.png"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"
import Registerform from "../../Pdf/registerformfeedback.pdf"
import { currentYear } from '../../constant'
import CollegeNavbar from '../../Components/CollegeNavbar'

function AdmissionRAR() {
    return (
        <>
            <CollegeNavbar />
            <div className='AdmissionRARbg'>
                <div className=' container-fluid ps-5 text-light filter15 text-center'>
                    <p >Home / College Admission / Addmission Detail</p><br></br>
                    <h1 className='fw-bold d-md-block d-none'>Raja Akram Road, Saddar Campus</h1>
                    <h1 className='fw-bold d-md-none d-block fs-1'>Raja Akram Road, Saddar Campus</h1>


                </div>

                {/* <div className=''>
                    <div id="mySidenav" class="sidenav position-fixed">
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="about"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-whatsapp fs-1 rounded-end-5"></i></a>
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="blog"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-facebook fs-1 rounded-end-5"></i></a>
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="projects"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-envelope-at fs-1 rounded-end-5"></i></a>
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="contact"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-telephone-fill fs-1 rounded-end-5"></i></a>

                    </div>


                </div> */}
            </div>

            <div className='bg-lightgrey'>
                <div className='container '>

                    <div className='d-flex justify-content-center align-items-center ' data-aos="fade-up">
                        <div className='b d-sm-block d-none mt-5'>
                        </div>
                        <div data-aos="zoom-in" className=' d-flex mt-5 align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 d-sm-block d-none'>ADMISSION DETAIL</h3>&nbsp;&nbsp;
                            <h6 className='text-light fw-bold mb-0 d-sm-none d-block'>ADMISSION DETAIL</h6>&nbsp;&nbsp;

                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b d-sm-block d-none mt-5'>
                        </div>
                    </div>



                    <div className='d-flex flex-wrap align-items-center text-justify' data-aos="fade-up">

                        <div data-aos="fade-left" className='col-md-7 col-12 ps-md-4 ps-0 lh-lg order-md-2 mt-5'>
                            <h3 className='fw-bold'>Welcome to Skans Schools of Accountancy - Your Gateway to Success in CA and ACCA!</h3>
                            <p>Are you ready to unlock a world of opportunity and achieve unparalleled success in your career? Skans Schools of Accountancy is your definitive partner in attaining a distinguished and prosperous professional life. We are delighted to introduce you to our prestigious institution, renowned for its unwavering commitment to empowering students through comprehensive Chartered Accountancy (CA) and Association of Chartered Certified Accountants (ACCA) study programs.</p>
                            {/* <p className='fs-2'>Grade: <span className='p-2 fs-3 rounded bg-danger text-white fw-bold'>1 to 4</span></p> */}

                        </div>
                        <div data-aos="fade-right" className='col-md-5 col-12 order-md-1 mt-5'>
                            <img className='img-fluid' src={Admissionpicdetail}></img>
                        </div>

                    </div>
                    <div className='mt-5'>
                        <h4 className='fw-bold' data-aos="fade-up">Why Choose Skans Schools of Accountancy?</h4>

                        <ol className='lh-lg text-justify'>
                            <li className='mt-3' data-aos="fade-up">
                                <h5 className='fw-bold fontcolordark'>Proven Excellence:</h5>
                                <ul>
                                    <li>
                                        We have a stellar track record of producing CA and ACCA professionals who excel in their fields, both nationally and internationally.
                                    </li>
                                    <li>
                                        Our students consistently achieve top results, thanks to our experienced faculty and rigorous curriculum.
                                    </li>
                                </ul>

                            </li>

                            <li className='mt-3' data-aos="fade-up">
                                <h5 className='fw-bold fontcolordark'>Exceptional Faculty:</h5>
                                <ul>
                                    <li>
                                        Our educators are industry experts and subject matter specialists, dedicated to providing you with the finest instruction and mentorship.
                                    </li>
                                </ul>

                            </li>


                            <li className='mt-3' data-aos="fade-up">
                                <h5 className='fw-bold fontcolordark'>State-of-the-Art Facilities:</h5>
                                <ul>
                                    <li>
                                        Our campuses are equipped with modern, purpose-built facilities, ensuring a conducive environment for learning.
                                    </li>
                                    <li>
                                        Access to extensive libraries, computer labs, and study resources is at your fingertips.
                                    </li>
                                </ul>

                            </li>

                            <li className='mt-3' data-aos="fade-up">
                                <h5 className='fw-bold fontcolordark'>Comprehensive Curriculum:</h5>
                                <ul>
                                    <li>
                                        Our CA and ACCA programs are designed to encompass the latest industry developments and global trends.
                                    </li>
                                    <li>
                                        We offer a well-structured and dynamic learning experience that is continuously updated to meet the evolving demands of the profession.
                                    </li>
                                </ul>

                            </li>


                            <li className='mt-3' data-aos="fade-up">
                                <h5 className='fw-bold fontcolordark'> Personalized Support:</h5>
                                <ul>
                                    <li>
                                        We recognize that each student is unique, and our team is dedicated to providing personalized guidance to help you succeed.
                                    </li>
                                    <li>
                                        Small class sizes foster a close-knit community where you can readily access individualized support.
                                    </li>
                                </ul>
                            </li>


                            <li className='mt-3' data-aos="fade-up">
                                <h5 className='fw-bold fontcolordark'> Competitive Advantage:</h5>
                                <ul>
                                    <li>
                                        A CA or ACCA qualification from Skans Schools of Accountancy not only enhances your career prospects but also ensures a competitive edge in the job market.
                                    </li>
                                    <li>
                                        Our alumni are sought after by prestigious firms worldwide.
                                    </li>
                                </ul>


                            </li>



                        </ol>

                        <h3 className='fw-bold fontcolordark' data-aos="fade-up">Apply Now and Take the First Step Towards Your Journey to Success!</h3>
                        <p data-aos="fade-up">For any questions or further information, please feel free to contact us. We are here to help you realize your dreams and achieve greatness through education.<br></br>

                            Skans Schools of Accountancy - Your Gateway to Success in CA and ACCA!





                        </p>

                    </div>




                    <table className='table table-light table-striped mt-5' data-aos="fade-up">

                        <tr>
                            <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>
                        </tr>

                        <tbody>
                            <tr col="row">
                                <td className='fw-bold fs-5'>Address:</td>
                                <td>16-Raja Akram Road, Saddar, Rawalpindi Cantt</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Email:</td>
                                <td><a href="mailto:school.info@skans.pk">inquiry@skans.pk</a></td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone 1:</td>
                                <td>(+92)-51-5125148–49</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone 2:</td>
                                <td>(+92)-51-5125155</td>
                            </tr>

                        </tbody>
                    </table>



                    <div className='mt-5'>
                    <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13292.449289700802!2d73.039474!3d33.60239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df946297a95cf3%3A0x8aead128e41e7ea8!2sSKANS%20School%20of%20Accountancy!5e0!3m2!1sen!2sus!4v1696331964422!5m2!1sen!2sus" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                        
                    </div>


                </div>





            </div>

            <div className='bgdarkblue py-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdmissionRAR
