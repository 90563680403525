import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/AdmissionSchool.css"
import AdmissionOpen from "../../Photos/AdmissionOpen.jpg"
import F8Campus from "../../Photos/F8Campus.jpg"
import STCampus from "../../Photos/STCAMPUS.png"
import RARCampus from "../../Photos/RAR campus.jpeg"
import PeshawarRoadCampus from "../../Photos/bgpeshawarroad.jpeg"



// import Sectiontitle1 from "../Photos/section-title-shape-1.png"
// import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import { Link } from 'react-router-dom'
import CollegeNavbar from '../../Components/CollegeNavbar'

function AdmissionCollege() {
  return (
    <>   
      <CollegeNavbar />
      <div className='AdmissionSchoolbg'>
        <div className=' container-fluid ps-5 text-light filter15 text-center'>
          <p >Home / College Admission</p><br></br>
          <h1 className='fw-bold'>SKANS ADMISSION</h1>

        </div>
      </div>

      <div className='bg-lightgrey overflow-hidden'>
        <div className='container pt-5'>

          <div class="main">
            <ul class="cards">
              <li class="cards_item" data-aos="fade-right">
                <div class="cardschool" tabindex="0">

                  <div class="card_image"><img className='img-fluid' src={F8Campus} alt="" /><h2 class="card_title">Admission Open</h2></div>
                  <div class="card_content">

                    <div class="card_text">


                      <h4 className='fw-bold text-uppercase'>F8 Islamabad Campus</h4>
                      {/* <p ><span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 4&nbsp;&nbsp;&nbsp;</span></p> */}
                      <p> <span className='fs-5'>Location: </span> 14A-Haroon Building F8, Markaz Islamabad</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span> (+92)-51-2287313</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span> (+92)-51-2287315</p>

                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}

                      <Link class="btn btn-primary text-center upcharge text-white" to="/F8CampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li class="cards_item" data-aos="fade-up">
                <div class="cardschool" tabindex="0">
                  <div class="card_image"><img className='img-fluid' src={STCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h4 className='fw-bold text-uppercase'>Satellite Town CAMPUS</h4>
                      {/* <p >Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 4&nbsp;&nbsp;&nbsp;</span></p> */}
                      <p> <span className='fs-5'>Location: </span>House No. 77-A,Block A,Satellite Town Rehmanabad Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>(+92)-51-2716089</p>
                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to="/STCampusAdmission">More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li class="cards_item" data-aos="fade-left">
                <div class="cardschool" tabindex="0">
                  <div class="card_image"><img src={RARCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h4 className='fw-bold text-uppercase'> Raja Akram Road, Saddar CAMPUS</h4>
                      {/* <p >Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 4&nbsp;&nbsp;&nbsp;</span></p> */}
                      <p> <span className='fs-5'>Location: </span>16-Raja Akram Road, Saddar, Rawalpindi Cantt</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>(+92)-51-5125148–49</p>
                      <p className='fs-5'> <span className='fs-5'></span>(+92)-51-5125155</p>

                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to='/RARcampusAdmission'>More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>



              <li class="cards_item" data-aos="fade-left">
                <div class="cardschool" tabindex="0">
                  <div class="card_image"><img src={PeshawarRoadCampus} alt="admissionopen " /></div>
                  <div class="card_content">
                    <h2 class="card_title">Admission Open</h2>
                    <div class="card_text">
                      <h4 className='fw-bold text-uppercase'> Peshawar Road CAMPUS</h4>
                      {/* <p >Grade: <span className='fw-bold bg-danger rounded text-white fs-4'> &nbsp;&nbsp; 1 to 4&nbsp;&nbsp;&nbsp;</span></p> */}
                      <p> <span className='fs-5'>Location: </span>304 Bangash Plaza Near Maryam Memorial Hospital Peshawar Road, Rawalpindi</p>
                      <p className='fs-5'> <span className='fs-5'>Contact:</span>051 5490876</p>
                      {/* <p className='fs-5'> <span className='fs-5'></span>(+92)-51-5125155</p> */}

                      {/* <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p> */}
                      <Link class="btn btn-primary text-center upcharge text-white" to='/PeshawarRoadCampusAdmissioncollege'>More Detail </Link>
                    </div>
                  </div>
                </div>
              </li>



            </ul>
          </div>


        </div>
      </div>
    </>
  )
}

export default AdmissionCollege
