import React from 'react'
import Navbar from '../Components/navbar'
import "../CSS/CBEDates.css"
import { currentYear } from '../constant'
import CollegeNavbar from '../Components/CollegeNavbar'

function CBEDates() {
    return (
        <>

            <CollegeNavbar />
            <div className='CBEbg'>
                <div className=' container-fluid ps-5 text-light filter8 text-center'>
                    <p>Home / CBE Dates</p><br></br>
                    <h1 className='fw-bold'>CBE DATES</h1>
                    <p className='fs-2 fw-bold'>(COMPUTER BASED EXAM)</p>
                </div>
            </div>


            <div className='container mt-5'>

                <table class="table2">
                    
                    <tr class="tr2">
                        <th class="th2">CAMPUS</th>
                        <th class="th2">CBE DATES</th>
                        <th class="th2">Fee Submission Last Date & Time</th>
                    </tr>
                    
                    

                    
                    <tr class="tr2">
                        <td class="td2">Islamabad</td>
                        <td class="th2">28 Aug 2023</td>
                        <td class="th2 text-danger">26 Aug 2023</td>
                    </tr>
                    <tr class="tr2">
                        <td class="td2">Islamabad</td>
                        <td class="th2">28 Aug 2023</td>
                        <td class="th2 text-danger">26 Aug 2023</td>
                    </tr>
                    <tr class="tr2">
                        <td class="td2">Islamabad</td>
                        <td class="th2 ">28 Aug 2023</td>
                        <td class="th2 text-danger">26 Aug 2023</td>
                    </tr>
                    <tr class="tr2 ">
                        <td class="td2">Islamabad</td>
                        <td class="th2">28 Aug 2023</td>
                        <td class="th2 text-danger">26 Aug 2023</td>
                    </tr>
                    

                    </table>

            </div>


            <div className='bgdarkblue py-4 mt-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        Powered By : <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>

        </>
    )
}

export default CBEDates
