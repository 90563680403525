import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function Updatelifeatskans() {
    const { id } = useParams();
    console.log(id);

    const navigate = useNavigate();

    const [photos, setPhotos] = useState({});
    const [user, setUser] = useState({}); // Initialize as empty object

    const handlePhotoChange = (e) => {
        const updatedUser = { ...photos };
        updatedUser.photos = e.target.files[0]; // Check that e.target.files contains selected files
        setPhotos(updatedUser);
        console.log(updatedUser); // Log updatedUser to verify files are stored
    };

    const callIndScreenImage = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/Viewgalleryphoto/${id}`);
            const ScreenImageData = response.data;
            setUser(ScreenImageData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndScreenImage();
    }, [callIndScreenImage]);

    const UpdateScreenImage = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        if (user.photos) {
            formData.append('photos', photos.photos);

            try {
                const response = await axios.patch(`${backendURL}/Updategalleryphoto/${id}`, formData);
                console.log(response.data);
                toast.success('Image Updated Successfully', {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: 'colored'
                });
                console.log("Image Updated Successfully");
                setTimeout(() => {
                    navigate("/Adminlifeatskans");
                }, 2000);
            } catch (err) {
                console.error(err);
                toast.error('Image not Added. Please Try Again', {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: 'colored'
                });
            }
        } else {
            // Handle the case when user.photos is null or undefined
            console.error('No photo selected');
        }
    };

    const picurl = `${backendURL}/public/gallery/`;

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark mb-4">Update Photo</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form onSubmit={UpdateScreenImage}>
                        <div className="p-3">
                            <div className="ribbon786 mb-2">
                                <span className="ribbonadd2">Update Image</span>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <label className="labels">Image</label>
                                    <input
                                        name="photos"
                                        id="photos"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        className="form-control"
                                        onChange={handlePhotoChange}
                                        required
                                    />
                                </div>
                            </div>
                            {user && (
                                <div className='mt-5'>
                                    <img className='img-fluid' width={'20%'} src={picurl + user.photos} alt="Image" />
                                </div>
                            )}
                            <div className="my-3 text-center">
                                <button className="btn btn-primary profile-button" type="submit">Update Image</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Updatelifeatskans;
