import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function Test() {
    const navigate = useNavigate();

    const [announcement, setAnnouncement] = useState({
        paper: "",
        teacher: "",
        batch: "",
        batchstart: "",
        batchend: "",
        type:""
    });
    const [detail, setDetail] = useState({});

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncement({ ...announcement, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("/test", announcement);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAnnouncement({
                paper: "",
                teacher: "",
                batch: "",
                batchstart: "",
                batchend: "",
                type:""
            }); // Clear the input fields after successful upload
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = async () => {
        try {
            const res = await fetch("/gettest", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (res.status === 200) {
                const data = await res.json();
                console.log(data);
                setDetail(data);
            } else {
                const error = new Error(res.statusText);
                throw error;
            }
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    };

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`/deletetest/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Batch Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, [callProfile]);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <form method="PATCH" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="paper"
                                type="text"
                                id="paper"
                                className="form-control"
                                placeholder="Enter or paste text here"
                                value={announcement.paper}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="teacher"
                                type="text"
                                id="teacher"
                                className="form-control"
                                placeholder="Enter date here"
                                value={announcement.teacher}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right  mt-3">
                            <input
                                name="batch"
                                type="number"
                                id="batch"
                                className="form-control"
                                placeholder="Enter date here"
                                value={announcement.batch}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="batchstart"
                                type="text"
                                id="batchstart"
                                className="form-control"
                                placeholder="Enter date here"
                                value={announcement.batchstart}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="batchend"
                                type="text"
                                id="batchend"
                                className="form-control"
                                placeholder="Enter date here"
                                value={announcement.batchend}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="type"
                                type="text"
                                id="type"
                                className="form-control"
                                placeholder="Enter type here"
                                value={announcement.type}
                                onChange={handleInput}
                                required
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Batch
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container-md container-fluid ps-0">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        
                            <div className=' mt-5' >

                                <table class="table2">
                                    <tr class="tr2">
                                        <th class="th2 p-sm-2 p-1">PAPER</th>
                                        <th colspan="2" class="th2 p-sm-2 p-1">TEACHER</th>
                                        <th class="th2 p-sm-2 p-1">BATCH</th>
                                        <th class="th2 p-sm-2 p-1">BATCH STARTED</th>
                                        <th class="th2 p-sm-2 p-1">BATCH END</th>
                                        <th class="th2 p-sm-2 p-1"></th>

                                    </tr>
                                    {Array.isArray(detail) && detail.length > 0 ? (
                        detail.map((ann, id) => {
                            // Add a condition to check if the type is "rwp"
                            if (ann.type === "rwp") {
                                return (
                                    <tr class="tr2" key={id}>
                                        <td class="td2  p-sm-2 p-1 fw-bold">{ann.paper}</td>
                                        <td class="td2 p-sm-2 p-1">{ann.teacher}</td>
                                        <td class="td2 p-lg-2 p-1"></td>
                                        <td class="th2 p-sm-2 p-1">{ann.batch}</td>
                                        <td class="th2 p-sm-2 p-1 text-success">{ann.batchstart}</td>
                                        <td class="th2 p-sm-2 p-1 text-danger">{ann.batchend}</td>
                                        <td class="th2 p-sm-2 p-1 text-danger">{ann.type}</td>
                                        <th class="th2 d-flex justify-content-start">
                                            <Link to={`/UpdateF8CACalender/${ann._id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                            <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann._id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                        </th>
                                    </tr>
                                );
                            } else {
                                return null; // Do not render if type is not "rwp"
                            }
                        })
                    ) : (
                        <p>Not Available</p>
                    )}
                                </table>
                            </div>
                        
                        <div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Test;
