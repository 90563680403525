import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function STCACalender() {
    const navigate = useNavigate();

    const [announcement, setAnnouncement] = useState({
        paper: "",
        teacher: "",
        batch: "",
        batchstart: "",
        batchend: ""
    });
    const [detail, setDetail] = useState({});

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncement({ ...announcement, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendURL}/CACalenderST`, announcement);
            console.log(response);
            toast.success("ST Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAnnouncement({
                paper: "",
                teacher: "",
                batch: "",
                batchstart: "",
                batchend: ""
            }); // Clear the input fields after successful upload
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

const callProfile = useCallback( async () => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
  
          redirect: "follow"
        };
  
        fetch(`${backendURL}/getCACalenderST`, requestOptions)
          .then((response) => response.text())
          .then(async (result) => {
            // console.log("faculty", result);
  
            const data = JSON.parse(result);
            console.log(data);
            setDetail(data);

  
          })
          .catch((error) => console.error(error));
    } catch (err) {
        console.log(err);
        navigate("/AdminLogin");
    }
},[navigate, setDetail]);

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteCACalenderST/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("ST DATA Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, [callProfile]);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="my-5">
                <h1 className="fw-bold text-center fontcolordark">CA ST Calender</h1>

            </div>
            <div className="">
                <form method="POST" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="paper"
                                type="text"
                                id="paper"
                                className="form-control"
                                placeholder="Enter paper here"
                                value={announcement.paper}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="teacher"
                                type="text"
                                id="teacher"
                                className="form-control"
                                placeholder="Enter teacher name"
                                value={announcement.teacher}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right  mt-3">
                            <input
                                name="batch"
                                type="number"
                                id="batch"
                                className="form-control"
                                placeholder="Enter Batch here"
                                value={announcement.batch}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="batchstart"
                                type="text"
                                id="batchstart"
                                className="form-control"
                                placeholder="Enter Batchstart here"
                                value={announcement.batchstart}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="batchend"
                                type="text"
                                id="batchend"
                                className="form-control"
                                placeholder="Enter batchend here"
                                value={announcement.batchend}
                                onChange={handleInput}
                                required
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Batch
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container-md container-fluid px-md-1 px-0">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        <div className="">
                            <div className=' mt-5' >

                                <table class="table2 ">
                                    <tr class="tr2">
                                        <th class="th2 p-sm-2 p-1">PAPER</th>
                                        <th colspan="2" class="th2 p-sm-2 p-1">TEACHER</th>
                                        <th class="th2 p-sm-2 p-1">BATCH</th>
                                        <th class="th2 p-sm-2 p-1">BATCH STARTED</th>
                                        <th class="th2 p-sm-2 p-1">BATCH END</th>
                                        <th class="th2 p-sm-2 p-1"></th>

                                    </tr>
                                    {Array.isArray(detail) && detail.length > 0 ? (
                                        detail.map((ann, id) => (

                                            <tr class="tr2" key={id}>
                                                <td class="td2  p-sm-2 p-1 fw-bold">{ann.paper}</td>
                                                <td class="td2 p-sm-2 p-1">{ann.teacher}</td>
                                                <td class="td2 p-lg-2 p-1"></td>

                                                <td class="th2 p-sm-2 p-1">{ann.batch}</td>
                                                <td class="th2 p-sm-2 p-1 text-success">{ann.batchstart}</td>
                                                <td class="th2 p-sm-2 p-1 text-danger">{ann.batchend}</td>
                                                <th class="th2 d-flex justify-content-start">
                                                
                                                    <Link to={`/UpdateSTCACalender/${ann.id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                                    <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                               
                                                </th>
                                                
                                            </tr>





                                        ))
                                    ) : (
                                        <p>Not Available</p>
                                    )}
                                </table>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default STCACalender;
