import React from 'react';
import Navbar from '../Components/navbar';
import "../CSS/ACCAProgram.css"
import Sectiontitle1 from "../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import { Link } from 'react-router-dom';
import { currentYear } from '../constant';
import CollegeNavbar from '../Components/CollegeNavbar';

function ACCAProgram() {
    return (
        <>
            <CollegeNavbar />
            <div className='ACCAprogrambg'>
                <div className=' container-fluid ps-5 text-light filter2'>
                    <p>Home / ACCA Programs</p><br></br>
                    <h1 className='fw-bold'>ACCA Programs</h1>
                </div>
            </div>

            <div className='container-fluid p-4'>
                <h4 className='textdarkblue py-3 fw-bold ps-5'>Campus Quick Link</h4>
                <div className='d-flex flex-wrap'>
                    <div className='col-lg-3 col-12 p-4 bg-lightgrey rounded-4'>
                        <ul className="nav nav-tabs d-flex flex-column fontsmaller" >
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link d-flex justify-content-between  active show" data-bs-toggle="tab" data-bs-target="#ACCA">
                                    ACCA Program
                                    <i class="bi bi-arrow-right  fs-5 fw-bold text-info"></i>
                                </a>

                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <Link className="nav-link  d-flex justify-content-between  black" to="/CollegeAdmission">
                                    Admission
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </Link>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <Link className="nav-link  d-flex justify-content-between  black" to="/ACCAFeeStructure">
                                    Fee Structure
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </Link>
                            </li>
                            {/* <li className="nav-item quicklink p-0 rounded-3">
                                <Link className="nav-link  d-flex justify-content-between  black" to="/ACCAIsalamabadCalender">
                                    F8 ISB Calender
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </Link>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <Link className="nav-link  d-flex justify-content-between  black" to="/ACCASatelliteTownCalender">
                                    Satellite Town Calender
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </Link>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <Link className="nav-link  d-flex justify-content-between  black" to="/ACCA_RARCalender" >
                                    RAR Calender
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </Link>
                            </li> */}
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#AdmissionAtALP">
                                    Admission At ALP
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#EntryRoutine">
                                    Entry Routine
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#ExemptionAvailable">
                                    Exemptions for other Professional Qualifications
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>

                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#AcademicCalender">
                                    Academic Calenders
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>

                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#PracticalExperienceRequirements">
                                    Practical Experience Requirements
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>

                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#ACCA/OxfordBrookesUniversityDegreePartnership">
                                    ACCA / Oxford Brookes University Degree Partnership
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>

                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#ImportantContacts">
                                    Important Contacts
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#HowToRegister">
                                    How To Register
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>

                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#Syllabus&ProgressionRules">
                                    Syllabus & Progression Rules
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#ExaminationStructure">
                                    Examination Structure
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>

                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#FutureProgression">
                                    Future Progession
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#ACCAProfessionalCourse">
                                    ACCA Professional Course
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#ExaminationRules">
                                    Examination Rules
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            <li className="nav-item quicklink p-0 rounded-3">
                                <a className="nav-link  d-flex justify-content-between  black" data-bs-toggle="tab" data-bs-target="#CBEAB-LW">
                                    CBE AB - LW
                                    <i class="bi bi-arrow-right fs-5 fw-bold text-info"></i>
                                </a>
                            </li>
                            {/* Add similar code for Lunch and Dinner tabs */}
                        </ul>
                    </div>










                    <div className='col-lg-9 col-12 lh-lg'>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="ACCA">
                                <div className='container ps-5'>

                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>ACCA Qualification</span>&nbsp; &nbsp;
                                        <img height={"60%"} src={Sectiontitle2}></img>

                                    </div>

                                    <p>The ACCA Qualification is a highly-regarded accountancy qualification. In order to become a qualified chartered certified accountant and use the designatory letters “ACCA” after your name you need to complete:</p>
                                    <ul className='list-unstyled'>
                                        <li className='ps-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    13 exams (exemption can be claimed in nine exams)
                                                </div>
                                            </div>

                                        </li>
                                        <li className='px-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    Three-years’ relevant practical experience requirements
                                                </div>
                                            </div>


                                        </li>
                                        <li className='px-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    An Ethics and Professional Skills module
                                                </div>
                                            </div>


                                        </li>
                                    </ul>



                                    <div>
                                        <p>
                                            ACCA (Association of Chartered Certified Accountants) is the largest and fastest-growing international accountancy body, with over 486,000 students and 200,000 members in 180 countries. More people around the world qualify in-country with ACCA than any other international body. ACCA’s reputation and influence are respected worldwide by employers, government and the profession itself.<br></br><br></br>

                                            ACCA’s Members, who are known as Chartered Certified Accountants, are employed in industry, financial services, the public sector, or in public practice. With statutory recognition in the UK and Ireland it has the authority to license its members to work as registered auditors. In the UK, it also authorises its members to undertake insolvency work. Outside the UK, ACCA’s are recognised under the EU’s Mutual Recognition Directive and in many countries around the world.
                                        </p>
                                        <p>ACCA offers four core qualifications:</p>
                                    </div>
                                    <div>
                                        <ul className='list-unstyled'>

                                            <li className='px-5'>
                                                <div className='d-flex'>
                                                    <div>
                                                        <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                    </div>


                                                    <div className='px-3'>
                                                        A suite of awards, including certificates, diplomas and a revised Certified Accounting Technician (CAT) qualification – (ACCA Foundation Diploma)
                                                    </div>
                                                </div>


                                            </li>

                                            <li className='px-5'>
                                                <div className='d-flex'>
                                                    <div>
                                                        <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                    </div>


                                                    <div className='px-3'>
                                                        A Professional Qualification enabling members to reach the highest level in accountancy – (ACCA)
                                                    </div>
                                                </div>


                                            </li>



                                            <li className='px-5'>
                                                <div className='d-flex'>
                                                    <div>
                                                        <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                    </div>


                                                    <div className='px-3'>
                                                        A Bachelor of Science in Applied Accounting which is centered on the needs of employers for practical, professional accounting – BSc (Hons)
                                                    </div>
                                                </div>


                                            </li>

                                            <li className='px-5'>
                                                <div className='d-flex'>
                                                    <div>
                                                        <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                    </div>


                                                    <div className='px-3'>
                                                        A Masters in Professional Accountancy designed to broaden business understanding and enhance strategic decision making – (MSc)
                                                    </div>
                                                </div>


                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>



                            <div className="tab-pane fade" id="AdmissionAtALP">
                                <div className='container ps-5'>

                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Admission At ALP</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <ul className='list-unstyled'>

                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    SKANS School of Accountancy (Islamabad Campus) is Platinum Status Approved Learning Partner(ALP).
                                                </div>
                                            </div>


                                        </li>
                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    SKANS School of Accountancy (Rawalpindi Campus) is GOLD Status Approved Learning Partner(ALP).
                                                </div>
                                            </div>


                                        </li>
                                    </ul>

                                    <p className='fw-bold fs-5'>Keeping Standards High!</p>
                                    <p>The pass rates of SKANS are well above the worldwide pass rates of ACCA backed by the commitment of our lecturers, high quality tuition and student support.</p>
                                </div>

                            </div>




                            <div className="tab-pane fade" id="EntryRoutine">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Entry Routine</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <p>ACCA offers various entry routes, depending on your age and previous academic qualifications.</p>
                                    <ul className='list-unstyled'>

                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>
                                                <div className='px-3'>
                                                    Minimum Entry Requirements
                                                </div>
                                            </div>
                                        </li>

                                        <li className='ps-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-all iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    2 passes at GCE A’ Levels and 3 passes at GCSE/GCE O Level in 5 separate subjects, including English and Mathematics
                                                </div>
                                            </div>


                                        </li>

                                        <li className='ps-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-all iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>
                                                <div className='px-3'>
                                                    American Higher School Certificate with atleast 50%
                                                </div>
                                            </div>
                                        </li>

                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>
                                                <div className='px-3'>
                                                    Graduate Entry Route
                                                </div>
                                            </div>
                                        </li>


                                        <li className='ps-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-all iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>
                                                <div className='px-3'>
                                                    Graduation, B.Com, BA, BSc from a recognised University
                                                </div>
                                            </div>
                                        </li>

                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>
                                                <div className='px-3'>
                                                    Access to ACCA via FD qualification
                                                </div>
                                            </div>
                                        </li>

                                        <li className='ps-5'>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check2-all iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>
                                                <div className='px-3'>
                                                    Complete the Diploma in Accounting and Business through the Foundation Diploma route, and you can continue your studies with the Certified Accounting Technician Qualification, or you can transfer onto the ACCA Qualification, miss out the first three exams and continue your studies from the fourth exam (LW – Corporate and Business Law) onwards.
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <h3 className='fw-bold fs-5 textdarkblue pt-5'>Removal of Mature Student Entry Route (MSER)</h3>
                                    <p>If you would have normally registered on the ACCA Qualification via the Mature Student Entry Route (MSER), you should now register on Foundations in Accountancy. MSER is no longer available.
                                        This is because now that we have introduced Foundations in Accountancy, which is open entry and does not require you to have any previous academic qualifications to apply, it is possible for you to enter at any level within Foundations in Accountancy, including the Diploma in Accounting and Business.</p>
                                    <p>The exams FAB, FMA and FFA on Foundations in Accountancy are identical to AB,FA and MA of the ACCA Qualification. If you would have normally registered on MSER, you are now better placed when you register on Foundations in Accountancy. This is because there is no restriction on the number of exams you take, the order you choose to take them in, or the time you can take to complete FAB, FMA and FFA on Foundations in Accountancy.</p>

                                    <h3 className='fw-bold textdarkblue '>Claiming Exemptions</h3>
                                    <p>Relevant degree holders from ACCA-accredited institutions may be exempted from all nine exams within the Fundamentals Level and register directly at the Professional Level. Degrees with some relevance may also qualify for exemptions. For details, refer to <a className='text-decoration-none text-info text-uppercase' href='https://www.accaglobal.com/gb/en.404.html?/content/accaglobal/students/study_exams/exemptions.html'> ACCAGlobal Exemption Support</a></p>
                                </div>

                            </div>






                            <div className="tab-pane fade" id="ExemptionAvailable">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Exemptions Available To ACCA Affiliates</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <p>ACCA (UK) qualified students are eligible for the following other professional qualifications:</p>
                                    <ul className='list-unstyled'>

                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    A qualified ACCA (UK) will get maximum 5 exemptions in the Institute of Chartered Accountants of Pakistan (ICAP)
                                                </div>
                                            </div>


                                        </li>
                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    A qualified ACCA (UK Stream) needs to appear for 3 papers of Institute of Chartered Accountants of England & Wales (ICAEW) only, remaining papers being fully exempt.
                                                </div>
                                            </div>


                                        </li>


                                        <li className=''>
                                            <div className='d-flex'>
                                                <div>
                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                </div>


                                                <div className='px-3'>
                                                    A qualified ACCA needs to appear for 3 papers of Chartered Institute of Management Accountants UK (CIMA) only, remaining papers being fully exempt after clearing CIMA Gateway.
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="AcademicCalender">
                                <div className='container ps-5'>

                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Academic Calender</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>
                                    <h3 className='text-center fw-bold textdarkblue'>

                                    </h3>

                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#January" aria-expanded="true" aria-controls="January">
                                                    January
                                                </button>
                                            </h2>
                                            <div id="January" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul class="list-unstyled">
                                                        <li>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                                </div>


                                                                <div className='px-3'>
                                                                    Your annual subscription fee is now due.
                                                                </div>
                                                            </div>

                                                        </li>
                                                        <li>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                                </div>


                                                                <div className='px-3'>
                                                                    11-20 result declaration for December attempt.
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                                </div>


                                                                <div className='px-3'>
                                                                    Application for exemptions to be reflected in the June exam options must be submitted by 31 January (or at least two weeks earlier if submitting to an ACCA office outside the UK).
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                                                </div>


                                                                <div className='px-3'>
                                                                    Requests for an administrative review should be received within 10 working days from the release of December results.
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Feburary
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                1 standard exam fee deadline for March exam session
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                Amendment in exam entry should be done by this date
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                8 Late fee submission deadline for March exams
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                15 early bird discount deadline for June exam session
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    March
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className='list-unstyled'>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                15 early bird discount deadline for June exam session
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingApril">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#April" aria-expanded="false" aria-controls="collapseThree">
                                                    April
                                                </button>
                                            </h2>
                                            <div id="April" class="accordion-collapse collapse" aria-labelledby="headingApril" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                10-20 result declaration for March attempt
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                Requests for an administrative review should be received within 10 working days from the release of March results
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingMay">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMay" aria-expanded="false" aria-controls="collapseMay">
                                                    May
                                                </button>
                                            </h2>
                                            <div id="collapseMay" class="accordion-collapse collapse" aria-labelledby="headingMay" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                2 standard exam fee deadline for June exam session
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                Amendment in exam entry should be done by this date
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                9 late exam fee submission deadline for June session
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                16 early bird discount deadline for September exam session
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>





                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingJune">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJune" aria-expanded="false" aria-controls="collapseJune">
                                                    June
                                                </button>
                                            </h2>
                                            <div id="collapseJune" class="accordion-collapse collapse" aria-labelledby="headingJune" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                5-9 exam dates for June attempt
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingJuly">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJuly" aria-expanded="false" aria-controls="collapseJuly">
                                                    July
                                                </button>
                                            </h2>
                                            <div id="collapseJuly" class="accordion-collapse collapse" aria-labelledby="headingJuly" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                11-20 result declaration of June attempt
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                Requests for an administrative review should be received within 10 working days from the release of June results
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingAugust">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAugust" aria-expanded="false" aria-controls="collapseAugust">
                                                    August
                                                </button>
                                            </h2>
                                            <div id="collapseAugust" class="accordion-collapse collapse" aria-labelledby="headingAugust" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                1 standard exam fee deadline for September session
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                1 Amendment in exam entry should be done by this date
                                                            </div>
                                                        </li>

                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                8 late exam fee deadline for September exams
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                15 early bird discount deadline for December attempt
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>





                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSeptember">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeptember" aria-expanded="false" aria-controls="collapseSeptember">
                                                    September
                                                </button>
                                            </h2>
                                            <div id="collapseSeptember" class="accordion-collapse collapse" aria-labelledby="headingSeptember" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="d-flex align-items-center">
                                                            <div>
                                                                <i className="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div className="px-3">
                                                                5-9 exam dates for September attempt
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOctober">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOctober" aria-expanded="false" aria-controls="collapseOctober">
                                                    October
                                                </button>
                                            </h2>
                                            <div id="collapseOctober" class="accordion-collapse collapse" aria-labelledby="headingOctober" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                17 result declaration for September attempt
                                                            </div>
                                                        </li>
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                31 standard exam fee deadline for December exam session
                                                            </div>
                                                        </li>
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                31 Amendment in exam entry should be done by this date
                                                            </div>
                                                        </li>
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                Requests for an administrative review should be received within 10 working days from the release of June results
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>






                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingNovember">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNovember" aria-expanded="false" aria-controls="collapseNovember">
                                                    November
                                                </button>
                                            </h2>
                                            <div id="collapseNovember" class="accordion-collapse collapse" aria-labelledby="headingNovember" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                7 late exam fee deadline for December exams

                                                            </div>
                                                        </li>
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                9 early bird discount deadline for March attempt
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>






                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingDecember">
                                                <button class="accordion-button fs-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDecember" aria-expanded="false" aria-controls="collapseDecember">
                                                    December
                                                </button>
                                            </h2>
                                            <div id="collapseDecember" class="accordion-collapse collapse" aria-labelledby="headingDecember" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li class="d-flex align-items-center">
                                                            <div>
                                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                            </div>
                                                            <div class="px-3">
                                                                5-9 exam dates for December session

                                                            </div>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        {/* add more item */}
                                    </div>

                                </div>

                            </div>



                            <div className="tab-pane fade" id="PracticalExperienceRequirements">
                                <div className='container ps-5'>


                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Practical Experience Requirements</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>



                                    <ul class="list-unstyled">
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                In addition to completing exams, you will need to gain relevant, supervised practical work experience.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You can gain your practical experience before, during or after completion of the exams but ACCA strongly recommends that you gain your experience alongside or soon after sitting your exams.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You must demonstrate effectiveness and achievement in the workplace by meeting 13 predefined performance objectives, overseen and agreed by a workplace mentor.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Complete a minimum of three-years’ relevant practical experience.
                                            </div>
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Record your Practical Experience Requirement (PER) in My Experience through my ACCA.
                                            </div>
                                        </li>
                                    </ul>


                                    <p>Employers expect ACCA members to show high levels of knowledge and ability in the workplace and to apply in practice the knowledge and techniques gained through your studies towards the ACCA exams.</p>



                                </div>

                            </div>






                            <div className="tab-pane fade" id="ACCA/OxfordBrookesUniversityDegreePartnership">
                                <div className='container ps-5'>

                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"50%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-4 text-center textdarkblue'>ACCA / Oxford Brookes University Degree Partnership</span>&nbsp; &nbsp;
                                        <img height={"50%"} src={Sectiontitle2}></img>

                                    </div>

                                    <h5 className='text-center fw-bold textdarkblue text-center'>B.Sc. Honours Degree in Applied Accounting<br></br>
                                        (Oxford Brookes University)</h5>
                                    <p>
                                        ACCA has developed a unique relationship with the UK’s Oxford Brookes University – rated top new University in 2000 for the sixth consecutive year by The Times newspaper. This valuable initiative enables students to sit for an Oxford Brookes BSc Honours degree in Applied Accounting while taking their ACCA examinations.
                                    </p>
                                    <p>
                                        The Oxford Brookes BSc (Hons) Degree in Applied Accounting is a highly regarded qualification, which is based on practical, professional, employer-driven accounting requirements. It enables students to prove that not only have they mastered the knowledge required for the degree, but that they have the key skills required by graduate employers.
                                    </p>
                                    <p>
                                        Students who are entitled to the degree are required to complete the Fundamentals Level of the ACCA qualification, the online professional ethics module and by demonstrating their research, analysis, interpersonal and IT skills by completing a Research and Analysis project.
                                    </p>
                                    <p>
                                        The <strong>Higher Education Commission (HEC)</strong> in Pakistan recognises the BSc (Hons) in Applied Accounting from Oxford Brookes University as equivalent to a three year bachelors degree. Students who have met the criteria outlined below will be able to apply to the HEC for recognition of their degree. For further information, refer
                                    </p>
                                    <p>
                                        to <a className='text-info text-decoration-none' href="http://www.accaglobal.com/pakistan/students/local_information/hec">http://www.accaglobal.com/pakistan/students/local_information/hec</a>
                                    </p>

                                    <h3>Masters in Business Administration (Oxford Brookes University)</h3>
                                    <p>
                                        This MBA is a unique Qualification offered to ACCA members and developed through a strategic alliance between ACCA and Oxford Brookes University. The combination of ACCA’s experience in global accountancy and the renowned quality of Oxford Brookes’ Business School has resulted in a programme designed for the international business environment of the future, fully supported online through the Virtual Campus. The MBA is accredited by the Association of MBA’s (AMBA).

                                    </p>
                                    <p>
                                        The MBA is internationally acknowledged to be the premier business qualification, and is AMBA accredited. By studying for MBA you will be investing in your future. And it will also be a significant step in your career development.
                                    </p>
                                </div>

                            </div>






                            <div className="tab-pane fade" id="ImportantContacts">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Important Contacts</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <h5 className=' fw-bold textdarkblue'>
                                        ACCA STUDENT SERVICES UK OFFICE
                                    </h5>

                                    <div className='border border-start-0 border-end-0 py-2'>
                                        <p>2-Central Quay, 89 Hyde Park Street,Glasgow G3 8BW UK</p>
                                        <ul className='list-unstyled'>
                                            <li>
                                                <strong>Tel:</strong> + 44(0) 141 582 2000
                                            </li>
                                            <li>
                                                <strong>Fax:</strong> + 44(0) 141 582 2222
                                            </li>
                                            <li>
                                                <strong>E-mail:</strong> <a className='text-decoration-none' href="students@accaglobal.com">students@accaglobal.com</a>
                                            </li>
                                            <li>
                                                <strong>Website: </strong><a className='text-decoration-none' href='www.accaglobal.com'>www.accaglobal.com</a>
                                            </li>
                                        </ul>


                                    </div>



                                    <h5 className=' fw-bold textdarkblue'>
                                        ACCA STUDENT SERVICES ISLAMABAD OFFICE
                                    </h5>
                                    <div className='border border-start-0 border-end-0 py-2'>
                                        <p>Office No. 5, 2nd Floor, SNS Centre,Fazal-ul-Haq Road, Blue Area,Islamabad, Pakistan</p>
                                        <ul className='list-unstyled'>
                                            <li>
                                                <strong>Tel:</strong> +92 (0) 51 111-222-275
                                            </li>
                                            <li>
                                                <strong>Fax:</strong> +92 (0) 51 32876605
                                            </li>
                                            <li>
                                                <strong>E-mail:</strong> <a className='text-decoration-none' href="info@pk.accaglobal.com">info@pk.accaglobal.com</a>
                                            </li>
                                            <li>
                                                <strong>Website: </strong><a className='text-decoration-none' href='www.pakistan.accaglobal.com'>www.pakistan.accaglobal.com</a>
                                            </li>
                                        </ul>
                                    </div>


                                    <h5 className=' fw-bold textdarkblue'>
                                        ACCA STUDENT SERVICES LAHORE OFFICE
                                    </h5>
                                    <div className='border border-start-0 border-end-0 py-2'>
                                        <p>61-C Main Gulberg, Main Boulevard, Lahore 54660 Pakistan</p>
                                        <ul className='list-unstyled'>
                                            <li>
                                                <strong>Tel:</strong> +92 (0) 42 111-222-275/ 35759129 / 35714361
                                            </li>
                                            <li>
                                                <strong>Fax:</strong> +92 (0) 42 35759346
                                            </li>
                                            <li>
                                                <strong>E-mail:</strong> <a className='text-decoration-none' href=" info@pk.accaglobal.com"> info@pk.accaglobal.com</a>
                                            </li>
                                            <li>
                                                <strong>Website: </strong><a className='text-decoration-none' href='www.pakistan.accaglobal.com'>www.pakistan.accaglobal.com</a>
                                            </li>
                                        </ul>
                                    </div>



                                </div>

                            </div>


                            <div className="tab-pane fade" id="HowToRegister">
                                <div className='container ps-5'>

                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>How To Register</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>


                                    <ul class="list-unstyled">

                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You need to register with ACCA as a student in order to take the exams.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                The quickest and easiest way is to apply for your accounting course online. You can do it yourself or through SKANS. Applying online also allows you to upload all your supporting documents. This means your application will be processed much quicker and you will know at which level you can start your studies shortly after submitting your application.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Students are not restricted to June and December paper-based exam sessions as CBEs can be sat at any time of the year.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                ACCA offers a number of entry routes for studying towards the ACCA Qualification, designed to take into account equivalent local qualifications and previous educational achievements. Check entry requirements and exemptions available on ACCAGlobal along with the information on Joint Examination Schemes.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Remember to send supporting documentation with your registration form including a passport style photograph, proof of identity and attested copies of previous academic qualifications as proof of minimum entry eligibility or for claiming exemptions.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You can track the status of your application also online.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                ACCA will issue you with a passcode to access your student details from the ACCA website and student post-registration information containing details about the exams, practical experience requirements, Professional Ethics module and the service and support provided by ACCA.
                                            </div>
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                As soon as you have received confirmation from ACCA that your application has been approved, you may make arrangements to sit CBEs.
                                            </div>
                                        </li>
                                    </ul>

                                </div>

                            </div>



                            <div className="tab-pane fade" id="Syllabus&ProgressionRules">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Syllabus & Progression Rules</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <ul class="list-unstyled">

                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                The syllabus is divided into two levels – Fundamentals and Professional.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                All Fundamental Level papers are mandatory.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                The Professional Level includes mandatory Essentials papers as well as Optional papers.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                There are 16 papers available in total. You only need to pass 14 papers (unless exemptions are awarded) because you only need to complete two out of the four Options papers.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                The pass marks for all papers are 50%.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You can progress through the syllabus at your own pace. You may attempt a maximum of four exams at any six-month exam cycle, including computer-based exams. Papers may be attempted from different modules at the same sitting as long as the modules are attempted in order.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Students may vary the order in which they attempt exams within each module but they may not vary the order of the modules. However, ACCA strongly recommends that papers within a module are taken in order.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You have the choice of sitting computer-based or paper-based exams for Papers F1, F2, and F3.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Paper-based exams can be taken in any one of over 400 exam centres around the world.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Paper-based exams are held twice a year – in June and December.
                                            </div>
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                You can:
                                            </div>
                                        </li>
                                    </ul>


                                    <ol class="mb-3">
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Enter for exams all year round
                                            </div>
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Enter for one of the next two exam sessions
                                            </div>
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Add exams until the end of the standard entry closing date (the appropriate early or standard fee will apply, depending on when additional exams are added)
                                            </div>
                                        </li>

                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Remove exam entries made until the end of the standard entry closing date
                                            </div>
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Amend exam center, stream, or variant without charge until the end of the standard exam entry closing date
                                            </div>
                                        </li>

                                    </ol>


                                </div>

                            </div>





                            <div className="tab-pane fade" id="ExaminationStructure">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'> Examination Structure</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <p>You have unlimited time to complete fundamental level papers and seven years to complete professional level papers. You take ACCA exams when it suits you – you choose the pace. You also have the option to select papers based on International Accounting and Auditing Standards and, in certain countries, variant papers based on local law and tax. You may also claim exemptions from certain exams, depending on what other qualifications you have gained.</p>
                                    <h3 className='text-center fw-bold textdarkblue'>Fundamentals (9 Papers)</h3>
                                    <h5 className='fw-bold textdarkblue'>Applied Knowledge </h5>


                                    <table class="table table-hover table-light table-striped">
                                        <thead>
                                            <tr>
                                                <th>PAPER</th>
                                                <th>SUBJECT</th>
                                                <th>EXAM SESSION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>AB</td>
                                                <td>Accountant in Business</td>
                                                <td>CBE* (monthly)</td>
                                            </tr>
                                            <tr>
                                                <td>MA</td>
                                                <td>Management Accounting</td>
                                                <td>CBE* (monthly)</td>
                                            </tr>
                                            <tr>
                                                <td>FA</td>
                                                <td>Financial Accounting</td>
                                                <td>CBE* (monthly)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h5 className='fw-bold textdarkblue'>APPLIED SKILLS</h5>

                                    <table class="table table-hover table-light table-striped">
                                        <thead>
                                            <tr>
                                                <th>PAPER</th>
                                                <th>SUBJECT</th>
                                                <th>EXAM SESSION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>LW</td>
                                                <td>Corporate and Business Law</td>
                                                <td>CBE (monthly)</td>
                                            </tr>
                                            <tr>
                                                <td>PM</td>
                                                <td>Performance Management</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>TX</td>
                                                <td>Taxation</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>FR</td>
                                                <td>Financial Reporting</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>AA</td>
                                                <td>Audit and Assurance</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>FM</td>
                                                <td>Financial Management</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className='text-center fw-bold textdarkblue'>PROFESSIONAL LEVEL (4 Papers)</h3>
                                    <h5 className='py-2 fw-bold textdarkblue'>STRATEGIC PROFESSIONAL CORE</h5>

                                    <table class="table table-hover table-light table-striped">
                                        <thead>
                                            <tr>
                                                <th>PAPER</th>
                                                <th>SUBJECT</th>
                                                <th>EXAM SESSION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>SBL</td>
                                                <td>Strategic Business Leader</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>SBR</td>
                                                <td>Strategic Business Reporting</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h5 className='fw-bold textdarkblue py-2'>STRATEGIC PROFESSIONAL OPTIONAL PAPERS</h5>

                                    <table class="table  table-hover table-light table-striped">
                                        <thead>
                                            <tr>
                                                <th>PAPER</th>
                                                <th>SUBJECT</th>
                                                <th>EXAM SESSION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>AFM</td>
                                                <td>Advanced Financial Management</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>APM</td>
                                                <td>Advanced Performance Management</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>ATX</td>
                                                <td>Advanced Taxation</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                            <tr>
                                                <td>AAA</td>
                                                <td>Advanced Audit and Assurance</td>
                                                <td>March, June, September & December</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>






                            <div className="tab-pane fade" id="FutureProgression">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Future Progession</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>


                                    <p>Students who have passed knowledge and skills modules (9-papers) of the ACCA qualification are eligible to apply for an Oxford Brookes University (OBU) Bsc (Hons) degree in Applied Accounting.</p>

                                    <p>Qualified members may get MBA degree of Oxford Brookes University which was specifically developed through a strategic alliance formed between the business school at the OBU and ACCA. After qualifying ACCA, you can go for CA, ICAEW, CIMA, CFA® etc; as your further career progression.</p>
                                </div>

                            </div>





                            <div className="tab-pane fade" id="ACCAProfessionalCourse">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"80%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>ACCA Professional Course</span>&nbsp; &nbsp;
                                        <img height={"80%"} src={Sectiontitle2}></img>

                                    </div>

                                    <p>
                                        The ACCA Professional Scheme is a highly relevant, targeted combination of study and practical experience. The syllabus is a result of extensive research and consultation with governments, students, colleges and employers from a range of public and private sector organisations.
                                    </p>
                                    <p>
                                        Wherever you are in the world, ACCA strives to offer consistently high standards of training and support. Its personal and virtual study options have been developed to ensure that you have maximum control over what you learn, the way that you learn it, and the pace at which you work. Future employers will be aware of your potential, because the ACCA qualification is highly regarded throughout the world.
                                    </p>
                                    <p>
                                        We have effective relationships with local and multinational organisations, accounting bodies, corporations and institutions including governments and even the United Nations. The ACCA qualification is a passport to a new world of opportunity – once you become a member, the learning process continues throughout your career. The range of educational opportunities includes a degree qualification, plans for a specifically tailored MBA and a constantly updated range of courses in all areas of finance and management.
                                    </p>



                                </div>

                            </div>


                            <div className="tab-pane fade" id="ExaminationRules">
                                <div className='container ps-5'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"50%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Examination Rules</span>&nbsp; &nbsp;
                                        <img height={"50%"} src={Sectiontitle2}></img>

                                    </div>


                                    <ul>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Students have unlimited time to complete fundamental level papers and seven years to complete professional level papers.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                The pass marks for all papers are 50%.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                There are four manual examination sessions per year – in March, June, September and December
                                            </div>
                                        </li>

                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Up to four papers can be taken in a single examination session.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Credits gained successfully in Fundamentals and Professional Level Papers can be retained, to prevent unnecessary re-taking of examinations.
                                            </div>
                                        </li>

                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                The Knowledge module must be completed before the Essentials & Options module can be attempted
                                            </div>
                                        </li>
                                    </ul>

                                </div>

                            </div>


                            <div className="tab-pane fade" id="CBEAB-LW">
                                <div className='container ps-5 lh-lg'>
                                    <div className='d-flex justify-content-center align-items-center fw-bold'>
                                        <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <span className='fs-3 text-center textdarkblue'>Computer Based Examination (CBE)</span>&nbsp; &nbsp;
                                        <img height={"60%"} src={Sectiontitle2}></img>

                                    </div>

                                    <p>You can control the speed of your progression and specialism you wish to develop. You can choose to sit either paper-based exams in March, June, September and December or on-demand Computer Based Exams (CBE) whichever suits you best. CBE are currently available for paper F1 to F4.</p>
                                    <p>Computer Based Examination offers you:</p>

                                    <ul className='list-unstyled'>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Flexibility – You are not restricted to March, June, September, and December paper-based examination sessions as you can sit CBE at any time of the year. CBE also offers flexibility for re-sits, which you can take at any time. There is no restriction on the number of times you can re-sit the Computer Based Exams.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Instant Feedback – Your result is displayed on the computer screen at the end of the examination.
                                            </div>
                                        </li>

                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3">
                                                Results are uploaded by the licensed centre and will be transferred to your ACCA account within 72 hours.
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex ">
                                            <div>
                                                <i class="bi bi-check-circle-fill iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3 pt-2">
                                                The CBE fee is to be deposited at the admission office with the requirements as follows:
                                                <ul className='list-unstyled'>
                                                    <li class="mb-3 d-flex align-items-center">
                                                        <div>
                                                            <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                        </div>
                                                        <div class="px-3 fs-5 fw-bold fontcolordark">
                                                            One passport size photograph.
                                                        </div>
                                                    </li>
                                                    <li class="mb-3 d-flex align-items-center">
                                                        <div>
                                                            <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                        </div>
                                                        <div class="px-3 fs-5 fw-bold fontcolordark">
                                                            A copy of ACCA registration card/number
                                                        </div>
                                                    </li>
                                                    <li class="mb-3 d-flex align-items-center">
                                                        <div>
                                                            <i class="bi bi-check2-all iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                                        </div>
                                                        <div class="px-3 fs-5 fw-bold fontcolordark">
                                                            A copy of an evidence of your age (could be ONE of: National ID-Card / Passport / Matric / O’ Level Result Certificate).
                                                        </div>
                                                    </li>


                                                </ul>

                                            </div>
                                        </li>

                                    </ul>

                                    <p>Students, who have not received their ACCA UK Registration cards, are advised to submit a proof of the ACCA registration number in the form of any email correspondence or the postal mail received from the ACCA Office.</p>

                                    <h4 className='fontcolordark fw-bold'>ID Verification Letter</h4>
                                    <p>Students not in possession of their ACCA Registration card are advised to get their ID Verification Letter made. The ID Verification letter is required to prove a student’s identity and is mandatory for their entry in the examination hall. Once the supporting documentation including proof of identity is submitted, we need at least 24-48 hours to process the timely receipt of your ID verification letters. The requests are processed, keeping in mind the need for the students to concentrate on their exam preparation and not to waste their time in commuting to the ACCA Lahore Office for these letters.</p>
                                    <h4 className='fontcolordark fw-bold'>CBE Progression Rules for ACCA</h4>
                                    <p>Students MUST be registered ACCA students, and hold a valid ACCA registration card prior to sitting the following papers by CBE</p>
                                    <ul>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3  fw-bold">
                                                AB – Accountant in Business
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3 fw-bold">
                                            MA – Management Accounting
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3  fw-bold">
                                            FA – Financial Accounting
                                            </div>
                                        </li>
                                        <li class="mb-3 d-flex align-items-center">
                                            <div>
                                                <i class="bi bi-check2-circle iconcolor rounded-5 fw-bold fs-4"></i>&nbsp;
                                            </div>
                                            <div class="px-3  fw-bold">
                                            LW – Corporate and Business Law
                                            </div>
                                        </li>

                                        
                                    </ul>
                                    <p>ACCA offers four exams windows in a year for the papers PM to AAA.</p>
                                    <p className='fw-bold fs-5'>March, June, September and December</p>
                                </div>

                            </div>

                            <div className="tab-pane fade" id="">
                                <div className='container ps-5'>
                                    <h3 className='text-center fw-bold textdarkblue'>
                                        Academic Calender
                                    </h3>



                                </div>

                            </div>
                            {/* Add similar code for Lunch and Dinner tab content */}
                        </div>
                    </div>
                </div>
            </div>





            <div className='bgdarkblue py-4 mt-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
        </>
    );
}

export default ACCAProgram;
