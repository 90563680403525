import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/navbar';
import "../../CSS/CAFeeStructure.css";
import { backendURL } from '../../constant';
import CollegeNavbar from '../../Components/CollegeNavbar';

function CAFeeStructure() {
    const [detail, setDetail] = useState([]);

    const callProfile = async () => {
        try {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getFeeStructure`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                console.log("highlight", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);

      
              })
              .catch((error) => console.error(error));
            // const res = await fetch("/getFeeStructure", {
            //     method: "GET",
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            //     credentials: "include",
            // });

            // if (res.status === 200) {
            //     const data = await res.json();
            //     console.log(data);
            //     setDetail(data);
            // } else {
            //     const error = new Error(res.statusText);
            //     throw error;
            // }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>
            <CollegeNavbar />
            <div className='FeeStructurebg'>
                <div className=' container-fluid ps-sm-5 ps-0 text-light filter15 text-center'>
                    <p >Home / CA FEE STRUCTURE</p><br></br>
                    <h1 className='fw-bold'>CA FEE STRUCTURE</h1>
                </div>
            </div>

            <div className='container my-5'>
                <table className="table2">
                    <tr className="tr2">
                        <th className="th2">Level</th>
                        <th className="th2">Name</th>
                        <th className="th2">Fee</th>
                    </tr>

                    {Array.isArray(detail) && detail.length > 0 ? (
                        detail.map((ann, id) => (
                            <tr className="tr2" key={id}>
                                <td className="td2  p-sm-2 p-1 fw-bold">{ann.level}</td>
                                <td className="td2 p-sm-2 p-1">{ann.name}</td>
                                <td className="td2 p-lg-2 p-1 text-success fw-bold">{ann.fees}/-</td>
                            </tr>
                        ))
                    ) : (
                        <p>Not Available</p>
                    )}
                </table>
            </div>
        </>
    );
}

export default CAFeeStructure;
