import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function Complaintbox() {
    const navigate = useNavigate();

    const [detail, setDetail] = useState({});


    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getComplaint`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                console.log("highlight", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);
      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    };

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteComplaint/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Batch Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <h1 className="text-center fontcolordark fw-bold">Complaint Box</h1>
            </div>
            <div className="mt-5 container-md container-fluid ps-0">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        
                            <div className=' mt-5' >

                                <table class="table2">
                                    <tr class="tr2">
                                        <th class="th2 p-sm-2 p-1">Name</th>
                                        <th colspan="2" class="th2 p-sm-2 p-1">Email</th>
                                        <th class="th2 p-sm-2 p-1">Mobile</th>
                                        <th class="th2 p-sm-2 p-1">Campus</th>
                                        <th class="th2 p-sm-2 p-1"></th>

                                    </tr>
                                    {Array.isArray(detail) && detail.length > 0 ? (
                                        detail.map((ann, id) => (

                                            <tr class="tr2" key={id}>
                                                <td class="td2  p-sm-2 p-1 fw-bold">{ann.name}</td>
                                                <td class="td2 p-sm-2 p-1">{ann.email}</td>
                                                <td class="td2 p-lg-2 p-1"></td>

                                                <td class="th2 p-sm-2 p-1">{ann.mobile}</td>
                                                <td class="th2 p-sm-2 p-1 text-success">{ann.campus}</td>
                                                
                                                <th class="th2 d-flex justify-content-start">
                                                
                                                    <Link to={`/ViewComplaint/${ann.id}`} ><i class="bi bi-eye fs-5 text-primary fw-bold"></i></Link>
                                                    <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                               
                                                </th>
                                                
                                            </tr>





                                        ))
                                    ) : (
                                        <p>Not Available</p>
                                    )}
                                </table>
                            </div>
                        
                        <div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Complaintbox;
