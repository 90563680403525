import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateACCAFacultyST() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [faculty, setFaculty] = useState({
        photos: "",
        name: "",
        qualification: "",
        specialization: "",
        experience: "",
        designation: "",

    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFaculty({ ...faculty, [name]: value });
    };
    const handlePhotoChange = (e) => {
        setFaculty({ ...faculty, photos: e.target.files[0] });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("photos", faculty.photos);
            formData.append("name", faculty.name);
            formData.append("qualification", faculty.qualification);
            formData.append("specialization", faculty.specialization);
            formData.append("experience", faculty.experience);
            formData.append("designation", faculty.designation);
      
            const response = await axios.patch(`${backendURL}/updateACCAFacultyST/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setFaculty({
                photos: "",
                name: "",
                qualification: "",
                specialization: "",
                experience: "",
                designation: "",
            }); // Clear the input fields after successful upload
            setTimeout(() => {
                navigate("/ACCAFacultyST");
            }, 1000);
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Member", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewACCAFacultyST/${id}`);
            const announcementData = response.data;
            setFaculty(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    // const handleDataUpload = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.patch(`/updateHomeAnnouncement/${id.trim()}`, Announcment);
    //         console.log(response);
    //         toast.success("Data Uploaded Successfully", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });

    //         setTimeout(() => {
    //             navigate("/HomeAnnouncement");
    //         }, 1000);
    //     } catch (error) {
    //         console.error(error);
    //         toast.error("Failed to Update Announcement", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });
    //     }
    // };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark mb-4">Update ACCA Faculty ST</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                <form method="POST" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around ">

                        <div className="col-md-4 border-right">
                            <input
                                name="photos"
                                id="photos"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handlePhotoChange}
                                
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="name"
                                type="text"
                                id="name"
                                className="form-control"
                                placeholder="Enter name here"
                                value={faculty.name}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="qualification"
                                type="text"
                                id="qualification"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Qualification here"
                                value={faculty.qualification}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="specialization"
                                type="text"
                                id="specialization"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Specialization here"
                                value={faculty.specialization}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="experience"
                                type="text"
                                id="experience"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Experience here"
                                value={faculty.experience}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="designation"
                                type="text"
                                id="designation"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Designation here"
                                value={faculty.designation}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Member
                        </button>
                    </div>
                </form>
                </div>
            </div>
        </>
    )
}

export default UpdateACCAFacultyST;
