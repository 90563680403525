import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendURL } from '../constant';
import axios from 'axios';

function Logout() {
    const navigate = useNavigate();

    const logout = async () => {
        try {
            const response = await fetch(`${backendURL}/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                // Remove the token from localStorage
                localStorage.removeItem('token');

                // Remove the authorization header if stored globally
                delete axios.defaults.headers.common['Authorization'];

                // Navigate to the login page
                navigate('/AdminLogin', { replace: true });
            } else {
                console.error('Failed to log out');
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };

    useEffect(() => {
        logout();
    }, []);

    return null;
}

export default Logout;
