import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateupcomingEventF8() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [event, setEvent] = useState({
        photos: [],
        date: "",
        time:"",
        title: "",
        description: "",


    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEvent({ ...event, [name]: value });
    };
    const handlePhotoChange = (e) => {
        const updatedUser = { ...event };
        updatedUser.photos = e.target.files; // Check that e.target.files contains selected files
        setEvent(updatedUser);
        console.log(updatedUser); // Log updatedUser to verify files are stored
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            if(event.photos){
            for (let i = 0; i < event.photos.length; i++) {
                formData.append('photos', event.photos[i]);
              }
            }
              formData.append("date", event.date);
              formData.append("title", event.title);
              formData.append("time", event.time);

              formData.append("description", event.description);
      
            const response = await axios.patch(`${backendURL}/updateF8upcomingEvent/${id.trim()}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setEvent({
                photos: [],
                date: "",
                title: "",
                description: "",
            }); // Clear the input fields after successful upload
            setTimeout(() => {
                navigate("/AdminupcomingEventF8");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Member", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewF8upcomingEvent/${id.trim()}`);
            const announcementData = response.data;
            setEvent(announcementData);
            console.log(announcementData)
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    // const handleDataUpload = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.patch(`/updateHomeAnnouncement/${id.trim()}`, Announcment);
    //         console.log(response);
    //         toast.success("Data Uploaded Successfully", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });

    //         setTimeout(() => {
    //             navigate("/HomeAnnouncement");
    //         }, 1000);
    //     } catch (error) {
    //         console.error(error);
    //         toast.error("Failed to Update Announcement", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });
    //     }
    // };
    const picurl = `${backendURL}/public/uploads/`

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center my-2 fontcolordark">Update Upcoming Event F8</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-md-5 ms-0 pb-1 col-md-11 shadow-lg mb-4 pt-5">
                <form method="POST" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around ">

                    <div className="col-md-5 border-right">
                            <input
                                name="photos"
                                id="photos"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handlePhotoChange}
                                multiple
                                
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="title"
                                type="text"
                                id="title"
                                className="form-control"
                                placeholder="Enter title here"
                                value={event.title}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="time"
                                type="text"
                                id="time"
                                className="form-control"
                                placeholder="Enter time here"
                                value={event.time}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="date"
                                type="text"
                                id="date"
                                className="form-control"
                                placeholder="Enter date here"
                                value={event.date}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="description"
                                type="text"
                                id="description"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Description here"
                                value={event.description}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        


                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Event
                        </button>
                    </div>
                </form>
                
                <div className='d-flex flex-wrap mt-5'>


                        {
                             typeof event.photos === 'string' ? (
                                event.photos.split(',').map((photo, id) => (
                                    <img key={id} className='img-fluid p-2' src={picurl + photo.trim()} alt={photo} style={{ width: '25%' }} />
                                ))
                            ) : (
                                <p>No photos found</p>
                            )
                        
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateupcomingEventF8;
