import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/AdmissionDetailSchool.css"
import Admissionpicdetail from "../../Photos/Admissionpicdetail.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"
import Registerform from "../../Pdf/registerformfeedback.pdf"
import { currentYear } from '../../constant'
import SchoolNavbar from '../../Components/SchoolNavbar'

function AdmissionDetailSchool() {
    return (
        <>
            <SchoolNavbar />
            <div className='AdmissionHarleybg'>
                <div className=' container-fluid ps-5 text-light filter15 text-center'>
                    <p >Home / School Admission / Addmission Detail</p><br></br>
                    <h1 className='fw-bold d-md-block d-none'>Harley Street Campus</h1>
                    <h1 className='fw-bold d-md-none d-block fs-1'>Harley Street Campus</h1>


                </div>

                {/* <div className=''>
                    <div id="mySidenav" class="sidenav position-fixed">
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="about">0312-3456789 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-whatsapp fs-1 rounded-end-5"></i></a>
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="blog">0312-3456789 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-facebook fs-1 rounded-end-5"></i></a>
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="projects">0312-3456789 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-envelope-at fs-1 rounded-end-5"></i></a>
                        <a className='d-flex align-items-center rounded-end-5 py-2 ps-3' href="#" id="contact">0312-3456789 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-telephone-fill fs-1 rounded-end-5"></i></a>

                    </div>
                </div> */}
            </div>

            <div className='bg-lightgrey'>
                <div className='container '>

                    <div className='d-flex justify-content-center align-items-center ' data-aos="fade-up">
                        <div className='b d-sm-block d-none mt-5'>
                        </div>
                        <div data-aos="zoom-in" className=' d-flex mt-5 align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 d-sm-block d-none'>ADMISSION DETAIL</h3>&nbsp;&nbsp;
                            <h6 className='text-light fw-bold mb-0 d-sm-none d-block'>ADMISSION DETAIL</h6>&nbsp;&nbsp;

                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b d-sm-block d-none mt-5'>
                        </div>
                    </div>

                    <div className='text-center mb-5 mt-3' data-aos="fade-up">
                        <a href={Registerform} className='text-decoration-none btn btn-primary' download>Download Admission Form <i class="bi bi-download fs-5"></i> </a>

                    </div>

                    <div className='d-flex flex-wrap align-items-center text-justify' data-aos="fade-up">
                        
                        <div data-aos="fade-left" className='col-md-7 col-12 ps-md-4 ps-0 lh-lg order-md-2'>
                            <p>At SKANS , we are dedicated to providing quality education and fostering a nurturing environment for young learners. As a renowned institution in the education sector, we pride ourselves on offering comprehensive and well-rounded educational experiences that empower students to excel academically and develop essential life skills.</p>
                            <p className='fs-2'>Grade: <span className='p-2 fs-3 rounded bg-danger text-white fw-bold'>Play Group to 6</span></p>

                        </div>
                        <div data-aos="fade-right" className='col-md-5 col-12 order-md-1'>
                            <img className='img-fluid' src={Admissionpicdetail}></img>
                        </div>

                    </div>




                    <table className='table table-light table-striped mt-5' data-aos="fade-up">

                        <tr>
                            <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>
                        </tr>

                        <tbody>
                            <tr col="row">
                                <td className='fw-bold fs-5'>Address:</td>
                                <td>House No 53-B Harley Street Campus – Rawalpindi</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Email:</td>
                                <td><a href="mailto:school.info@skans.pk">school.info@skans.pk</a></td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Mobile:</td>
                                <td>(+92)-332-8922225</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone:</td>
                                <td>(+92)-51-5176138</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone 1:</td>
                                <td>(+92)-51-5176066</td>
                            </tr>
                        </tbody>
                    </table>



                    <div className='mt-5'>
                        <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>


                </div>





            </div>

            <div className='bgdarkblue py-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                    © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdmissionDetailSchool
