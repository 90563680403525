import React from 'react'
import '../Components/sidenav.css';
import logo1 from "../Photos/Skanslogo1.png"
import { Link } from 'react-router-dom';


function GlobalSidebar() {

    return (
        <>

            {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                <i className="fas fa-align-left text-white fs-3"></i>
                <span className='ps-3 text-white fs-5'>Menu</span>
            </button> */}
            <a class="toggle-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                <i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i>

            </a>
            <div class="offcanvas offcanvas-start bgdarkblue" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div class="offcanvas-header">
                    {/* <div className="sidebar-header"> */}
                    <div className='col-6'>
                        <img className='img-fluid' src={logo1}></img>
                    </div>
                    {/* <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">Backdrop with scrolling</h5> */}
                    <button type="button" class="border border-0 bgdarkblue fs-4 text-white p-0" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                </div>
                <div id='sidebar' class="offcanvas-body">


                    <ul className="list-unstyled components ">
                        {/* <p>Dummy Heading</p> */}
                        <li className="active">

                            <Link to="/"> Home </Link>


                        </li>
                        {/* <li>
                            <a
                                href="#feeSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className=" d-flex justify-content-between pe-3 align-items-center"
                            >
                                Program/Fee Structure
                                <i class="bi bi-chevron-down"></i>
                            </a>

                            <ul className="collapse list-unstyled" id="feeSubmenu">
                                <li>
                                    <a
                                        href="#feeSubmenu1"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className=" d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        College
                                        <i class="bi bi-chevron-down"></i>
                                    </a>

                                    <ul className="collapse list-unstyled" id="feeSubmenu1">
                                        <li>
                                            <a
                                                href="#feeSubmenu2"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className=" d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Program
                                                <i class="bi bi-chevron-down"></i>
                                            </a>

                                            <ul className="collapse list-unstyled" id="feeSubmenu2">
                                                <li>
                                                    <Link to="/CAProgram">CA Program</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCAProgram">ACCA Program</Link>
                                                </li>


                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="#feeSubmenu3"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className=" d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Fee Structure
                                                <i class="bi bi-chevron-down"></i>
                                            </a>

                                            <ul className="collapse list-unstyled" id="feeSubmenu3">
                                                <li>
                                                    <Link to="/CAFeeStructure">CA Fee Structure</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCAFeeStructure">ACCA Structure</Link>
                                                </li>


                                            </ul>
                                        </li>


                                    </ul>
                                </li>


                            </ul>
                        </li> */}

                        <li>
                            <a
                                href="#pageSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                            >
                                CA / ACCA
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="pageSubmenu">
                                <li>
                                    <Link to="/CAProgram">CA</Link>
                                </li>
                                <li>
                                    <Link to="/ACCAProgram">ACCA</Link>
                                </li>
                                <li>
                                    <a
                                        href="#pageSubmenucampus"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Campuses Location
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled" id="pageSubmenucampus">

                                        <li>
                                            <Link to="/F8Campus">F8 Islamabad Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/RARCampus">Raja Akram Road, Saddar Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/SatelliteTownCampus">Satellite Town Campus </Link>
                                        </li>
                                        <li><Link to="/ACCAPeshawarRoadCampus">ACCA Peshawar Road Campus</Link></li>

                                    </ul>
                                </li>
                                <li>
                                    <a
                                        href="#pageSubmenuF8Faculty"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        F8 Islamabad Faculty
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled" id="pageSubmenuF8Faculty">

                                        <li>
                                            <Link to="/CAF8Faculty">CA Faculty</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCAF8Faculty">ACCA Faculty</Link>
                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    <a
                                        href="#pageSubmenuRARFaculty"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Raja Akram Road, Saddar Faculty
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled" id="pageSubmenuRARFaculty">

                                        <li>
                                            <Link to="/CA_RARFaculty">CA Faculty</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCA_RARFaculty">ACCA Faculty</Link>
                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    <a
                                        href="#pageSubmenuSTFaculty"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Satellite Town Faculty
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled" id="pageSubmenuSTFaculty">

                                        <li>
                                            <Link to="/CA_STFaculty">CA Faculty</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCA_STFaculty">ACCA Faculty</Link>
                                        </li>

                                    </ul>
                                </li>

                                <li>
                                    <a
                                        href="#pageSubmenuPeshawarroadFaculty"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Peshawar Road Faculty
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled" id="pageSubmenuPeshawarroadFaculty">

                                       <li><Link to="/ACCAPeshawarRoadFaculty">ACCA Faculty</Link></li>

                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a
                                href="#pageSubmenuSchool"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                            >
                                School
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="pageSubmenuSchool">

                                <li>
                                    <a
                                        href="#pageSubmenuSchoolCampuses"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        School
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled" id="pageSubmenuSchoolCampuses">

                                        <li>
                                            <Link to="/JuniorHarleyCampus">Harley Street Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/GulzareQuaidCampus">Gulzar e Quaid Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorChakriRoad">Junior Chakri Road Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorWestridgeCampus">Junior Westridge Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/SeniorWestridgeCampus">Senior Westridge Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/GolraMorCampus">Golra Mor Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/PeshawarRoadCampus">Peshawar Road Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/PWDCampus">SKANS PWD Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/AirportCampus">SKANS AECHS Campus</Link>
                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    <Link to="/ParentsCorner">Parent Corner</Link>
                                </li>
                                <li>
                                    <Link to="/Curriculum">Curriculum</Link>
                                </li>
                                <li>
                                    <Link to="/Facilities">Facilities</Link>
                                </li>

                                <li>
                                    <Link to="/ContactUs">Contact Us</Link>
                                </li>

                            </ul>

                        </li>
                        <li>
                            <Link to="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</Link>
                        </li>
                        <li>
                            <a
                                href="#CalenderSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                            >
                                Calender
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="CalenderSubmenu">
                                <li>
                                    <a
                                        href="#IslamabadF8Submenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        F8 Islamabad Campus
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="IslamabadF8Submenu">
                                        <li>
                                            <Link to="/CAIsalamabadCalender">CA Calender</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCAIslamabadCalender">ACCA Calender</Link>
                                        </li>
                                        <li>
                                            <Link to="/EventIslamabad">Event Islamabad</Link>
                                        </li>

                                    </ul>
                                </li>



                                <li>
                                    <a
                                        href="#RawalpindiCalSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Satellite Town Campus
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="RawalpindiCalSubmenu">
                                        <li>
                                            <Link to="/CASatelliteTownCalender">CA Calender</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCASatelliteTownCalender">ACCA Calender</Link>
                                        </li>
                                        <li>
                                            <Link to="/EventSatelliteTown">Satellite Town Event</Link>
                                        </li>

                                    </ul>
                                </li>

                                <li>
                                    <a
                                        href="#RARCalSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Raja Akram Road, Saddar Campus
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="RARCalSubmenu">
                                        <li>
                                            <Link to="/CA_RARCalender">CA Calender</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCA_RARCalender">ACCA Calender</Link>
                                        </li>
                                        <li>
                                            <Link to="/EventRAR">Raja Akram Road, Saddar Event</Link>
                                        </li>

                                    </ul>
                                </li>



                                <li>
                                    <a
                                        href="#PeshawarroadCalSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Peshawar Road Campus
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="PeshawarroadCalSubmenu">
                                    <li><Link to="/ACCAPeshawarRoadCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventPeshawarRoad">Peshawar Road Campus Event </Link></li>

                                    </ul>
                                </li>
                                 
                            </ul>
                        </li>


                        <li>
                            <Link to="/SkansGallery">Life@SKANS</Link>
                        </li>



                        <li>
                            <a
                                href="#NoticeboardSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                            >
                                Noticeboard
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="NoticeboardSubmenu">
                                <li>
                                    <a
                                        href="#CollegeSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Colleges
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="CollegeSubmenu">
                                        <li>
                                            <Link to="/RARNoticeBoard">Raja Akram Road, Saddar Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/SatelliteTownNoticeBoard">Satellite Town Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/F8IslamabadNoticeBoard">F8 Islamabad Noticeboard</Link>
                                        </li>
                                        <li><Link to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Campus</Link></li>


                                    </ul>
                                </li>



                                <li className='bg-primary'>
                                    <a
                                        href="#SchoolSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Schools
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled " id="SchoolSubmenu">
                                        <li>
                                            <Link to="/AECHSNoticeboard">AECHS Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/PWDNoticeboard">PWD Junior Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/HarleyStreetNoticeBoard">Harley Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorChakriRoadNoticeboard">Chakri Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorWestridgeNoticeboard">Westridge Junior Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/SeniorWestridgeNoticeboard">Westridge Senior Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/GolraMorNoticeboard">Golra Mor Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/PeshawarRoadNoticeboard">Peshawar Road Campus Noticeboard</Link>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/Meritstudents">Merit List</Link>
                        </li>

                    </ul>
                </div>
            </div>








            {/* <li>
                            <a
                                href="#pageSubmenucolleges"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                                onClick={() => handleButtonClick(1)}
                            >
                                SKANS Schools of Accountancy - Colleges
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="pageSubmenucolleges">
                                <li>
                                    <a
                                        href="#pageSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        CA / ACCA
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse " id="pageSubmenu">
                                        <li>
                                            <Link to="/CAProgram">CA</Link>
                                        </li>
                                        <li>
                                            <Link to="/ACCAProgram">ACCA</Link>
                                        </li>
                                        <li>
                                            <a
                                                href="#pageSubmenucampus"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Campuses Location
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled" id="pageSubmenucampus">

                                                <li>
                                                    <Link to="/F8Campus">F8 Islamabad Campus</Link>
                                                </li>
                                                <li>
                                                    <Link to="/RARCampus">RAR Campus</Link>
                                                </li>
                                                <li>
                                                    <Link to="/SatelliteTownCampus">Satellite Town Campus </Link>
                                                </li>
                                                <li><Link to="/ACCAPeshawarRoadCampus">ACCA Peshawar Road Campus</Link></li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="#pageSubmenuF8Faculty"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                F8 Islamabad Faculty
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled" id="pageSubmenuF8Faculty">

                                                <li>
                                                    <Link to="/CAF8Faculty">CA Faculty</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCAF8Faculty">ACCA Faculty</Link>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="#pageSubmenuRARFaculty"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                RAR Faculty
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled" id="pageSubmenuRARFaculty">

                                                <li>
                                                    <Link to="/CA_RARFaculty">CA Faculty</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCA_RARFaculty">ACCA Faculty</Link>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="#pageSubmenuSTFaculty"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Satellite Town Faculty
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled" id="pageSubmenuSTFaculty">

                                                <li>
                                                    <Link to="/CA_STFaculty">CA Faculty</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCA_STFaculty">ACCA Faculty</Link>
                                                </li>

                                            </ul>
                                        </li>

                                        <li>
                                            <a
                                                href="#pageSubmenuPeshawarroadFaculty"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Peshawar Road Faculty
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled" id="pageSubmenuPeshawarroadFaculty">

                                                <li><Link to="/ACCAPeshawarRoadFaculty">ACCA Faculty</Link></li>

                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <Link to="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</Link>
                                </li>

                                <li>
                                    <a
                                        href="#CalenderSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Calender
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse " id="CalenderSubmenu">
                                        <li>
                                            <a
                                                href="#IslamabadF8Submenu"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                F8 Islamabad Campus
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled bg-primary" id="IslamabadF8Submenu">
                                                <li>
                                                    <Link to="/CAIsalamabadCalender">CA Calender</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCAIslamabadCalender">ACCA Calender</Link>
                                                </li>
                                                <li>
                                                    <Link to="/EventIslamabad">Event Islamabad</Link>
                                                </li>

                                            </ul>
                                        </li>



                                        <li>
                                            <a
                                                href="#RawalpindiCalSubmenu"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Satellite Town Campus
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled bg-primary" id="RawalpindiCalSubmenu">
                                                <li>
                                                    <Link to="/CASatelliteTownCalender">CA Calender</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCASatelliteTownCalender">ACCA Calender</Link>
                                                </li>
                                                <li>
                                                    <Link to="/EventSatelliteTown">Satellite Town Event</Link>
                                                </li>

                                            </ul>
                                        </li>

                                        <li>
                                            <a
                                                href="#RARCalSubmenu"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                RAR Campus
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled bg-primary" id="RARCalSubmenu">
                                                <li>
                                                    <Link to="/CA_RARCalender">CA Calender</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ACCA_RARCalender">ACCA Calender</Link>
                                                </li>
                                                <li>
                                                    <Link to="/EventRAR">RAR Event</Link>
                                                </li>

                                            </ul>
                                        </li>



                                        <li>
                                            <a
                                                href="#PeshawarroadCalSubmenu"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                className="d-flex justify-content-between pe-3 align-items-center"
                                            >
                                                Peshawar Road Campus
                                                <i class="bi bi-chevron-down"></i>
                                            </a>
                                            <ul className="collapse list-unstyled bg-primary" id="PeshawarroadCalSubmenu">
                                                <li><Link to="/ACCAPeshawarRoadCalender">ACCA Calender</Link></li>
                                                <li><Link to="/EventPeshawarRoad">Peshawar Road Campus Event </Link></li>

                                            </ul>
                                        </li>



                                    </ul>

                                </li>

                                <li>
                                    <a
                                        href="#NoticeboardSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Noticeboard Colleges
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse " id="NoticeboardSubmenu">

                                        <li>
                                            <Link to="/RARNoticeBoard">RAR Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/SatelliteTownNoticeBoard">Satellite Town Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/F8IslamabadNoticeBoard">F8 Islamabad Noticeboard</Link>
                                        </li>
                                        <li><Link to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Campus</Link></li>

                                    </ul>
                                </li>

                            </ul>

                        </li>

                        <li>
                            <a
                                href="#pageSubmenuSchool"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                                onClick={() => handleButtonClick(2)}
                            >
                                SKANS Schools
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="pageSubmenuSchool">

                                <li>
                                    <a
                                        href="#pageSubmenuSchoolCampuses"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        School Campuses
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse " id="pageSubmenuSchoolCampuses">

                                        <li>
                                            <Link to="/JuniorHarleyCampus">Harley Street Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/GulzareQuaidCampus">Gulzar e Quaid Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorChakriRoad">Junior Chakri Road Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorWestridgeCampus">Junior Westridge Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/SeniorWestridgeCampus">Senior Westridge Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/GolraMorCampus">Golra Mor Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/PeshawarRoadCampus">Peshawar Road Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/PWDCampus">SKANS PWD Campus</Link>
                                        </li>
                                        <li>
                                            <Link to="/AirportCampus">SKANS AECHS Campus</Link>
                                        </li>

                                    </ul>
                                </li>

                                <li className=''>
                                    <a
                                        href="#SchoolSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Noticeboard Schools
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse " id="SchoolSubmenu">
                                        <li>
                                            <Link to="/AECHSNoticeboard">AECHS Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/PWDNoticeboard">PWD Junior Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/HarleyStreetNoticeBoard">Harley Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorChakriRoadNoticeboard">Chakri Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/JuniorWestridgeNoticeboard">Westridge Junior Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/SeniorWestridgeNoticeboard">Westridge Senior Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/GolraMorNoticeboard">Golra Mor Campus Noticeboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/PeshawarRoadNoticeboard">Peshawar Road Campus Noticeboard</Link>
                                        </li>

                                    </ul>
                                </li>
                                <li>


                                    <Link to="/ParentsCorner">Parent Corner</Link>
                                </li>
                                <li>
                                    <Link to="/Curriculum">Curriculum</Link>
                                </li>

                                <li>
                                    <Link to="/Facilities">Facilities</Link>
                                </li>

                                <li>
                                    <Link to="/ContactUs">Contact Us</Link>
                                </li>

                            </ul>

                        </li>




                        <li>
                            <Link to="/SkansGallery">Life@SKANS</Link>
                        </li>





                        <li>
                            <Link to="/Meritstudents">Merit List</Link>
                        </li> */}

        </>
    )
}

export default GlobalSidebar
