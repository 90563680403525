import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function CompletedEventF8() {
    const navigate = useNavigate();

    const [event, setEvent] = useState({
        photos: [],
        date: "",
        title: "",
        description: "",


    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEvent({ ...event, [name]: value });
    };
    // const handlePhotoChange = (e) => {
    //     setEvent({ ...event, photos: e.target.files });
    // };

    const handlePhotoChange = (e) => {
        const updatedUser = { ...event };
        updatedUser.photos = e.target.files; // Check that e.target.files contains selected files
        setEvent(updatedUser);
        console.log(updatedUser); // Log updatedUser to verify files are stored
    };
    

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            for (let i = 0; i < event.photos.length; i++) {
                formData.append('photos', event.photos[i]);
              }
            formData.append("date", event.date);
            formData.append("title", event.title);
            formData.append("description", event.description);

      
            const response = await axios.post(`${backendURL}/F8CompletedEvent`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setEvent({
                photos: [],
                date: "",
                title: "",
                description: "",
            }); // Clear the input fields after successful upload
            setTimeout(() => {
                navigate("/AdminCompletedEventF8");
            }, 100);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload DATA", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

const callProfile = useCallback( async () => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
  
          redirect: "follow"
        };
  
        fetch(`${backendURL}/getF8CompletedEvent`, requestOptions)
          .then((response) => response.text())
          .then(async (result) => {
            // console.log("faculty", result);
  
            const data = JSON.parse(result);
            console.log(data);
            setEvent(data);

  
          })
          .catch((error) => console.error(error));
    } catch (err) {
        console.log(err);
        navigate("/AdminLogin");
    }
},[navigate, setEvent]);

    const deleteFaculty = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteF8CompletedEvent/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Event Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);
    const picurl = `${backendURL}/public/uploads/`;
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <h1 className=" text-center fontcolordark mb-4 fw-bold">Completed Event F8</h1>
                <form method="POST" className="pt-3" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">

                        <div className="col-md-5 border-right">
                            <input
                                name="photos"
                                id="photos"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handlePhotoChange}
                                multiple
                                
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="title"
                                type="text"
                                id="title"
                                className="form-control"
                                placeholder="Enter title here"
                                value={event.title}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="date"
                                type="text"
                                id="date"
                                className="form-control"
                                placeholder="Enter date here"
                                value={event.date}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="description"
                                type="text"
                                id="description"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Description here"
                                value={event.description}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        

                        

                      

                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Event
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        {Array.isArray(event) && event.length > 0 ? (
                            <table className="table2">
                                
                                    <tr className="tr2">
                                        <th className="th2 p-sm-2 p-1">#</th>
                                        <th className="th2 p-sm-2 p-1">Title</th>
                                        <th className="th2 p-sm-2 p-1">Date</th>
                                        <th className="th2 p-sm-2 p-1">Action</th>
                                    </tr>
                              
                                    {event.map((ann, id) => (
                                        <tr key={id} className="tr2">
                                            <td className="td2 p-sm-2 p-1 fw-bold">{id + 1}</td>
                                            {/* {ann.photos.map((photo,id)=>(
                                            
                                            <td key={id}>
                                                <img
                                                    className="img-fluid"
                                                    width="100"
                                                    src={picurl + photo}
                                                    alt={ann.name}
                                                />
                                            </td>

                                            ))
                                            
                                            } */}
                                            <td className="td2 p-sm-2 p-1 fw-bold">{ann.title}</td>
                                            <td className="td2 p-sm-2 p-1">{ann.date}</td>
                                            <td className="td2 p-sm-2 p-1">
                                                <Link to={`/UpdateCompletedEventF8/${ann.id}`} className=" me-3 ">
                                                <i className="bi bi-pencil-square fs-5 text-primary"></i>
                                                </Link>
                                                <Link
                                                    onClick={() => deleteFaculty(ann.id)}
                                                    className=""
                                                >
                                                    <i className="bi bi-trash fs-5 text-danger"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                
                            </table>
                        ) : (
                            <p>Not Available</p>
                        )}
                    </div>


                </form>
            </div>
        </>
    );
}

export default CompletedEventF8;
