import React, { useEffect } from 'react'
import Navbar from '../../Components/navbar'
// import bgcounter from "../../Photos/bgcounter.png"

function HowtouseStudentportal() {

  return (
    <>
    <Navbar/>
    <div className='d-flex justify-content-center align-items-center pt-5 bgcounter' >
        <iframe data-aos="fade-up" width="1080" height="520" src="https://www.youtube.com/embed/slvayXmHnQY" title="How to check your results online?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      {/* <iframe width="1080" height="520" src="https://www.youtube.com/embed/tgbNymZ7vqY?playlist=tgbNymZ7vqY&loop=1"> */}
{/* </iframe> */}
    </div>





    </>
  )
}

export default HowtouseStudentportal
