import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateAnnouncment() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Announcment, setAnnouncment] = useState({
        title: "",
        description: "",
        date: ""
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncment({ ...Announcment, [name]: value });
    };

    const callIndAnnouncement = () => {
        try {

            const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/viewHomeAnnouncement/${id}`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          setAnnouncment(data);

        })
        .catch((error) => console.error(error));
            // const res = await fetch("/getHomeAnnouncement", {
            //     method: "GET",
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            //     credentials: "include",
            // });

            // if (res.status === 200) {
            //     const data = await res.json();
            //     console.log(data);
            //     setAnnouncement(data);
            // } else {
            //     const error = new Error(res.statusText);
            //     throw error;
            // }
        } catch (err) {
            console.log(err);
            // navigate("/AdminLogin");
        }

        
    }
    

    useEffect(() => {
        callIndAnnouncement();
    }, []);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/updateHomeAnnouncement/${id}`, Announcment);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });

            setTimeout(() => {
                navigate("/HomeAnnouncement");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Update Announcement", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center pb-3 fontcolordark">Update Announcement</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form method='PATCH' onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="title"
                                    type="text"
                                    id="title"
                                    className="form-control"
                                    placeholder="Enter title here"
                                    value={Announcment.title}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="date"
                                    type="text"
                                    id="date"
                                    className="form-control"
                                    placeholder="Enter date here"
                                    value={Announcment.date}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-8 col-12 border-right mt-3">
                                <textarea
                                    name="description"
                                    type="text"
                                    id="description"
                                    rows="4"
                                    className="form-control"
                                    placeholder="Enter description here"
                                    value={Announcment.description}
                                    onChange={handleInput}
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update Announcement
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateAnnouncment;
