import React from 'react'
import "../CSS/ParentsCorner.css"
import Navbar from '../Components/navbar'
import Family from "../Photos/family.png"
import Gallery from "../Photos/gallery.png"
import Group from "../Photos/group.png"
import Sectiontitle1 from "../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import SkansMission from "../Photos/skansmission.jpg"
import JuniorHarley from "../Photos/juniorHarley.jpg"
import { Link } from 'react-router-dom'

import SeniorWestridge from "../Photos/SeniorWestridgeCampus.png"
import JuniorWestridge from "../Photos/JuniorWestridge.png"
import HarleyCampus from "../Photos/HarleyCampus.png"
import ChakriCampus from "../Photos/ChakriCampus.png"
import GolraCampus from "../Photos/GolraMor.png"
import PWDCampus from "../Photos/PWDcampus.JPG"
import AECHSCampus from "../Photos/AECHSCampus.png"
import skanspeshawarroadCampus from "../Photos/skanspeshawarroad.png"
import { currentYear } from '../constant'
import SchoolNavbar from '../Components/SchoolNavbar'
function ParentsCorner() {
    return (
        <>
            <SchoolNavbar />
            <div className='ParentsBg position-relative' >
                <div className='container-fluid filter1  text-white fw-bold' >
                    <div className='container-fluid lh-lg text-center'>
                        <p className='fw-bold'>
                            Welcome to SKANS SCHOOL SYSTEM OFFICIAL WEBSITE
                        </p>
                        <h2 className='text-white  fw-bold'>Get information about anything that you need<br></br>
                            Just reach your desired branch through contact button below.</h2>
                    </div>

                    {/* <div class="ocean">
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                    </div> */}

                </div>
            </div>
            <div className='bg-lightgrey'>
                <div className='container-fluid text-justify'>
                    <div className='d-flex flex-wrap justify-content-around pt-4'>
                        <div className='col-lg-3 col-12 mb-3' data-aos="fade-up">

                            <div className='cornerbox'>
                                <div className='backrotate '>
                                    <img className='ms-1 mt-2' src={Family} width={"70%"}></img>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-center flex-column mt-4'>
                                <h4 className='fontcolordark fw-bold mt-3'>Parent Corner</h4>
                                <p className='fw-bold opacity-75'>Parent corner is something very informative that we are currently building and you can access your child performance online</p>
                                <Link className='text-decoration-none  fw-bold anchorborder fs-6 px-5' to='http://58.65.172.36/stdlogin/login.aspx'><p className='mb-0'>Click Here For LOGIN</p><span className='anchorborderhover'></span></Link>

                            </div>
                        </div>
                        <div className='col-lg-3 col-12 mx-5 mb-3' data-aos="fade-up">

                            <div className='cornerbox'>
                                <div className='backrotate '>
                                    <img className='ms-3 mt-3' src={Gallery} width={"70%"}></img>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-center flex-column mt-4'>
                                <h4 className='fontcolordark fw-bold mt-3'>Gallery–Our Moments</h4>
                                <p className='fw-bold opacity-75'>Now you can view our students performance at every event and you can see what other activities your child is doing in SKANS.</p>
                                <Link className='text-decoration-none  fw-bold anchorborder fs-6 px-5' to="/SkansGallery"><p className='mb-0'>VISIT GALLERY</p><span className='anchorborderhover'></span></Link>

                            </div>
                        </div>
                        <div className='col-lg-3 col-12 ' data-aos="fade-up">

                            <div className='cornerbox'>
                                <div className='backrotate '>
                                    <img className='ms-2 mt-3' src={Group} width={"70%"}></img>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-center flex-column mt-4'>
                                <h4 className='fontcolordark fw-bold mt-3'>Forum – Ask Anything</h4>
                                <p className='fw-bold opacity-75'>If you have any question feel free to ask us question. Relevant campus will reply you in reply of your question.</p><br></br>
                                <Link className='text-decoration-none  fw-bold anchorborder fs-6 px-5' to='/ContactUs'><p className='mb-0'>Contant Us</p><span className='anchorborderhover'></span></Link>
                            </div>
                        </div>


                    </div>

                </div>


                {/* </div> */}
                <div className='container mt-5 text-center'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h4 className='text-light fw-bold mb-0'>OUR MISSION, VISION & CORE VALUES</h4>&nbsp;&nbsp;
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>

                    <p data-aos="flip-right" className='fs-5'>We help businesses of all sizes to increase visibility, drive more traffic and improve commercial success</p>
                </div>


                <div className='container' >

                    <div class="tabs" data-aos="fade-up">
                        <div class="tab-2">
                            <label for="tab2-1">OUR CORE VALUES</label>
                            <input id="tab2-1" name="tabs-two" type="radio" checked="checked" />
                            <div className='text-center pt-0'>
                                <img className='img-fluid' src={SkansMission} width={"100%"} height={"80%"}></img>

                            </div>
                        </div>
                        <div class="tab-2">
                            <label for="tab2-2">OUR MISSION & VISION</label>
                            <input id="tab2-2" name="tabs-two" type="radio" />
                            <div className='d-flex justify-content-between pt-1'>
                                <div className='col-6 me-3 '>
                                    <div className=' d-flex mb-3 align-items-center justify-content-center border p-3 bgdarkblue rounded shadow-lg border-0'>
                                        <img height={"10%"} src={Sectiontitle1}></img>&nbsp;
                                        <h4 className='text-light fw-bold mb-0 d-md-block d-none text-center'>OUR MISSION</h4>&nbsp;
                                        <h6 className='text-light fw-bold mb-0 d-md-none d-block text-center'>OUR MISSION</h6>&nbsp;

                                        <img height={"10%"} src={Sectiontitle2}></img>
                                    </div>
                                    <p className='lh-lg d-md-block d-none text-justify'>SKANS schools are committed to the intellectual and personal development of our students, inspiring them and empowering them to become positive and active global citizens.</p>
                                    <p className=' d-md-none d-block text-justify'>SKANS schools are committed to the intellectual and personal development of our students, inspiring them and empowering them to become positive and active global citizens.</p>

                                    <ul>
                                        <li>
                                            work in partnership with our community

                                        </li>
                                        <li>
                                            welcome innovation
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-6'>
                                    <div className=' d-flex mb-3 align-items-center justify-content-center border p-3 bgdarkblue rounded shadow-lg border-0'>
                                        <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                        <h4 className='text-light fw-bold mb-0 d-md-block d-none text-center'>VISION</h4>&nbsp;
                                        <h6 className='text-light fw-bold mb-0 d-md-none d-block text-center'>VISION</h6>&nbsp;

                                        <img height={"10%"} src={Sectiontitle2}></img>
                                    </div>

                                    <p className='lh-lg d-md-block d-none text-justify'>To enhance our students’ full potential, a passion for learning, develop their confidence and creativity in a safe and caring environment.</p>
                                    <p className=' d-md-none d-block text-justify'>To enhance our students’ full potential, a passion for learning, develop their confidence and creativity in a safe and caring environment.</p>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='container'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h4 className='text-light fw-bold mb-0'>OUR BRANCHES</h4>&nbsp;&nbsp;
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>

                    <p data-aos="flip-left" className='text-center fs-5 mb-5'>We build your child’s future, view our branches near you so we will be in your reach.</p>

                    <div class="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={HarleyCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Harley Street Campus</h5>
                                    <p class="card-text">House No 53-B Harley Street Campus – Rawalpindi</p>
                                    <p className=' fw-bold'>(+92)-332-8922225<br></br>
                                        (+92)-51-5176138</p>
                                </div>
                            </div>
                        </div>
                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={GolraCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Golra Mor Campus</h5>
                                    <p class="card-text">Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad
                                    </p>
                                    <p className=' fw-bold'>
                                        (051) 2227475
                                        <br></br><br></br>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={ChakriCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior  Chakri Road Campus</h5>
                                    <p class="card-text pb-0">Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>0310-3331131<br></br>
                                        051- 5575082
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={JuniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior Westridge Campus</h5>
                                    <p class="card-text pb-0">H.No. 9-B, Hali Road, Westridge-1, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>051-8773091<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>



                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={SeniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Senior Westridge Campus</h5>
                                    <p class="card-text pb-0">91 Hali Road, Street no. 8, Westridge-1, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8355546<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={skanspeshawarroadCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Peshawar Road Campus</h5>
                                    <p class="card-text pb-0">House No. 308-A, Main Peshawar Road, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8890534<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={PWDCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">PWD Campus</h5>
                                    <p class="card-text pb-0">233, Block A Sector A PWD, Islamabad, Punjab
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8773775<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={AECHSCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">AECHS Campus</h5>
                                    <p class="card-text">37 Street 17, Sector 1 Airport Employees CHS, Rawalpindi
                                    </p>
                                    <p className=' fw-bold'>
                                        (051) 8468475
                                        <br></br><br></br>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='bgdarkblue py-4 mt-4'>
                    <div className='d-flex justify-content-around text-white'>
                        <div>
                            © Copyright {currentYear} - All rights reserved
                        </div>
                        <div>
                            <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                        </div>

                    </div>


                </div>
            </div>

        </>
    )
}

export default ParentsCorner
