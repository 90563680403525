import React, { useEffect, useRef } from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/Juniorharley.css"
import Junior from "../../Photos/juniorHarley.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"


import SeniorWestridge from "../../Photos/SeniorWestridgeCampus.png"
import JuniorWestridge from "../../Photos/JuniorWestridge.png"
import HarleyCampus from "../../Photos/HarleyCampus.png"
import ChakriCampus from "../../Photos/ChakriCampus.png"
import GolraCampus from "../../Photos/GolraMor.png"
import PWDCampus from "../../Photos/PWDcampus.JPG"
import AECHSCampus from "../../Photos/AECHSCampus.png"
import skanspeshawarroadCampus from "../../Photos/skanspeshawarroad.png"
import { currentYear } from '../../constant'
import SchoolNavbar from '../../Components/SchoolNavbar'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

function JuniorHarley() {

    return (
        <>

            <SchoolNavbar />
            <div className='JuniorHarleybg'>
                <div className='  container-fluid  ps-md-5 px-0 text-light filter5 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Harley Street Campus<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h1>
                            <h3 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Harley Street Campus<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h3>

                            <div className='fw-bold fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='bg-lightgrey'>
                <div className='container-fluid'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 text-center d-md-block d-none'>HARLEY STREET BRANCH</h3>&nbsp;&nbsp;
                            <h5 className='text-light fw-bold mb-0 text-center d-md-none d-block'>HARLEY STREET BRANCH</h5>&nbsp;&nbsp;

                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>

                    <div className='d-flex flex-wrap justify-content-evenly'>
                        <div className='col-lg-3 col-6 ms-lg-5 ms-0'>
                        <img className='img-fluid ' src={require("../../Photos/HarleyCampusPrincipal.jpeg")} style={{ objectFit: 'cover', width: '80%', height: '100%' }}/>

                            {/* <img className='img-fluid align-self-stretch' src={require("../../Photos/HarleyCampusPrincipal.jpeg")} style={{ objectFit: 'cover', width: '100%', height: '100%' }}></img> */}
                        </div>
                        <div className='col-lg-8 col-12  pt-3'>

                            <blockquote class="otro-blockquote">
                            <span>Welcome to SKANS School System Harley Street Campus, Rawalpindi</span> where we are dedicated to nurturing excellence in every student. Our commitment is to provide a safe, inclusive, and innovative learning environment that fosters academic achievement, personal growth, and lifelong success. Guided by Islamic principles and moral values, we strive to cultivate integrity, compassion, and respect in our students. Together, we strive to inspire and empower our students to reach their fullest potential.
                                <span>Principal:<br></br>Ms. Raveen Asif</span>
                            </blockquote>
                        </div>
                    </div>

                    {/* <p className='container py-5 text-justify lh-lg'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p> */}
                   
                    <div className="swiper-container my-5">
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={4} // Display 4 slides at once
                            spaceBetween={30}
                          


                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                                
                            }}
                            pagination={true}
                            autoplay={{ delay: 2000 }}
                            modules={[EffectCoverflow, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery1.jpg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery2.jpg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery3.jpg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery4.jpg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery5.jpg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery6.jpg")} />
                            </SwiperSlide>
                            <SwiperSlide className='d-flex align-items-center'>
                                <img src={require("../../Photos/harleygallery7.jpg")} />
                            </SwiperSlide>
                            
                        </Swiper>
                    </div>
                    <div>

                        <table className='table table-light table-striped container mt-3' data-aos="fade-up">

                            <tr>
                                <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>
                            </tr>

                            <tbody>
                                <tr col="row">
                                    <td className='fw-bold fs-5'>Address:</td>
                                    <td>House No 53-B Harley Street Campus – Rawalpindi</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold fs-5'>Email:</td>
                                    <td><a href="mailto:school.info@skans.pk">school.info@skans.pk</a></td>
                                </tr>
                                <tr>
                                    <td className='fw-bold fs-5'>Mobile:</td>
                                    <td>(+92)-332-8922225</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold fs-5'>Telephone:</td>
                                    <td>(+92)-51-5176138</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold fs-5'>Telephone 1:</td>
                                    <td>(+92)-51-5176066</td>
                                </tr>
                            </tbody>
                        </table>



                        <div className='mt-5 container'>
                            <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>





                    <div className='container'>
                        <div className='d-flex align-items-center ' data-aos="fade-up">
                            <div className='b'>

                            </div>
                            <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                                <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                <h3 className='text-light fw-bold mb-0 d-flex align-items-center text-center'> <span class=" fw-bold fs-1 pe-md-3 p-0"> + </span> OTHER CAMPUSES</h3>&nbsp;&nbsp;
                                <img height={"10%"} src={Sectiontitle2}></img>
                            </div>
                            <div className='b'>
                            </div>
                        </div>



                        <p data-aos="flip-right" className='text-center opacity-75 fw-bold fs-5'>We build your child’s future, view our branches near you so we will be in your reach.</p>

                        <div class="row row-cols-1 row-cols-md-3 g-4">
                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={HarleyCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Harley Street Campus</h5>
                                        <p class="card-text">House No 53-B Harley Street Campus – Rawalpindi</p>
                                        <p className=' fw-bold'>(+92)-332-8922225<br></br>
                                            (+92)-51-5176138</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={GolraCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Golra Mor Campus</h5>
                                        <p class="card-text">Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad
                                        </p>
                                        <p className=' fw-bold'>
                                            (051) 2227475
                                            <br></br><br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={ChakriCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Junior  Chakri Road Campus</h5>
                                        <p class="card-text pb-0">Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi
                                        </p>
                                        <p className=' fw-bold pb-0'>0310-3331131<br></br>
                                            051- 5575082
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={JuniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Junior Westridge Campus</h5>
                                        <p class="card-text pb-0">H.No. 9-B, Hali Road, Westridge-1, Rawalpindi
                                        </p>
                                        <p className=' fw-bold pb-0'>051-8773091<br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>



                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={SeniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Senior Westridge Campus</h5>
                                        <p class="card-text pb-0">91 Hali Road, Street no. 8, Westridge-1, Rawalpindi
                                        </p>
                                        <p className=' fw-bold pb-0'>(051) 8355546<br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={skanspeshawarroadCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Peshawar Road Campus</h5>
                                        <p class="card-text pb-0">House No. 308-A, Main Peshawar Road, Rawalpindi
                                        </p>
                                        <p className=' fw-bold pb-0'>(051) 8890534<br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={PWDCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">PWD Campus</h5>
                                        <p class="card-text pb-0">233, Block A Sector A PWD, Islamabad, Punjab
                                        </p>
                                        <p className=' fw-bold pb-0'>(051) 8773775<br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="col" data-aos="fade-up">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={AECHSCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">AECHS Campus</h5>
                                        <p class="card-text">37 Street 17, Sector 1 Airport Employees CHS, Rawalpindi
                                        </p>
                                        <p className=' fw-bold'>
                                            (051) 8468475
                                            <br></br><br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>




                <div className='bgdarkblue py-4 mt-4'>
                    <div className='d-flex justify-content-around text-white'>
                        <div>
                            © Copyright {currentYear} - All rights reserved
                        </div>
                        <div>
                            <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                        </div>

                    </div>


                </div>
            </div>

        </>
    )
}

export default JuniorHarley
