import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { backendURL } from "../../../../constant";

function CollegeFeeStructure() {
    const navigate = useNavigate;

    const [announcement, setAnnouncement] = useState({
        level: "",
        name: "",
        fees: "",
    });

    const [detail, setDetail] = useState([]);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncement({ ...announcement, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendURL}/AdminFeeStructure`, announcement);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAnnouncement({
                level: "",
                name: "",
                fees: "",
            });

            // Clear the input fields after successful upload
            callProfile();
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };
    
    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getFeeStructure`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                console.log("highlight", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);
      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            // navigate("/AdminLogin");
        }
    };

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/deleteFeeStructure/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Batch Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-center text-uppercase mb-5 fontcolordark">CA Fee Structure</h1>
                    </div>
                </div>
                <form method="POST" className="" onSubmit={handleDataUpload}>
                    <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="level"
                                type="text"
                                id="level"
                                className="form-control"
                                placeholder="Enter Level text here"
                                value={announcement.level}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="name"
                                type="text"
                                id="name"
                                className="form-control"
                                placeholder="Enter name here"
                                value={announcement.name}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="fees"
                                type="number"
                                id="fees"
                                className="form-control"
                                placeholder="Enter fees here"
                                value={announcement.fees}
                                onChange={handleInput}
                                required
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Fee Structure
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container-md container-fluid ps-0">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        <div className="mt-5">
                            <table className="table2 mb-5">
                                <tr className="tr2">
                                    <th className="th2 p-sm-2 p-1">Level</th>
                                    <th className="th2 p-sm-2 p-1">Name</th>
                                    <th className="th2 p-sm-2 p-1">FEE</th>
                                    <th className="th2 p-sm-2 p-1">Action</th>
                                </tr>
                                {detail.length > 0 ? (
                                    detail.map((ann, id) => (
                                        <tr className="tr2" key={id}>
                                            <td className="td2 p-sm-2 p-1 fw-bold">{ann.level}</td>
                                            <td className="td2 p-sm-2 p-1">{ann.name}</td>
                                            <td className="td2 p-lg-2 p-1">{ann.fees}</td>
                                            <td className="th2 d-flex justify-content-start">
                                                <Link to={`/UpdateFeeStructure/${ann.id}`}>
                                                    <i className="bi bi-pencil-square fs-5 text-primary"></i>
                                                </Link>
                                                <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann.id)}>
                                                    <i className="bi bi-trash fs-5 text-danger"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <p>Not Available</p>
                                )}
                            </table>
                        </div>
                        <div></div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default CollegeFeeStructure;
