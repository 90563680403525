import React, { useEffect, useState } from 'react';
import Navbar from "../Components/navbar";
import '../CSS/Gallery.css'; // Corrected the import path
import ReactPaginate from 'react-paginate';
import { backendURL } from '../constant';
// import photo from "../Photos/Gallery1.JPG"
// import photo1 from "../Photos/Gallery1.JPG"

// import photo2 from "../Photos/Gallery1.JPG"


const MasonryGallery = () => {
    const [user, setUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const universitiesPerPage = 30;

    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    
      redirect: "follow"
    };
    
    fetch(`${backendURL}/getgalleryphoto`, requestOptions)
      .then((response) => response.text())
      .then(async(result) => {
        console.log("getgalleryphoto",result);
       
          const data =JSON.parse(result);
          console.log(data);
          setUser(data);
      
      })
      .catch((error) => console.error(error));
            // const res = await fetch("/getgalleryphoto", {
            //     method: "GET",
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //     },
            //     credentials: "include",
            // });

            // if (res.status === 200) {
            //     const data = await res.json();
            //     console.log(data);
            //     setUser(data);
            // } else {
            //     const error = new Error(res.statusText);
            //     throw error;
            // }
        } catch (err) {
            console.log(err);
        }
    };


    const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
    const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
    const currentphotos = user.slice(indexOfFirstUniversity, indexOfLastUniversity);
    
    // useEffect(()=>{
    //     callProfile();
    // },[])

    const gall=()=>{
        const gallery = document.getElementById('gallery');

        const getVal = (elem, style) => parseInt(window.getComputedStyle(elem).getPropertyValue(style));
        const getHeight = (item) => item.querySelector('.content').getBoundingClientRect().height;
        const resizeAll = () => {
            const altura = getVal(gallery, 'grid-auto-rows');
            const gap = getVal(gallery, 'grid-row-gap');
            gallery.querySelectorAll('.gallery-item').forEach((item) => {
                const el = item;
                el.style.gridRowEnd = `span ${Math.ceil((getHeight(item) + gap) / (altura + gap))}`;
            });
        };

        const handleImageLoad = (item) => {
            const altura = getVal(gallery, 'grid-auto-rows');
            const gap = getVal(gallery, 'grid-row-gap');
            const gitem = item.parentElement.parentElement;
            gitem.style.gridRowEnd = `span ${Math.ceil((getHeight(gitem) + gap) / (altura + gap))}`;
            item.classList.remove('byebye');
        };

        const handleItemClick = (item) => {
            item.classList.toggle('full');
        };

        const images = gallery.querySelectorAll('img');
        images.forEach((item) => {
            item.classList.add('byebye');
            if (item.complete) {
                console.log(item.src);
                handleImageLoad(item);
            } else {
                item.addEventListener('load', () => handleImageLoad(item));
            }
        });

        window.addEventListener('resize', resizeAll);

        gallery.querySelectorAll('.gallery-item').forEach((item) => {
            item.addEventListener('click', () => handleItemClick(item));
        });

        return () => {
            window.removeEventListener('resize', resizeAll);
            images.forEach((item) => {
                item.removeEventListener('load', () => handleImageLoad(item));
            });
            gallery.querySelectorAll('.gallery-item').forEach((item) => {
                item.removeEventListener('click', () => handleItemClick(item));
            });
        };
    }

    useEffect(() => {
        gall();

    }, [gall]);

    const picurl = `${backendURL}/public/gallery/`;
    useEffect(()=>{
        callProfile();
    },[callProfile])

    return (
        <>
            <Navbar />
            <div className='Gallerybg'>
                <div className='container-fluid ps-md-5 px-0 text-light filter14 text-center d-flex justify-content-center'>
                    <div className="glasseffect ">
                        <h1 className='fw-bold'>SKANS GALLERY</h1>
                    </div>
                </div>
            </div>
            <div className="mx-5 mt-5">
                <div className="gallery mb-5" id="gallery">
                      {currentphotos.length>0 ? currentphotos.map((photo, index) => (
                        <div className="gallery-item" key={index}>
                            <div className="content">
                                {/* <p hidden>{index}</p> */}
                                <img  className='img-fluid' src={picurl + photo.photos} alt="gallery" />
                                 <div className="content2">
                                    <p>{photo.caption}</p>
                                </div>
                            </div>
                        </div>
                    )):(<p >Loading...</p>)}
                   



                </div>
                <ReactPaginate
                pageCount={Math.ceil(user.length / universitiesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
            />
            </div>
        </>
    );
};

export default MasonryGallery;
