import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateHighAchivers() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [achiever, setAchiever] = useState({
        photos: "",
        name: "",
        description: ""

    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAchiever({ ...achiever, [name]: value });
    };
    const handlePhotoChange = (e) => {
        setAchiever({ ...achiever, photos: e.target.files[0] });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("photos", achiever.photos);
            formData.append("name", achiever.name);
            formData.append("description", achiever.description);


            const response = await axios.patch(`${backendURL}/updateHighAchievers/${id.trim()}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAchiever({
                photos: "",
                name: "",
                description: "",

            }); // Clear the input fields after successful upload
            setTimeout(() => {
                navigate("/AdminHighAchievers");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Member", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewHighAchievers/${id.trim()}`);
            const announcementData = response.data;
            setAchiever(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    // const handleDataUpload = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.patch(`/updateHomeAnnouncement/${id.trim()}`, Announcment);
    //         console.log(response);
    //         toast.success("Data Uploaded Successfully", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });

    //         setTimeout(() => {
    //             navigate("/HomeAnnouncement");
    //         }, 1000);
    //     } catch (error) {
    //         console.error(error);
    //         toast.error("Failed to Update Announcement", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });
    //     }
    // };
    const picurl = `${backendURL}/public/uploads/`;

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark mb-4">Update High Achiever</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-md-5 ms-0 p-3 col-md-11 shadow-lg mb-4">
                    <form method="POST" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around ">

                            <div className="col-md-4 border-right">
                                <input
                                    name="photos"
                                    id="photos"
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    className="form-control"
                                    onChange={handlePhotoChange}
                                    
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="name"
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    placeholder="Enter name here"
                                    value={achiever.name}
                                    onChange={handleInput}
                                    
                                />
                            </div>

                            <div className="col-md-8 col-12 border-right mt-3">
                                <textarea
                                    name="description"
                                    type="text"
                                    id="description"
                                    rows="2"
                                    className="form-control"
                                    placeholder="Enter description here"
                                    value={achiever.description}
                                    onChange={handleInput}
                                    
                                ></textarea>
                            </div>

                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Upload Member
                            </button>
                        </div>
                    </form>


                    <img
                        className="img-fluid text-center"
                        width="100"
                        src={picurl + achiever.photos}
                        alt={achiever.name}
                    />
                </div>
            </div>
        </>
    )
}

export default UpdateHighAchivers;
