import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/GulzareQuaid.css"
import Junior from "../../Photos/juniorHarley.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"


import SeniorWestridge from "../../Photos/SeniorWestridgeCampus.png"
import JuniorWestridge from "../../Photos/JuniorWestridge.png"
import HarleyCampus from "../../Photos/HarleyCampus.png"
import ChakriCampus from "../../Photos/ChakriCampus.png"
import GolraCampus from "../../Photos/GolraMor.png"
import PWDCampus from "../../Photos/PWDcampus.JPG"
import AECHSCampus from "../../Photos/AECHSCampus.png"
import skanspeshawarroadCampus from "../../Photos/skanspeshawarroad.png"
import { currentYear } from '../../constant'
import SchoolNavbar from '../../Components/SchoolNavbar'


function PeshawarRoadCampusschool() {
    return (
        <>

            <SchoolNavbar />
            <div className='skanspeshawarroadbg'>
                <div className='  container-fluid  ps-md-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>PESHAWAR ROAD CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h1>
                            <h3 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>PESHAWAR ROAD CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h3>
                            
                            <div className='fw-bold fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='bg-lightgrey'>
            <div className='container'>
                <div className='d-flex align-items-center ' data-aos="fade-up">
                    <div className='b'>

                    </div>
                    <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                        <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                        <h3 className='text-light fw-bold mb-0 d-md-block d-none'>PESHAWAR ROAD CAMPUS</h3>&nbsp;&nbsp;
                        <h5 className='text-light fw-bold mb-0 d-md-none d-block text-center'>PESHAWAR ROAD CAMPUS</h5>&nbsp;&nbsp;
                        
                        <img height={"10%"} src={Sectiontitle2}></img>
                    </div>
                    <div className='b'>
                    </div>
                </div>



                <div>
                    {/* <p><span className='fw-bold'>Address:</span> House No 53-B Harley Street Campus – Rawalpindi</p>
                    <p><span className='fw-bold'>Email: </span>            <a className='text-decoration-none' href=""> school.info@skans.pk</a></p>
                    <p><span className='fw-bold'>Mobile:  </span>          (+92)-332-8922225</p>
                    <p><span className='fw-bold'>Telephone: </span>    (+92)-51-5176138</p>
                    <p><span className='fw-bold'>Telephone 1: </span>  (+92)-51-5176066</p> */}




                    <table className='table table-light table-striped' data-aos="fade-up">
                        
                            <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>
                        
                        <tbody>
                            <tr>
                                <td className='fw-bold fs-5'>Address:</td>
                                <td>House No. 308-A, Main Peshawar Road, Rawalpindi</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Email:</td>
                                <td><a href="mailto:school.info@skans.pk">school.info@skans.pk</a></td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Mobile:</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone:</td>
                                <td>(051) 8890534</td>
                            </tr>
                            {/* <tr>
                            <td className='fw-bold fs-5'>Telephone 1:</td>
                            <td>(+92)-51-5176066</td>
                        </tr> */}
                        </tbody>
                    </table>



                    <div className='mt-5'>
                        {/* <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>





                <div>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 d-flex align-items-center text-center'> <span class=" fw-bold fs-1 pe-3"> + </span> OTHER CAMPUSES</h3>&nbsp;&nbsp;
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>



                    <p data-aos="flip-left" className='text-center opacity-75 fw-bold fs-5'>We build your child’s future, view our branches near you so we will be in your reach.</p>

                    <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={HarleyCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Harley Street Campus</h5>
                                    <p class="card-text">House No 53-B Harley Street Campus – Rawalpindi</p>
                                    <p className=' fw-bold'>(+92)-332-8922225<br></br>
                                        (+92)-51-5176138</p>
                                </div>
                            </div>
                        </div>
                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={GolraCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Golra Mor Campus</h5>
                                    <p class="card-text">Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad
                                    </p>
                                    <p className=' fw-bold'>
                                        (051) 2227475
                                        <br></br><br></br>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={ChakriCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior  Chakri Road Campus</h5>
                                    <p class="card-text pb-0">Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>0310-3331131<br></br>
                                        051- 5575082
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={JuniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior Westridge Campus</h5>
                                    <p class="card-text pb-0">H.No. 9-B, Hali Road, Westridge-1, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>051-8773091<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>



                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={SeniorWestridge} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Senior Westridge Campus</h5>
                                    <p class="card-text pb-0">91 Hali Road, Street no. 8, Westridge-1, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8355546<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={skanspeshawarroadCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Peshawar Road Campus</h5>
                                    <p class="card-text pb-0">House No. 308-A, Main Peshawar Road, Rawalpindi
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8890534<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={PWDCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">PWD Campus</h5>
                                    <p class="card-text pb-0">233, Block A Sector A PWD, Islamabad, Punjab
                                    </p>
                                    <p className=' fw-bold pb-0'>(051) 8773775<br></br>

                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col" data-aos="fade-up">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={AECHSCampus} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">AECHS Campus</h5>
                                    <p class="card-text">37 Street 17, Sector 1 Airport Employees CHS, Rawalpindi
                                    </p>
                                    <p className=' fw-bold'>
                                        (051) 8468475
                                        <br></br><br></br>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>




            <div className='bgdarkblue py-4 mt-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
            </div>

        </>
    )
}

export default PeshawarRoadCampusschool
