import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/GulzareQuaid.css"
import Junior from "../../Photos/juniorHarley.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"
import skanspeshawarroadCampus from "../../Photos/skanspeshawarroad.png"
import { currentYear } from '../../constant'
import SchoolNavbar from '../../Components/SchoolNavbar'

function GulzareQuaid() {
    return (
        <>

            <SchoolNavbar />
            <div className='GulzareQuiadbg'>
                <div className='  container-fluid  ps-md-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>GULZAR-E-QUAID CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h1>
                            <h3 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>GULZAR-E-QUAID CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h3>
                            
                            <div className='fw-bold fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='bg-lightgrey'>
            <div className='container'>
                <div className='d-flex align-items-center '>
                    <div className='b'>

                    </div>
                    <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                        <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                        <h3 className='text-light fw-bold mb-0 d-md-block d-none'>GULZAR E QUIAD CAMPUS</h3>&nbsp;&nbsp;
                        <h5 className='text-light fw-bold mb-0 text-center d-md-none d-block'>GULZAR E QUIAD CAMPUS</h5>&nbsp;&nbsp;

                        <img height={"10%"} src={Sectiontitle2}></img>
                    </div>
                    <div className='b'>
                    </div>
                </div>



                <div>
                    {/* <p><span className='fw-bold'>Address:</span> House No 53-B Harley Street Campus – Rawalpindi</p>
                    <p><span className='fw-bold'>Email: </span>            <a className='text-decoration-none' href=""> school.info@skans.pk</a></p>
                    <p><span className='fw-bold'>Mobile:  </span>          (+92)-332-8922225</p>
                    <p><span className='fw-bold'>Telephone: </span>    (+92)-51-5176138</p>
                    <p><span className='fw-bold'>Telephone 1: </span>  (+92)-51-5176066</p> */}




                    <table className='table table-light table-striped'>
                        
                            <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>
                        
                        <tbody>
                            <tr>
                                <td className='fw-bold fs-5'>Address:</td>
                                <td> 119 /2 street 4 Sector 2 Airport society Rawalpindi</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Email:</td>
                                <td><a href="mailto:school.info@skans.pk">school.info@skans.pk</a></td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Mobile:</td>
                                <td>(+92)333-5666810</td>
                            </tr>
                            <tr>
                                <td className='fw-bold fs-5'>Telephone:</td>
                                <td>(+92)-51-5497155</td>
                            </tr>
                            {/* <tr>
                            <td className='fw-bold fs-5'>Telephone 1:</td>
                            <td>(+92)-51-5176066</td>
                        </tr> */}
                        </tbody>
                    </table>



                    <div className='mt-5'>
                        {/* <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>
                </div>





                <div>
                    <div className='d-flex align-items-center '>
                        <div className='b'>

                        </div>
                        <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 d-flex align-items-center text-center'> <span class=" fw-bold fs-1 pe-3"> + </span> OTHER CAMPUSES</h3>&nbsp;&nbsp;
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>



                    <p className='text-center opacity-75 fw-bold fs-5'>We build your child’s future, view our branches near you so we will be in your reach.</p>

                    <div class="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={Junior} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior Campus Harley</h5>
                                    <p class="card-text">House No 53-B Harley Street Campus – Rawalpindi</p>
                                    <p className=' fw-bold'>(+92)-332-8922225<br></br>
                                        (+92)-51-5176138<br></br>
                                        (+92)-51-5176066</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={Junior} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Senior Campus Harley</h5>
                                    <p class="card-text">House 76/3, Lane 5,<br></br>
                                        Harley Street Campus<br></br>
                                        Rawalpindi
                                    </p>
                                    <p className=' fw-bold'>
                                        (+92)-51-5176257
                                        <br></br>
                                        (+92)-51-5176258<br></br>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col">
                        <div class="card border-0 shadow-lg rounded-5">
                            <img src={Junior} class="card-img-top rounded-top-5" alt="..." />
                            <div class="card-body">
                                <h5 class="fontcolordark fw-bold">Gulzar-e-Quaid Campus</h5>
                                <p class="card-text">
                                    House # 119/2, Street # 4,<br></br>
                                    Sector-2, Airport Housing Society,<br></br>
                                    Gulzar-e-Quaid,<br></br>
                                    Rawalpindi
                                </p>
                                <p  className=' fw-bold'>
                                    (+92)-51-5497155<br></br>
                                </p>
                            </div>
                        </div>
                    </div> */}
                        <div class="col">
                            <div class="card border-0 shadow-lg rounded-5">
                                <img src={Junior} class="card-img-top rounded-top-5" alt="..." />
                                <div class="card-body">
                                    <h5 class="fontcolordark fw-bold">Junior Campus Chakri Road</h5>
                                    <p class="card-text">Raja Bashir Plaza, Moza Hayal Ranail,<br></br>
                                        Opp. Pir Mehar Ali Shah Town, Chakri<br></br>
                                        Road Rawalpindi.
                                    </p>
                                    <p className=' fw-bold'>051- 5575082<br></br>
                                        0310-3331131
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>




            <div className='bgdarkblue py-4 mt-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        Powered By : <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
            </div>

        </>
    )
}

export default GulzareQuaid
