import React, { useEffect, useState } from 'react';
import '../CSS/MeritStudents.css';
import Navbar from '../Components/navbar';
import Sectiontitle1 from '../Photos/section-title-shape-1.png';
import Sectiontitle2 from '../Photos/section-title-shape-2.png';
import MeritList from '../Photos/MeritListVector.jpg';
import ReactPaginate from 'react-paginate';
import { backendURL } from '../constant';
import CollegeNavbar from '../Components/CollegeNavbar';
import HighachieverNavbar from '../Components/HighachieverNavbar';

function MeritStudents() {
  const [details, setDetails] = useState([]);
  const picUrl = `${backendURL}/public/uploads/`;
  const [currentPage, setCurrentPage] = useState(0);
  const universitiesPerPage = 12;

  const callProfile = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      
      
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      
        redirect: "follow"
      };
      
      fetch(`${backendURL}/getHighAchievers`, requestOptions)
        .then((response) => response.text())
        .then(async(result) => {
          console.log("getHighAchievers",result);
         
            const data =JSON.parse(result);
            console.log(data);
            setDetails(data);
        
        })
        .catch((error) => console.error(error));
      // const res = await fetch('/getHighAchievers', {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   credentials: 'include',
      // });

      // if (res.status === 200) {
      //   const data = await res.json();
      //   setDetails(data);
      // } else {
      //   const error = new Error(res.statusText);
      //   throw error;
      // }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    callProfile();
  }, []);


  const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
  const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
  const currentDetail = details.slice(indexOfFirstUniversity, indexOfLastUniversity);

  return (
    <>
      {/* <CollegeNavbar /> */}
      <HighachieverNavbar/>
      <div className="Meritlistbg mb-5">
        <div className="container-fluid ps-5 text-light filter12 text-center">
          <p>Home / Merit List</p>
          <br />
          <h1 className='fw-bold d-sm-block d-none'>MERIT LIST</h1>
        <h1 className='fw-bold fs-1 d-sm-none d-block'>MERIT LIST</h1>
        </div>
      </div>

      <div className="container">
        <div className="bgdarkblue text-white fw-bold p-3 mt-5 mb-lg-0 mb-5 text-center rounded" data-aos="flip-right">
          <h3 className="fw-bold  d-sm-block d-none">Meet the Brightest Stars of SKANS School of Accountancy!</h3>
          <h5 className="fw-bold  d-sm-none d-block">Meet the Brightest Stars of SKANS School of Accountancy!</h5>

        </div>
        <div className="d-flex flex-wrap align-items-center" data-aos="fade-up">
          <div className="col-lg-8 col-12 text-justify lh-lg" data-aos="fade-right">
            Welcome to <span className="fontcolordark fw-bold" style={{ fontFamily: '-moz-initial' }}>SKANS</span> celebrating the exceptional achievements in the field of accountancy - the Merit List of Top Position Students. These individuals are not just students; they are our pride, our stars, who have illuminated the realm of accountancy with their unparalleled dedication and brilliance. Securing the top positions, they have showcased their unwavering commitment to academic excellence and a profound understanding of the intricate world of numbers. Their remarkable accomplishments stand as a testament to their hard work, perseverance, and intellectual prowess. Join us in acknowledging these outstanding individuals who have risen above the rest, inspiring us all and setting new standards of achievement in the realm of accountancy.
          </div>
          <div className="col-lg-4 col-10" data-aos="fade-left">
            <img className="img-fluid" src={MeritList} alt="Merit List" />
          </div>
        </div>
        <div className="d-flex align-items-center" data-aos="fade-up">
          <div className="b"></div>
          <div data-aos="zoom-in" className="my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
            <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
            <h3 className="text-light fw-bold mb-0 text-uppercase">MERIT LIST</h3>&nbsp;&nbsp;&nbsp;&nbsp;
            <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
          </div>
          <div className="b"></div>
        </div>
      </div>

      <div className="container-fluid">
        <ul className="three">
          {currentDetail.length > 0 ? (
            currentDetail.map((ann, id) => (
              <li key={id} className="transition col-3" data-aos="flip-left">
                <div className="wrapper">
                  <img className="transition rounded-circle" width={"40%"} src={picUrl + ann.photos} alt={ann.name} />
                  <ul className="social">
                    <li>{ann.description}</li>
                  </ul>
                  <span className="transition">
                    <h3>{ann.name}</h3>
                    <img className="mamber-img rounded-circle" width={"40%"} src={picUrl + ann.photos} alt={ann.name} />
                    <h3>{ann.name}</h3>
                    <i>more</i>
                  </span>
                </div>
              </li>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </ul>

        <ReactPaginate
                pageCount={Math.ceil(details.length / universitiesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
            />
      </div>
    </>
  );
}

export default MeritStudents;
