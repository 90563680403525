import React, { useEffect, useState } from 'react'
import "../../CSS/CACalender.css"
import Navbar from '../../Components/navbar'
import { backendURL, currentYear } from '../../constant';
import CollegeNavbar from '../../Components/CollegeNavbar';
function CAIslamabadCalender() {

    const [detail, setDetail] = useState({});


    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getCACalenderF8`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);

        }
    };

    useEffect(() => {
        callProfile();
    }, [callProfile]);

    return (
        <>

            <CollegeNavbar />
            <div className='CACalenderbg'>
                <div className=' container-fluid ps-4 text-light filter9'>
                    <p className='mb-0'>Home / CA Calender F8 Islamabad</p><br></br>
                    <h1 className='fw-bold d-md-block d-none'>CA Calender F8 Islamabad</h1>
                    <h3 className='fw-bold d-md-none d-block'>CA Calender F8 Islamabad</h3>

                    {/* <p className='fs-2 fw-bold'>(COMPUTER BASED EXAM)</p> */}
                </div>
            </div>


            <div className='container mt-5 px-sm-1 px-0 '>

                <table class="table2" >
                    <tr class="tr2">
                        <th class="th2">PAPER</th>
                        <th colspan="2" class="th2">TEACHER</th>
                        <th class="th2">BATCH</th>
                        <th class="th2">BATCH STARTED</th>
                        <th class="th2">BATCH END</th>

                    </tr>
                    {Array.isArray(detail) && detail.length > 0 ? (
                                        detail.map((ann, id) => (
                    <tr class="tr2">
                        <td class="td2 fw-bold">{ann.paper}</td>
                        <td class="td2">{ann.teacher}</td>
                        <td class="td2"></td>

                        <td class="td2">{ann.batch}</td>
                        <td class="td2 text-success">{ann.batchstart}</td>
                        <td class="td2 text-danger">{ann.batchend}</td>

                    </tr>
                                        ))):(<p>Not Found</p>)
                                        }
                    

                </table>

            </div>


            <div className='bgdarkblue py-4 mt-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
        </>
    )
}

export default CAIslamabadCalender
