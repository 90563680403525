import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateCounter() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Counter, setCounter] = useState({
        counter1: "",
        counter2: "",
        counter3: "",
        counter4:"",
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setCounter({ ...Counter, [name]: value });
    };

    const callIndCounter = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewHomeCounter/${id.trim()}`);
            const CounterData = response.data;
            setCounter(CounterData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndCounter();
    }, [callIndCounter]);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/updateHomeCounter/${id}`, Counter);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });

            setTimeout(() => {
                navigate("/HomeCounter");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Update Counter", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
            <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center pb-3 fontcolordark">Update Counter</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form method='PATCH' onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="counter1"
                                    type="text"
                                    id="counter1"
                                    className="form-control"
                                    placeholder="Enter counter1 here"
                                    value={Counter.counter1}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="counter2"
                                    type="text"
                                    id="counter2"
                                    className="form-control"
                                    placeholder="Enter counter2 here"
                                    value={Counter.counter2}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="counter3"
                                    type="text"
                                    id="counter3"
                                    className="form-control"
                                    placeholder="Enter counter3 here"
                                    value={Counter.counter3}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="counter4"
                                    type="text"
                                    id="counter4"
                                    className="form-control"
                                    placeholder="Enter counter4 here"
                                    value={Counter.counter4}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update Counter
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateCounter;
