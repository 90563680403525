import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../Sidebar';
import { backendURL } from '../../../../constant';

function UpdateFeeStructure() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Announcment, setAnnouncment] = useState({
        level: "",
        name: "",
        fees: "",
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncment({ ...Announcment, [name]: value });
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewFeeStructure/${id.trim()}`);
            const announcementData = response.data;
            setAnnouncment(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/updateFeeStructure/${id.trim()}`, Announcment);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });

            setTimeout(() => {
                navigate("/AdminFeeStructure");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Update Fee Structure", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark">Update CA FEE STRUCTURE</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-md-5 ms-0 pb-1 col-md-11 shadow-lg mb-4">
                    <form method="PATCH" onSubmit={handleDataUpload}>
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="level"
                                    type="text"
                                    id="level"
                                    className="form-control"
                                    placeholder="Enter level here"
                                    value={Announcment.level}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="name"
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    placeholder="Enter name here"
                                    value={Announcment.name}
                                    onChange={handleInput}
                                    required
                                />
                            </div>

                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="fees"
                                    type="number"
                                    id="fees"
                                    className="form-control"
                                    placeholder="Enter fees here"
                                    value={Announcment.fees}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update FeeStructure
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default UpdateFeeStructure;
