import React, { useEffect, useState } from 'react'
import '../../Components/sidenav.css';
import logo1 from "../../Photos/Skanslogo1.png"
import { Link, useNavigate } from 'react-router-dom';
import { backendURL } from '../../constant';


function Sidebar() {
    const navigate = useNavigate();
    const [userdata, setUserdata] = useState({
        photo: "",
        name: "",
        email: "",
        password: "",
        DOB: "",
        city: "",
        gender: "",
        mobile: "",

    });

    // const callgetAdmin = async () => {
    //     try {
    //         const cookies = document.cookie;
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //        const t= localStorage.getItem("token");
    //         myHeaders.append("Cookie", `${cookies}`);

    //         const requestOptions = {
    //             method: "GET",
    //             headers: myHeaders,
    //             redirect: "follow"
    //         };

    //         fetch(`${backendURL}/Admindash`, requestOptions)
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     return response.json();
    //                 } else if ( response.status === 401 || response.status === 500) {
    //                     throw new Error(response.statusText);
    //                 } else {
    //                     throw new Error("Unexpected response status: " + response.status);
    //                 }
    //             })
    //             .then(async (result) => {
    //                 console.log("highlight", result);
    //                 setUserdata(result);
    //             })
    //             .catch((error) => console.error(error));
            
    //     } catch (err) {
    //         console.error(err);
    //         navigate("/AdminLogin");
    //     }
    // }
    const callgetAdmin = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            // Retrieve the token from localStorage
            const token = localStorage.getItem("token");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`);
            } else {
                console.error("Token is missing");
                navigate("/AdminLogin");
                return;
            }
    
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                credentials: 'include', // Include credentials if needed
                redirect: "follow"
            };
    
            const response = await fetch(`${backendURL}/Admindash`, requestOptions);
    
            if (response.ok) {
                const result = await response.json();
                console.log("Data:", result);
                setUserdata(result);
            } else if (response.status === 401) {
                console.error("Unauthorized");
                navigate("/AdminLogin");
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error:", error);
            navigate("/AdminLogin");
        }
    };

    useEffect(() => {
        callgetAdmin();
    }, []);
    return (
        <>
            <div class="container-fluid d-flex justify-content-between align-items-center py-2 bgdarkblue position-fixed-top border border-top-0 border-bottom-0 border-3">
                {/* <a class="navbar-brand" href="#">Navbar</a> */}
                <div className='col-lg-4 col-2'>
                    {/* <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={toggleSidebar}>
                                    <i className="fas fa-align-left text-white fs-3"></i>
                                    <span className='ps-3 text-white fs-5'>Menu</span>
                                </button> */}
                    {/* <Sidebar/> */}

                    <button class="btn btn-primary d-flex" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                        <i className="fas fa-align-left text-white fs-3"></i>
                        <span className='ps-3 text-white fs-5 d-lg-block d-none'>Menu</span>
                    </button>
                </div>

                <div className='col-lg-2 col-md-3 col-4'>
                    <img className='img-fluid' src={logo1}></img>

                </div>

                <div className='col-lg-4 col-3'>

                    <ul class="navbar-nav text-white pt-lg-0">

                        <Link class="d-flex  ps-lg-5 ps-0  justify-content-evenly align-items-center text-decoration-none " to="/AdminProfile">
                            <p class="text-end d-lg-block d-none nav-link col-6 active text-white text-capitalize mb-0" aria-current="page" >{userdata.name}<img class="ms-4 rounded-circle text-white img-fluid " width={"30%"} src="https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg"></img></p>
                            <Link className=' text-decoration-none text-center fw-bold text-white btn btn-primary' aria-current="page" to="/AdminLogout">Logout</Link>
                        </Link>





                    </ul>


                </div>

            </div>

            {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                <i className="fas fa-align-left text-white fs-3"></i>
                <span className='ps-3 text-white fs-5'>Menu</span>
            </button> */}

            <div class="offcanvas offcanvas-start bgdarkblue" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div class="offcanvas-header">
                    {/* <div className="sidebar-header"> */}
                    <div className='col-6'>
                        <img className='img-fluid' src={logo1}></img>
                    </div>
                    {/* <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">Backdrop with scrolling</h5> */}
                    <button type="button" class="border border-0 bgdarkblue fs-4 text-white p-0" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                </div>
                <div id='sidebar' class="offcanvas-body">


                    <ul className="list-unstyled components ">
                        {/* <p>Dummy Heading</p> */}

                        <li className="active">
                            <Link to="/AdminDashboard">Dashboard</Link>
                        </li>
                        <li>
                            <Link to="/AdminComplaintBox">Complaint Box</Link>
                        </li>
                        
                        <li >

                            <a
                                href="#homeSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className=" d-flex justify-content-between pe-3 align-items-center"
                            >
                                Home
                                <i class="bi bi-chevron-down"></i>
                            </a>

                            <ul className="collapse list-unstyled" id="homeSubmenu">
                                <li>
                                    <Link to="/HomeScreen">Screen Images</Link>
                                </li>
                                <li>
                                    <Link to="/HomeHighlights">Highlights</Link>
                                </li>
                                <li>
                                    <Link to="/HomeAnnouncement">Announcement</Link>
                                </li>
                                <li>
                                    <Link to="/HomeHighAchiever">High Achievers</Link>
                                </li>
                                <li>
                                    <Link to="/HomeCounter">Home Counter</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a
                                href="#feeSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className=" d-flex justify-content-between pe-3 align-items-center"
                            >
                                Edit Fee Structure
                                <i class="bi bi-chevron-down"></i>
                            </a>

                            <ul className="collapse list-unstyled" id="feeSubmenu">
                                <li>
                                    <Link to="/AdminFeeStructure">CA Fee Structre</Link>
                                </li>
                                <li>
                                    <Link to="/AdminACCAFeeStructure">ACCA Fee Structure</Link>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <a
                                href="#pageSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                            >
                                Edit Faculty
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="pageSubmenu">
                                <li>
                                    <Link to="/CAFacultyF8">CA Faculty Islamabad</Link>
                                </li>
                                <li>
                                    <Link to="/ACCAFacultyF8">ACCA Faculty Islamabad</Link>
                                </li>
                                <li>
                                    <Link to="/CAFacultyST">CA Faculty ST</Link>
                                </li>
                                <li>
                                    <Link to="/ACCAFacultyST">ACCA Faculty ST</Link>
                                </li>
                                <li>
                                    <Link to="/CAFacultyRAR">CA Faculty RAR</Link>
                                </li>
                                <li>
                                    <Link to="/ACCAFacultyRAR">ACCA Faculty RAR</Link>
                                </li>
                                <li>
                                    <Link to="/ACCAFacultyPeshawarRoad">ACCA Faculty Peshawar Road</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link href="#">Edit CBE Dates</Link>
                        </li>
                        <li>
                            <a
                                href="#CalenderSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                            >
                                Edit Calender
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="CalenderSubmenu">
                                <li>
                                    <a
                                        href="#IslamabadSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Islamabad
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="IslamabadSubmenu">
                                        <li>
                                            <Link to="/AdminF8CACalender">CA Islamabad</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminF8ACCACalender">ACCA Islamabad</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminUpcomingEventF8">Upcoming Event Islamabad</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminCompletedEventF8">Completed Event Islamabad</Link>
                                        </li>

                                    </ul>
                                </li>



                                <li>
                                    <a
                                        href="#RawalpindiSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        RAR Campus
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="RawalpindiSubmenu">
                                        <li>
                                            <Link to="/AdminRARCACalender">CA RAR</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminRARACCACalender">ACCA RAR</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminUpcomingEventRAR">Upcoming Event RAR</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminCompletedEventRAR">Completed Event RAR</Link>
                                        </li>

                                    </ul>
                                </li>



                                <li>
                                    <a
                                        href="#RawalpindiSTSubmenu"
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                    >
                                        Satellite Town  Campus
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="collapse list-unstyled bg-primary" id="RawalpindiSTSubmenu">
                                        <li>
                                            <Link to="/AdminSTCACalender">CA ST</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminSTACCACalender">ACCA ST</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminUpcomingEventST">Upcoming Event ST</Link>
                                        </li>
                                        <li>
                                            <Link to="/AdminCompletedEventST">Completed Event ST</Link>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </li>


                        <li>
                            <Link to="/AdminlifeatSkans">Edit Life@SKANS</Link>
                        </li>



                        <li>
                            <a
                                href="#NoticeboardSubmenu"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                className="d-flex justify-content-between pe-3 align-items-center"
                                // to="/AdminCollegeNoticeBoard"
                            >
                                Edit Noticeboard
                                <i class="bi bi-chevron-down"></i>
                            </a>
                            <ul className="collapse list-unstyled" id="NoticeboardSubmenu">
                                <li>
                                    <Link
                                        // href="#CollegeSubmenu"
                                        // data-bs-toggle="collapse"
                                        // aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                        to="/AdminCollegeNoticeBoard"
                                    >
                                        Colleges
                                        {/* <i class="bi bi-chevron-down"></i> */}
                                    </Link>
                                    {/* <ul className="collapse list-unstyled bg-primary" id="CollegeSubmenu">
                                        <li>
                                            <Link href="#">CA Islamabad</Link>
                                        </li>
                                        <li>
                                            <Link href="#">ACCA Islamabad</Link>
                                        </li>
                                        <li>
                                            <Link href="#">CA Rawalpindi</Link>
                                        </li>
                                        <li>
                                            <Link href="#">ACCA Rawalpindi</Link>
                                        </li>

                                    </ul> */}
                                </li>



                                <li className='bg-primary'>
                                    <Link
                                        // href="#SchoolSubmenu"
                                        // data-bs-toggle="collapse"
                                        // aria-expanded="false"
                                        className="d-flex justify-content-between pe-3 align-items-center"
                                        to="/AdminSchoolNoticeBoard"
                                    >
                                        Schools
                                        {/* <i class="bi bi-chevron-down"></i> */}
                                    </Link>
                                    {/* <ul className="collapse list-unstyled " id="SchoolSubmenu">
                                        <li>
                                            <Link href="#">AECHS Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">PWD Junior Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">Harley Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">Chakri Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">Westridge Junior Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">Westridge Senior Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">Golra Mor Campus</Link>
                                        </li>
                                        <li>
                                            <Link href="#">Peshawar Road Campus</Link>
                                        </li>

                                    </ul> */}
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/AdminHighAchievers">Edit Merit List</Link>
                        </li>

                    </ul>
                </div>
            </div>

        </>
    )
}

export default Sidebar
