import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { backendURL } from "../../../constant";

function LifeatSkans() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const[photos,setPhotos]=useState([{}])
    const [currentPage, setCurrentPage] = useState(0);
    const universitiesPerPage = 30;

    const handlePhotoChange = (e) => {
        const updatedUser = { ...user };
        updatedUser.photos = e.target.files; // Check that e.target.files contains selected files
        setUser(updatedUser);
        console.log(updatedUser); // Log updatedUser to verify files are stored
    };
    
    

    const handlePhotoUpload = async (e) => {
        e.preventDefault();
        

        const formData = new FormData();
        for (let i = 0; i < user.photos.length; i++) {
            formData.append('photos', user.photos[i]);
          }
        //   formData.append('caption', data.caption);
      
          try {
            const response = await fetch(`${backendURL}/galleryphoto`, {
              method: 'POST',
              body: formData,
            });
      
            console.log(response);
            toast.success("Image Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            callProfile();
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Image", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getgalleryphoto`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setPhotos(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    };



    const DeleteDemographic = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/Deletegalleryphoto/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedata = await res2.json();
            console.log(deletedata);

            if (res2.status === 422 || !deletedata) {
                console.log("error");
            } else {
                toast.success("Question Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        callProfile();
    }, []);
    const picurl = `${backendURL}/public/gallery/`;

    const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
    const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
    const currentphotos = photos.slice(indexOfFirstUniversity, indexOfLastUniversity);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <h1 className="text-center fontcolordark fw-bold">Life @ SKANS</h1>
            </div>
            <div className="mt-5">
                <form className="d-flex justify-content-around flex-wrap" onSubmit={handlePhotoUpload} encType="multipart/form-data">
                    <div className="col-md-4 border-right">
                        <input
                            name="photos"
                            id="photos"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            className="form-control"
                            onChange={handlePhotoChange}
                            required
                            multiple
                        />
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Photo
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        <div className="p-2 d-flex flex-wrap justify-content-around">
                            {
                                Array.isArray(currentphotos) && currentphotos.length > 0
                                    ? currentphotos.map((photo, id) => (
                                        <li key={id} className="d-flex flex-column border  rounded-2  shadow-sm mt-3 px-4 py-2">
                                            <div className="col-9">
                                                <span className="fw-bold">{id + 1}.</span> <img width="180px" height={"180px"} src={picurl + photo.photos} alt={`Photo ${id + 1}`} />
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <Link to={`/UpdateLifeatSkans/${photo.id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                                <Link className="ms-4" onClick={() => DeleteDemographic(photo.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                            </div>
                                        </li>
                                    )) : <p>Not Available</p>}
                        </div>

                        <ReactPaginate
                pageCount={Math.ceil(photos.length / universitiesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
            />
                        <div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default LifeatSkans;
