import React from 'react'
import GlobalSidebar from './GlobalSideBar'
import logo from "../Photos/logo.png"
import logo1 from "../Photos/Skanslogo1.png"
import logo2 from "../Photos/Skanslogo2.png"
import logo3 from "../Photos/30yearslogo.png"
import { Link } from 'react-router-dom'

function HighachieverNavbar() {
  return (
    <div className='body1  bg-white  sticky-top zindex4'  >  {/*sticky-top */}

                <div className='container-fluid d-flex justify-content-between align-items-center position-relative py-lg-2 ps-lg-4 pb-lg-4 p-2 shadow-lg fw-bold fontcolor'>
                    <div className=' col-1 d-block d-lg-none '>

                        <a className=" menu-toggle-button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions1" aria-controls="offcanvasWithBothOptions1">
                            <i class="bi bi-list fs-1 fontcolordark"></i>

                        </a>
                    </div>
                    {/* <div className=' col-1 d-block d-lg-none '>
                        <a class="toggle-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                            <i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i>

                        </a>
                    </div> */}
                    <div className='col-md-2 col-3 mb-lg-3 mb-0'>
                        <img className='img-fluid' src={logo}></img>
                    </div>
                    <div className='d-flex justify-content-center col-md-2 col-3 mb-lg-3 mb-0'>
                        <img className='img-fluid' width={"75%"} src={logo3}></img>
                    </div>
                    {/* <div className='col-md-2 col-3 mb-lg-3 mb-0'>
                        <img className='img-fluid' width={"80%"} src={logo2}></img>
                    </div> */}




                    <div className={`d-lg-block d-none positionabsolute container text-white`}>
                        <ul className={` exo-menu d-flex justify-content-around bgdarkblue  list-unstyled  px-0`}>

                            <li className="active pe-0"><Link className='px-3 py-4' to="/"> Home</Link></li>

                            <li className="drop-down">
                                <a className='px-2 pt-4 pb- text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            CA / ACCA &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn text-white">

                                    <li><Link to="/CAProgram">CA</Link></li>
                                    <li><Link to="/ACCAProgram">ACCA</Link></li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Campuses Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/F8Campus">F8 Campus</Link></li>
                                            <li><Link to="/SatelliteTownCampus">Sattelite Town Campus</Link></li>
                                            <li><Link to="/RARCAmpus">Raja Akram Road, Saddar Campus</Link></li>
                                            <li><Link to="/ACCAPeshawarRoadCampus">ACCA Peshawar Road Campus</Link></li>



                                        </ul>
                                    </li>

                                    <li className="flyout-right "><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>F8 Islamabad Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CAF8Faculty">CA Faculty</Link></li>
                                            <li><Link to="/ACCAF8Faculty">ACCA Faculty</Link></li>

                                        </ul>
                                    </li>

                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Raja Akram Road, Saddar Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CA_RARFaculty">CA Faculty</Link></li>
                                            <li><Link to="/ACCA_RARFaculty">ACCA Faculty</Link></li>
                                        </ul>
                                    </li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Satellite Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CA_STFaculty">CA Faculty</Link></li>
                                            <li><Link to="/ACCA_STFaculty">ACCA Faculty</Link></li>
                                        </ul>
                                    </li>
                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Peshawar Road Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            
                                            <li><Link to="/ACCAPeshawarRoadFaculty">ACCA Faculty</Link></li>
                                        </ul>
                                    </li>

                                </ul>
                            </li>

                            {/* <li className="drop-down">
                                <a className='px-2 pt-4 pb-1 text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            Schools &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="flyout-right"><a >Our Campuses&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0" style={{ width: "300px" }}>

                                            <li><Link className='p-2' to="/JuniorHarleyCampus">Harley Street Campus</Link></li>
                                           
                                            <li><Link className='p-2' to="/JuniorChakriRoad">Junior Chakri Road Campus </Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeCampus">Junior Westridge Campus </Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeCampus">Senior Westridge Campus</Link></li>
                                            <li><Link className='p-2' to="/GolraMorCampus">Golra Mor Campus</Link></li>
                                            <li><Link className='p-2' to="/PeshawarRoadCampus">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDCampus">SKANS PWD Campus</Link></li>
                                            <li><Link className='p-2' to="/AirportCampus">SKANS AECHS Campus</Link></li>






                                        </ul>
                                    </li>

                                  
                                    <li><Link to="/ParentsCorner">Parents Corner</Link></li>
                                    <li><Link to="/Curriculum">Curriculum</Link></li>
                                    <li><Link to="/Facilities">Facilities</Link></li>
                                    <li><Link to="/ContactUs">Contact Us</Link></li>

                                </ul>
                            </li>
*/}
                            <li><Link className='px-2 py-4' to="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</Link></li> 

                            <li className="drop-down">
                                <a className='px-2 pt-4 pb-1' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            Calender &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>F8 Islamabad Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CAIsalamabadCalender">CA Calender</Link></li>
                                            <li><Link to="/ACCAIslamabadCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventIslamabad">F8 Islamabad Event </Link></li>
                                        </ul>
                                    </li>

                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Satellite Town Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CASatelliteTownCalender">CA Calender</Link></li>
                                            <li><Link to="/ACCASatelliteTownCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventSatelliteTown">Satellite Town Event </Link></li>
                                        </ul>
                                    </li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Raja Akram Road, Saddar Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CA_RARCalender">CA Calender</Link></li>
                                            <li><Link to="/ACCA_RARCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventRAR">Raja Akram Road, Saddar Campus Event </Link></li>
                                        </ul>
                                    </li>

                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Peshawar Road Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            
                                            <li><Link to="/ACCAPeshawarRoadCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventPeshawarRoad">Peshawar Road Campus Event </Link></li>
                                        </ul>
                                    </li>

                                   
                                </ul>
                            </li>

                            <li><Link className='px-2 py-4' to="/SkansGallery">Life @ SKANS</Link></li>

                            <li className="drop-down">
                                <a className='px-2 pt-4 pb-1' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            Noticeboard &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                                    {/* <li className="flyout-left"><a ><i class="bi bi-chevron-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>Colleges</a>
                                        <ul className="animated fadeIn ps-0"> */}
                                            <li><Link to="/RARNoticeBoard">Raja Akram Road, Saddar Campus</Link></li>
                                            <li><Link to="/SatelliteTownNoticeBoard">Satellite Town Campus</Link></li>
                                            <li><Link to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Campus</Link></li>
                                            <li><Link to="/F8IslamabadNoticeBoard">F8 Islamabad Campus</Link></li>



                                        {/* </ul>
                                    </li> */}

                                    {/* <li className="flyout-left"><a ><i class="bi bi-chevron-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>Schools</a>
                                        <ul className="animated fadeIn" style={{ width: "300px" }}>
                                            <li><Link className='p-2' to="/HarleyStreetNoticeBoard">Harley Street Branch</Link></li>
                                            
                                            <li><Link className='p-2' to="/JuniorChakriRoadNoticeboard">Junior Campus Chakri Road</Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeNoticeboard">Junior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeNoticeboard">Senior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/GolraMorNoticeboard">Golra Mor Campus</Link></li>


                                            <li><Link className='p-2' to="/PeshawarRoadNoticeboard">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/AECHSNoticeboard">AECHS Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDNoticeboard">PWD Campus</Link></li>

                                        </ul>
                                    </li> */}

                                </ul>
                            </li>

                            <li><Link className='px-2 py-4' to="/Meritstudents">High Achievers</Link></li>


                        </ul>


                        {/* <ul className={` exo-menu d-flex justify-content-around bgdarkblue  list-unstyled  px-0`}>

                            <li className="active pe-0"><Link className='px-3 py-4' to="/"> Home</Link></li>

                            <li className="drop-down">
                                <a className='px-2 pt-4 pb-1 text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            SKANS Schools of Accountancy - Colleges &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">



                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA / ACCA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CAProgram">CA</Link></li>
                                            <li><Link to="/ACCAProgram">ACCA</Link></li>


                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Campuses Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/F8Campus">F8 Campus</Link></li>
                                                    <li><Link to="/SatelliteTownCampus">Sattelite Town Campus</Link></li>
                                                    <li><Link to="/RARCAmpus">RAR Campus</Link></li>
                                                    <li><Link to="/ACCAPeshawarRoadCampus">ACCA Peshawar Road Campus</Link></li>



                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>F8 Islamabad Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CAF8Faculty">CA Faculty</Link></li>
                                                    <li><Link to="/ACCAF8Faculty">ACCA Faculty</Link></li>

                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>RAR Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CA_RARFaculty">CA Faculty</Link></li>
                                                    <li><Link to="/ACCA_RARFaculty">ACCA Faculty</Link></li>
                                                </ul>
                                            </li>


                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Satellite Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CA_STFaculty">CA Faculty</Link></li>
                                                    <li><Link to="/ACCA_STFaculty">ACCA Faculty</Link></li>
                                                </ul>
                                            </li>
                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Peshawar Road Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">

                                                    <li><Link to="/ACCAPeshawarRoadFaculty">ACCA Faculty</Link></li>
                                                </ul>
                                            </li>




                                        </ul>
                                    </li>


                                    <li><Link className='' to="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</Link></li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Calender &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>F8 Islamabad Campus &nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CAIsalamabadCalender">CA Calender</Link></li>
                                                    <li><Link to="/ACCAIslamabadCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventIslamabad">F8 Islamabad Event </Link></li>
                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Satellite Town Campus &nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CASatelliteTownCalender">CA Calender</Link></li>
                                                    <li><Link to="/ACCASatelliteTownCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventSatelliteTown">Satellite Town Event </Link></li>
                                                </ul>
                                            </li>


                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>RAR Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CA_RARCalender">CA Calender</Link></li>
                                                    <li><Link to="/ACCA_RARCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventRAR">RAR Campus Event </Link></li>
                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Peshawar Road Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">

                                                    <li><Link to="/ACCAPeshawarRoadCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventPeshawarRoad">Peshawar Road Campus Event </Link></li>
                                                </ul>
                                            </li>


                                        </ul>
                                    </li>


                                    <li className="flyout-right"><a>Noticeboard &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/RARNoticeBoard">RAR Campus</Link></li>
                                            <li><Link to="/SatelliteTownNoticeBoard">Satellite Town Campus</Link></li>
                                            <li><Link to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Campus</Link></li>
                                            <li><Link to="/F8IslamabadNoticeBoard">F8 Islamabad Campus</Link></li>



                                        </ul>
                                    </li>


                               

                                </ul>
                            </li>





                            <li className="drop-down">
                                <a className='px-2 pt-4 pb-1 text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            SKANS Schools &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">


                                    <li className="flyout-right"><a>Our Campuses &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link className='p-2' to="/JuniorHarleyCampus">Harley Street Campus</Link></li>

                                            <li><Link className='p-2' to="/JuniorChakriRoad">Junior Chakri Road Campus </Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeCampus">Junior Westridge Campus </Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeCampus">Senior Westridge Campus</Link></li>
                                            <li><Link className='p-2' to="/GolraMorCampus">Golra Mor Campus</Link></li>
                                            <li><Link className='p-2' to="/PeshawarRoadCampus">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDCampus">SKANS PWD Campus</Link></li>
                                            <li><Link className='p-2' to="/AirportCampus">SKANS AECHS Campus</Link></li>


                                        </ul>
                                    </li>
                                    <li className="flyout-right"><a>Noticeboard &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link className='p-2' to="/HarleyStreetNoticeBoard">Harley Street Branch</Link></li>

                                            <li><Link className='p-2' to="/JuniorChakriRoadNoticeboard">Junior Campus Chakri Road</Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeNoticeboard">Junior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeNoticeboard">Senior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/GolraMorNoticeboard">Golra Mor Campus</Link></li>


                                            <li><Link className='p-2' to="/PeshawarRoadNoticeboard">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/AECHSNoticeboard">AECHS Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDNoticeboard">PWD Campus</Link></li>



                                        </ul>
                                    </li>
                                    <li><Link to="/ParentsCorner">Parents Corner</Link></li>
                                    <li><Link to="/Curriculum">Curriculum</Link></li>
                                    <li><Link to="/Facilities">Facilities</Link></li>
                                    

                                    <li><Link to="/ContactUs">Contact Us</Link></li>

                                </ul>
                            </li>
                            <li><Link className='px-2 py-4' to="/SkansGallery">Life @ SKANS</Link></li>


                            <li><Link className='px-2 py-4' to="/Meritstudents">High Achievers</Link></li>

                        </ul> */}

                    </div>






























                    <div className='ms-3 col-1 d-block d-lg-none '>
                        {/* <a className='toggle-menu pt-2' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i></a> */}

                        {/* <a type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i></a> */}
                        <GlobalSidebar />

                    </div>






                </div>
            </div>
  )
}

export default HighachieverNavbar