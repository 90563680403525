import React from 'react'
import Navbar from '../../Components/navbar'
import "../../CSS/GulzareQuaid.css"
import Junior from "../../Photos/juniorHarley.jpg"
import Sectiontitle1 from "../../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../../Photos/section-title-shape-2.png"
import F8Campus from "../../Photos/F8Campus.jpg"
import RARCampus from "../../Photos/RAR campus.jpeg"
import { currentYear } from '../../constant'
import peshawarroad from "../../Photos/bgpeshawarroad.jpeg"
import CollegeNavbar from '../../Components/CollegeNavbar'
function SatelliteTownCampus() {
    return (
        <>

            <CollegeNavbar />
            <div className='AdmissionSTbg'>
                <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-md-1 fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>Satellite Town CAMPUS <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
                            <h2 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Satellite Town CAMPUS <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>

                            <div className='fw-bold fs-md-1 fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='bg-lightgrey'>
                <div className='container-sm container-fluid'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 text-uppercase d-md-block d-none'>SKANS Satellite Town CAMPUS</h3>&nbsp;&nbsp;
                            <h6 className='text-light fw-bold mb-0 text-uppercase d-md-none d-block text-center'>SKANS Satellite Town CAMPUS</h6>&nbsp;&nbsp;

                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>



                    <div>
                        {/* <p><span className='fw-bold'>Address:</span> House No 53-B Harley Street Campus – Rawalpindi</p>
                    <p><span className='fw-bold'>Email: </span>            <a className='text-decoration-none' href=""> school.info@skans.pk</a></p>
                    <p><span className='fw-bold'>Mobile:  </span>          (+92)-332-8922225</p>
                    <p><span className='fw-bold'>Telephone: </span>    (+92)-51-5176138</p>
                    <p><span className='fw-bold'>Telephone 1: </span>  (+92)-51-5176066</p> */}




                        <table className='table table-light table-striped' data-aos="fade-up">

                            <th className='fw-bold fs-2 th1 ps-3' colspan="2">Contact Information</th>

                            <tbody>
                                <tr>
                                    <td className='fw-bold fs-5'>Address:</td>
                                    <td>House No. 77-A,Block A,Satellite Town Rehmanabad
                                        Rawalpindi</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold fs-5'>Email:</td>
                                    <td><a href="mailto:inquiry@skans.pk">inquiry@skans.pk</a></td>
                                </tr>
                                <tr>
                                    <td className='fw-bold fs-5'>Telephone 1:</td>
                                    <td>(+92)-51-2716089</td>
                                </tr>

                            </tbody>
                        </table>



                        <div className='mt-5'>
                            <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d53147.89052576665!2d73.07676700000002!3d33.637901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df955662cb3185%3A0x560236c8cbc6bf77!2sSKANS%20School%20of%20Accountancy%20New%20Campus!5e0!3m2!1sen!2sus!4v1696332039984!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            
                        </div>
                    </div>





                    <div>
                        <div className='d-flex align-items-center ' data-aos="fade-up">
                            <div className='b'>

                            </div>
                            <div data-aos="zoom-in" className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                                <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                <h3 className='text-light fw-bold mb-0 d-flex align-items-center d-md-block d-none text-center'> <span class=" fw-bold fs-1 pe-sm-3 px-0"> + </span> OTHER CAMPUSES</h3>&nbsp;&nbsp;
                                <h6 className='text-light fw-bold mb-0 d-flex align-items-center d-md-none d-block text-center'> <span class=" fw-bold fs-1 pe-sm-3 px-0"> + </span> OTHER CAMPUSES</h6>&nbsp;&nbsp;

                                <img height={"10%"} src={Sectiontitle2}></img>
                            </div>
                            <div className='b'>
                            </div>
                        </div>



                        <p data-aos="flip-right" className='text-center opacity-75 fw-bold fs-5'>We build your child’s future, view our branches near you so we will be in your reach.</p>

                        <div class="row row-cols-1 row-cols-md-3 g-4 d-flex justify-content-around">
                            <div class="col" data-aos="fade-right">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={RARCampus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">Raja Akram Road, Saddar Campus</h5>
                                        <p class="card-text">16-Raja Akram Road, Saddar, Rawalpindi Cantt</p>
                                        <p className=' fw-bold'>(+92)-51-5125148–49<br></br>
                                            (+92)-51-5125155<br></br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col" data-aos="fade-left">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={F8Campus} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">F8 Isalamabad Campus</h5>
                                        <p class="card-text">14A-Haroon Building F8, Markaz Islamabad<br></br>

                                        </p>
                                        <br></br>
                                        <p className=' fw-bold'>
                                            (+92)-51-2287313
                                            <br></br>
                                            (+92)-51-2287315<br></br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col" data-aos="fade-right">
                                <div class="card border-0 shadow-lg rounded-5">
                                    <img src={peshawarroad} class="card-img-top rounded-top-5" alt="..." />
                                    <div class="card-body">
                                        <h5 class="fontcolordark fw-bold">ACCA Peshawar Road Campus</h5>
                                        <p class="card-text">304 Bangash Plaza Near Maryam Memorial Hospital Peshawar Road, Rawalpindi</p>
                                        <p className=' fw-bold'>051 5490876<br></br>
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>




                <div className='bgdarkblue py-4 mt-4'>
                    <div className='d-flex justify-content-around text-white'>
                        <div>
                            © Copyright {currentYear} - All rights reserved
                        </div>
                        <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                    </div>


                </div>
            </div>

        </>
    )
}

export default SatelliteTownCampus
