import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react'

// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
import { Routes, Route } from "react-router-dom"
import "./CSS/bootstrap.min.css"
import './App.css'
import Home from './Screens/Home'
import CAPrograms from './Screens/CAPrograms'
import ACCAProgram from './Screens/ACCAProgram'
import Cirriculum from './Screens/Cirriculum'
import ParentsCorner from './Screens/ParentsCorner'
import Facilities from './Screens/Facilities'
import ContactUs from './Screens/ContactUs'
import JuniorHarley from './Screens/Schools/JuniorHarley'
import GulzareQuaid from './Screens/Schools/GulzareQuaid'
import JuniorChakriRoad from './Screens/Schools/JuniorChakriRoad'
import CBEDates from './Screens/CBEDates'
import CARawalpindiCalender from './Screens/Calender/CARawalpindiCalender'
import CAIslamabadCalender from './Screens/Calender/CAIslamabadCalender'
import ACCARawalpindiCalender from './Screens/Calender/ACCARawalpindiCalender'
import ACCAIslamabadCalender from './Screens/Calender/ACCAIslamabadCalender'
import F8IslamabadNotice from './Screens/Noticeboard/College/F8IslamabadNotice'
// import ACCAIslamabadNotice from './Screens/Noticeboard/College/STNoticeboard'
import JuniorHarleyNotice from './Screens/Noticeboard/Schools/JuniorHarleyNotice'

import MeritStudents from './Screens/MeritStudents'
import CAIslamabadFaculty from './Screens/Faculty/CAIslamabadFaculty'
import MasonryGallery from './Screens/Gallery'
import CAFeeStructure from './Screens/FeeStructure/CAFeeStructure'
import ACCAFeeStructure from './Screens/FeeStructure/ACCAFeeStructure'
import AdmissionSchool from './Screens/SchoolAdmissions/AdmissionSchool'
import AdmissionDetailSchool from './Screens/SchoolAdmissions/HarleyStreetCampus'
import ACCAEventIslamabad from './Screens/Calender/Event/F8IsbEvent'
import HowtouseStudentportal from './Screens/TopNavbar/HowtouseStudentportal'
import Applyonline from './Screens/TopNavbar/Applyonline'

import Login from './Screens/Admin/Login'
import Admindash from './Screens/Admin/Admindash'
import GolraMorCampus from './Screens/Schools/GolraMorCampus'
import WestridgeCampus from './Screens/Schools/JuniorWestridge'

import PWDCampus from './Screens/Schools/PWDCampus'
import SeniorWestridgeCampus from './Screens/Schools/SeniorWestridge'
import AirportCampus from './Screens/Schools/AirportCampus'
import Profile from './Screens/Admin/AdminDash/Profile'
import HomeScreen from './Screens/Admin/Home/HomeScreen'
import UpdateScreenimage from './Screens/Admin/Home/UpdateScreenimage'
import HomeHighlights from './Screens/Admin/Home/HomeHighlights'
import UpdateHighlight from './Screens/Admin/Home/UpdateHighlight'
import Announcment from './Screens/Admin/Home/Announcment'
import UpdateAnnouncment from './Screens/Admin/Home/UpdateAnnouncment'
import HighAchiever from './Screens/Admin/Home/HighAchiever'
import F8Campus from './Screens/Colleges/F8Campus'
import RARCampus from './Screens/Colleges/RARCampus'
import SatelliteTownCampus from './Screens/Colleges/SatellitetownCampus'
import HomeCounter from './Screens/Admin/Home/HomeCounter'
import UpdateCounter from './Screens/Admin/Home/UpdateCounter'
import UpdateHighAchiever from './Screens/Admin/Home/UpdateHighAchiever'
import CAFacultyF8 from './Screens/Admin/Faculty/CAFacultyF8'
import UpdateCAFacultyF8 from './Screens/Admin/Faculty/UpdateCAFacultyF8'
import ACCAFacultyF8 from './Screens/Admin/Faculty/ACCAFacultyF8'
import UpdateACCAFacultyF8 from './Screens/Admin/Faculty/UpdateACCAFacultyF8'
import CAFacultyST from './Screens/Admin/Faculty/CAFacultyST'
import UpdateCAFacultyST from './Screens/Admin/Faculty/UpdateCAFacultyST'
import ACCAFacultyST from './Screens/Admin/Faculty/ACCAFacultyST'
import UpdateACCAFacultyST from './Screens/Admin/Faculty/UpdateACCAFacultyST'
import CAFacultyRAR from './Screens/Admin/Faculty/CAFacultyRAR'
import UpdateCAFacultyRAR from './Screens/Admin/Faculty/UpdateCAFacultyRAR'
import ACCAFacultyRAR from './Screens/Admin/Faculty/ACCAFacultyRAR'
import UpdateACCAFacultyRAR from './Screens/Admin/Faculty/UpdateACCAFacultyRAR'
import LifeatSkans from './Screens/Admin/Gallery/lifeatSkans'
import Updatelifeatskans from './Screens/Admin/Gallery/updatelifeatskans'
import F8CACalender from './Screens/Admin/Calender/F8CACalender'
import UpdateF8CACalender from './Screens/Admin/Calender/UpdateF8CACalender'
import STCACalender from './Screens/Admin/Calender/STCACalender'
import UpdateSTCACalender from './Screens/Admin/Calender/UpdateSTCACalender'
import RARCACalender from './Screens/Admin/Calender/RARCACalender'
import UpdateRARCACalender from './Screens/Admin/Calender/UpdateRARCACalender'
import F8ACCACalender from './Screens/Admin/Calender/F8ACCACalender'
import UpdateF8ACCACalender from './Screens/Admin/Calender/UpdateF8ACCACalender'
import STACCACalender from './Screens/Admin/Calender/STACCACalender'
import UpdateSTACCACalender from './Screens/Admin/Calender/UpdateSTACCACalender'
import RARACCACalender from './Screens/Admin/Calender/RARACCACalender'
import UpdateRARACCACalender from './Screens/Admin/Calender/UpdateRARACCACalender'
import CompletedEventF8 from './Screens/Admin/Events/F8completedEvent'
import UpdateCompletedEventF8 from './Screens/Admin/Events/UpdateF8completedEvent'
import CompletedEventST from './Screens/Admin/Events/STcompletedEvent'
import UpdateCompletedEventST from './Screens/Admin/Events/UpdateSTcompletedEvent'
import CompletedEventRAR from './Screens/Admin/Events/RARcompletedEvent'
import UpdateCompletedEventRAR from './Screens/Admin/Events/UpdateRARcompletedEvent'
import Test from './Screens/Admin/Events/test'
import CollegeNoticeBoard from './Screens/Admin/Noticeboard/College/CollegeNotice'
import UpdateCollegeNoticeboard from './Screens/Admin/Noticeboard/College/UpdateCollegeNoticeboard'
import SchoolNoticeBoard from './Screens/Admin/Noticeboard/School/SchoolNoticeBoard'
import UpdateSchoolNoticeboard from './Screens/Admin/Noticeboard/School/UpdateSchoolNoticeBoard'
import HighAchievers from './Screens/Admin/HighAchievers/HighAchievers'
import UpdateHighAchivers from './Screens/Admin/HighAchievers/UpdateHighAchivers'
import AnnouncementDetail from './Screens/AnnouncementDetail'
import CollegeFeeStructure from './Screens/Admin/FeeStructure/College/CollegeFeeStructure'
import UpdateFeeStructure from './Screens/Admin/FeeStructure/College/UpdateFeeStructure'
import ACCAF8Faculty from './Screens/Faculty/ACCAF8Faculty'
import CA_STFaculty from './Screens/Faculty/CA_STFaculty'
import ACCA_STFaculty from './Screens/Faculty/ACCA_STFaculty'
import CA_RARFaculty from './Screens/Faculty/CA_RARFaculty'
import ACCA_RARFaculty from './Screens/Faculty/ACCA_RARFaculty'
import CA_RARCalender from './Screens/Calender/CA_RARCalender'
import ACCA_RARCalender from './Screens/Calender/ACCA_RARCalender'
import UpcomingEventF8 from './Screens/Admin/Events/F8upcomingEvent'
import UpdateupcomingEventF8 from './Screens/Admin/Events/UpdateF8upcomingevent'
import UpcomingEventST from './Screens/Admin/Events/STupcomingEvent'
import UpdateupcomingEventST from './Screens/Admin/Events/UpdateSTupcomingEvent'
import UpcomingEventRAR from './Screens/Admin/Events/RARupcomingEvent'
import UpdateupcomingEventRAR from './Screens/Admin/Events/UpdateRARupcomingevent'
import EventSatelliteTown from './Screens/Calender/Event/STEvent'
import EventRAR from './Screens/Calender/Event/RAREvent'
import STNoticeboard from './Screens/Noticeboard/College/STNoticeboard'
import RARNoticeboard from './Screens/Noticeboard/College/RARNoticeboard'
import AECHSNoticeboard from './Screens/Noticeboard/Schools/AECHSNoticeboard'
import GulzareQuaidNoticeboard from './Screens/Noticeboard/Schools/GulzareQuaidNoticeboard'
import JuniorChakriNoticeboard from './Screens/Noticeboard/Schools/JuniorChakriNoticeboard'
import SeniorWestridgeNoticeboard from './Screens/Noticeboard/Schools/SeniorWestridgeNoticeboard'
import JuniorWestridgeNoticeboard from './Screens/Noticeboard/Schools/JuniorWestridgeCampus'
import GolraMorNoticeboard from './Screens/Noticeboard/Schools/GolraMorNoticeboard'
import PeshawarRoadNoticeboard from './Screens/Noticeboard/Schools/PeshawarRoadNoticeboard'
import PWDNoticeboard from './Screens/Noticeboard/Schools/PWDNoticeboard'

import LoadingOverlay from './Loader'
import AdmissionGulzareQuaid from './Screens/SchoolAdmissions/GulzareQuaidCampus'
import AdmissionJuniorChakri from './Screens/SchoolAdmissions/JuniorChakriCampus'
import AdmissionJuniorWestridge from './Screens/SchoolAdmissions/JuniorWestridgeCampus'
import AdmissionSeniorWestridge from './Screens/SchoolAdmissions/SeniorWestridgeCampus'
import AdmissionGolraMor from './Screens/SchoolAdmissions/GolraMorCampus'
import AdmissionPeshawarRoad from './Screens/SchoolAdmissions/PeshawarRoadCampus'
import AdmissionPWD from './Screens/SchoolAdmissions/PWDCampus'
import AdmissionAECHS from './Screens/SchoolAdmissions/AECHSCampus'
import AdmissionST from './Screens/CollegeAdmissions/STCampusAdmission'
import AdmissionF8 from './Screens/CollegeAdmissions/F8CampusAdmission'
import AdmissionRAR from './Screens/CollegeAdmissions/RARCampusAdmission'
import AdmissionCollege from './Screens/CollegeAdmissions/CollegeAdmissions'
import ErrorPage from './Screens/ErrorPage'
import ACCACollegeFeeStructure from './Screens/Admin/FeeStructure/College/ACCAFeeStructure'
import UpdateACCAFeeStructure from './Screens/Admin/FeeStructure/College/UpdateACCAFeeStructure'
import Logout from './Screens/Logout'

import Complaintbox from './Screens/Admin/Complaintbox/Complaintbox'
import ViewComplaint from './Screens/Admin/Complaintbox/ViewComplaint'
import PeshawarRoadCampusschool from './Screens/Schools/PeshawarRoadCampus';
import PeshawarRoadCampus from './Screens/Colleges/PeshawarRoadcampus';
import ACCAPeshawarRoadCalender from './Screens/Calender/ACCAPeshawarCalender';
import ACCAPeshawarRoadEvent from './Screens/Calender/Event/PeshawarRoadEvent';
import ACCAPeshawarRoadNotice from './Screens/Noticeboard/College/PeshawarRoadNoticeboard';
import ACCAPeshawarRoadFaculty from './Screens/Faculty/ACCAPeshawarRoadFaculty';
import ACCAFacultyPeshawarRoadAdmin from './Screens/Admin/Faculty/ACCAPeshawarRoadFaculty';
import UpdateACCAFacultyPeshawarRoad from './Screens/Admin/Faculty/UpdateACCAPeshawarRoad';
import AdmissionPeshawarRoadcollege from './Screens/CollegeAdmissions/PeshawarRoadcampusAdmission';
import CollegeTour from './Screens/CollegeTour';
import SchoolTour from './Screens/SchoolTour';





function App() {

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate data fetching (replace with your actual API call)
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      // initClassName: 'aos-init', // class applied after initialization
      // animatedClassName: 'aos-animate', // class applied on animation
      // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
    
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      // delay: 0, // values from 0 to 3000, with step 50ms
      duration: 3000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      // mirror: false, // whether elements should animate out while scrolling past them
      // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
    setTimeout(() => {
      // Assume you have fetched the data here
      setIsLoading(false);
    }, 2000); // Simulating a 2-second delay
  }, [AOS.init]);
  return (
    <>
   <Routes>
    <Route exact path="/" element={<Home />} />
    
    </Routes>
        {isLoading ? (
          <LoadingOverlay/>
      ) : ( 

        <Routes>
        {/* <Route path='/*' element={<ErrorPage />} /> */}

        <Route path="/AdminComplaintBox" element={<Complaintbox />} />
        <Route path="/ViewComplaint/:id" element={<ViewComplaint />} />



        <Route path="/AnnouncementDetail/:id" element={<AnnouncementDetail />} />

        <Route path="/AdminLogin" element={<Login />} />
        <Route path="/AdminLogout" element={<Logout />} />

        <Route path="/AdminDashboard" element={<Admindash />} />
        <Route path="/AdminProfile" element={<Profile />} />
        <Route path="/HomeScreen" element={<HomeScreen />} />
        <Route path="/UpdateScreenImage/:id" element={<UpdateScreenimage />} />
        <Route path="/HomeHighlights" element={<HomeHighlights />} />
        <Route path="/UpdateHighlight/:id" element={<UpdateHighlight />} />

        <Route path="/HomeAnnouncement" element={<Announcment />} />
        <Route path="/UpdateAnnouncment/:id" element={<UpdateAnnouncment />} />
        <Route path="/UpdateHighAchiever/:id" element={<UpdateHighAchiever />} />
        <Route path="/HomeHighAchiever" element={<HighAchiever />} />
        <Route path="/HomeCounter" element={<HomeCounter />} />
        <Route path="/UpdateCounter/:id" element={<UpdateCounter />} />


        <Route path="/AdminFeeStructure" element={<CollegeFeeStructure />} />
        <Route path="/UpdateFeeStructure/:id" element={<UpdateFeeStructure />} />
        <Route path="/AdminACCAFeeStructure" element={<ACCACollegeFeeStructure />} />
        <Route path="/UpdateACCAFeeStructure/:id" element={<UpdateACCAFeeStructure />} />


        <Route path="/CAFacultyF8" element={<CAFacultyF8 />} />
        <Route path="/UpdateCAFacultyF8/:id" element={<UpdateCAFacultyF8 />} />
        <Route path="/ACCAFacultyF8" element={<ACCAFacultyF8 />} />
        <Route path="/UpdateACCAFacultyF8/:id" element={<UpdateACCAFacultyF8 />} />
        <Route path="/CAFacultyST" element={<CAFacultyST />} />
        <Route path="/UpdateCAFacultyST/:id" element={<UpdateCAFacultyST />} />
        <Route path="/ACCAFacultyST" element={<ACCAFacultyST />} />
        <Route path="/UpdateACCAFacultyST/:id" element={<UpdateACCAFacultyST />} />
        <Route path="/CAFacultyRAR" element={<CAFacultyRAR />} />
        <Route path="/UpdateCAFacultyRAR/:id" element={<UpdateCAFacultyRAR />} />
        <Route path="/ACCAFacultyRAR" element={<ACCAFacultyRAR />} />
        <Route path="/UpdateACCAFacultyRAR/:id" element={<UpdateACCAFacultyRAR />} />
        <Route path="/test" element={<Test />} />
        <Route path="/UpdateACCAFacultyRAR/:id" element={<UpdateACCAFacultyRAR />} />
        <Route path="/ACCAFacultyPeshawarRoad" element={<ACCAFacultyPeshawarRoadAdmin />} />
        <Route path="/UpdateACCAFacultyPeshawarRoad/:id" element={<UpdateACCAFacultyPeshawarRoad />} />


        <Route path="/AdminlifeatSkans" element={<LifeatSkans />} />
        <Route path="/UpdateLifeatSkans/:id" element={<Updatelifeatskans />} />

        <Route path="/AdminF8CACalender" element={<F8CACalender />} />
        <Route path="/UpdateF8CACalender/:id" element={<UpdateF8CACalender />} />
        <Route path="/AdminSTCACalender" element={<STCACalender />} />
        <Route path="/UpdateSTCACalender/:id" element={<UpdateSTCACalender />} />
        <Route path="/AdminRARCACalender" element={<RARCACalender />} />
        <Route path="/UpdateRARCACalender/:id" element={<UpdateRARCACalender />} />


        <Route path="/AdminF8ACCACalender" element={<F8ACCACalender />} />
        <Route path="/UpdateF8ACCACalender/:id" element={<UpdateF8ACCACalender />} />
        <Route path="/AdminSTACCACalender" element={<STACCACalender />} />
        <Route path="/UpdateSTACCACalender/:id" element={<UpdateSTACCACalender />} />
        <Route path="/AdminRARACCACalender" element={<RARACCACalender />} />
        <Route path="/UpdateRARACCACalender/:id" element={<UpdateRARACCACalender />} />



        <Route path="/AdminCompletedEventF8" element={<CompletedEventF8 />} />
        <Route path="/UpdateCompletedEventF8/:id" element={<UpdateCompletedEventF8 />} />
        <Route path="/AdminCompletedEventST" element={<CompletedEventST />} />
        <Route path="/UpdateCompletedEventST/:id" element={<UpdateCompletedEventST />} />
        <Route path="/AdminCompletedEventRAR" element={<CompletedEventRAR />} />
        <Route path="/UpdateCompletedEventRAR/:id" element={<UpdateCompletedEventRAR />} />

        <Route path="/AdminUpcomingEventF8" element={<UpcomingEventF8 />} />
        <Route path="/UpdateupcomingEventF8/:id" element={<UpdateupcomingEventF8 />} />
        <Route path="/AdminUpcomingEventST" element={<UpcomingEventST />} />
        <Route path="/UpdateupcomingEventST/:id" element={<UpdateupcomingEventST/>} />
        <Route path="/AdminUpcomingEventRAR" element={<UpcomingEventRAR />} />
        <Route path="/UpdateupcomingEventRAR/:id" element={<UpdateupcomingEventRAR/>} />





        <Route path="/AdminCollegeNoticeBoard" element={<CollegeNoticeBoard />} />
        <Route path="/UpdateCollegeNoticeboard/:id" element={<UpdateCollegeNoticeboard />} />
        <Route path="/AdminSchoolNoticeBoard" element={<SchoolNoticeBoard />} />
        <Route path="/UpdateSchoolNoticeboard/:id" element={<UpdateSchoolNoticeboard />} />

        <Route path="/AdminHighAchievers" element={<HighAchievers />} />
        <Route path="/UpdateHighAchievers/:id" element={<UpdateHighAchivers />} />

























        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/CAProgram" element={<CAPrograms />} />
        <Route path="/ACCAProgram" element={<ACCAProgram />} />
        <Route path="/Curriculum" element={<Cirriculum />} />
        <Route path="/ParentsCorner" element={<ParentsCorner />} />
        <Route path="/Facilities" element={<Facilities />} />
        <Route path="/ContactUs" element={<ContactUs />} />


        <Route path="/JuniorHarleyCampus" element={<JuniorHarley />} />
        <Route path="/GulzareQuaidCampus" element={<GulzareQuaid />} />
        <Route path="/JuniorChakriRoad" element={<JuniorChakriRoad />} />
        <Route path="/GolraMorCampus" element={<GolraMorCampus />} />
        <Route path="/JuniorWestridgeCampus" element={<WestridgeCampus />} />
        <Route path="/PeshawarRoadCampus" element={<PeshawarRoadCampusschool />} />
        <Route path="/PWDCampus" element={<PWDCampus />} />
        <Route path="/SeniorWestridgeCampus" element={<SeniorWestridgeCampus />} />
        <Route path="/AirportCampus" element={<AirportCampus />} />



        <Route path="/F8Campus" element={<F8Campus />} />
        <Route path="/RARCampus" element={<RARCampus />} />
        <Route path="/SatelliteTownCampus" element={<SatelliteTownCampus />} />
        <Route path="/ACCAPeshawarRoadCampus" element={<PeshawarRoadCampus />} />
        <Route path="/CollegeVirtualTour" element={<CollegeTour />} />
        <Route path="/SchoolVirtualTour" element={<SchoolTour />} />













        <Route path="/CBEdates" element={<CBEDates />} />


        <Route path="/CASatelliteTownCalender" element={<CARawalpindiCalender />} />
        <Route path="/CAIsalamabadCalender" element={<CAIslamabadCalender />} />
        <Route path="/ACCASatelliteTownCalender" element={<ACCARawalpindiCalender />} />
        <Route path="/ACCAIslamabadCalender" element={<ACCAIslamabadCalender />} />
        <Route path="/CA_RARCalender" element={<CA_RARCalender />} />
        <Route path="/ACCA_RARCalender" element={<ACCA_RARCalender />} />
        <Route path="/ACCAPeshawarRoadCalender" element={<ACCAPeshawarRoadCalender />} />



        <Route path="/EventIslamabad" element={<ACCAEventIslamabad />} />
        <Route path="/EventSatellitetown" element={<EventSatelliteTown />} />

        <Route path="/EventRAR" element={<EventRAR />} />
        <Route path="/EventPeshawarRoad" element={<ACCAPeshawarRoadEvent />} />




        <Route path="/F8IslamabadNoticeBoard" element={<F8IslamabadNotice />} />
        <Route path="/SatellitetownNoticeBoard" element={<STNoticeboard />} />
        <Route path="/RARNoticeBoard" element={<RARNoticeboard />} />
        <Route path="/ACCAPeshawarRoadNoticeBoard" element={<ACCAPeshawarRoadNotice />} />





        <Route path="/HarleyStreetNoticeBoard" element={<JuniorHarleyNotice />} />
        <Route path="/AECHSNoticeBoard" element={<AECHSNoticeboard />} />
        <Route path="/GulzareQuaidNoticeBoard" element={<GulzareQuaidNoticeboard />} />
        <Route path="/JuniorChakriRoadNoticeboard" element={<JuniorChakriNoticeboard />} />
        <Route path="/SeniorWestridgeNoticeboard" element={<SeniorWestridgeNoticeboard />} />
        <Route path="/JuniorWestridgeNoticeboard" element={<JuniorWestridgeNoticeboard />} />
        <Route path="/GolraMorNoticeboard" element={<GolraMorNoticeboard />} />
        <Route path="/PeshawarRoadNoticeboard" element={<PeshawarRoadNoticeboard />} />
        <Route path="/PWDNoticeboard" element={<PWDNoticeboard />} />










        <Route path="/CAFeeStructure" element={<CAFeeStructure />} />
        <Route path="/ACCAFeeStructure" element={<ACCAFeeStructure />} />



        <Route path="/AdmissionSchool" element={<AdmissionSchool />} />
        <Route path="/HarleyStreetCampusAdmission" element={<AdmissionDetailSchool />} />
        <Route path="/GulzareQuiadCampusAdmission" element={<AdmissionGulzareQuaid />} />
        <Route path="/ChakriRoadCampusAdmission" element={<AdmissionJuniorChakri />} />
        <Route path="/JuniorWestridgeCampusAdmission" element={<AdmissionJuniorWestridge />} />
        <Route path="/SeniorWestridgeCampusAdmission" element={<AdmissionSeniorWestridge />} />
        <Route path="/GolraMorCampusAdmission" element={<AdmissionGolraMor />} />
        <Route path="/PeshawarRoadCampusAdmission" element={<AdmissionPeshawarRoad />} />
        <Route path="/PWDCampusAdmission" element={<AdmissionPWD />} />
        <Route path="/AECHSCampusAdmission" element={<AdmissionAECHS/>} />



        <Route path="/CollegeAdmission" element={<AdmissionCollege/>} />

        <Route path="/STCampusAdmission" element={<AdmissionST/>} />
        <Route path="/F8CampusAdmission" element={<AdmissionF8/>} />
        <Route path="/RARCampusAdmission" element={<AdmissionRAR/>} />
        <Route path="/PeshawarRoadCampusAdmissioncollege" element={<AdmissionPeshawarRoadcollege/>} />


















        <Route path="/MeritStudents" element={<MeritStudents />} />



        <Route path="/CAF8Faculty" element={<CAIslamabadFaculty />} />
        <Route path="/ACCAF8Faculty" element={<ACCAF8Faculty />} />
        <Route path="/CA_STFaculty" element={<CA_STFaculty />} />
        <Route path="/ACCA_STFaculty" element={<ACCA_STFaculty />} />
        <Route path="/CA_RARFaculty" element={<CA_RARFaculty />} />
        <Route path="/ACCA_RARFaculty" element={<ACCA_RARFaculty />} />
        <Route path="/ACCAPeshawarRoadFaculty" element={<ACCAPeshawarRoadFaculty />} />












        <Route path="/SkansGallery" element={<MasonryGallery />} />





        <Route path="/HowtouseStudentportal" element={<HowtouseStudentportal />} />
        <Route path="/Applyonline" element={<Applyonline />} />











        </Routes>


      
      ) 
       } 
     
    </>
  )
}

export default App
