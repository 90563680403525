import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function HomeCounter() {
    const navigate = useNavigate();

    const [counter, setCounter] = useState({
        counter1: null,
        counter2: null,
        counter3: null,
        counter4:null,
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setCounter({ ...counter, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/homeCounter`, counter);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setCounter({
                counter1: null,
                counter2: null,
                counter3: null,
                counter4:null,
            }); // Clear the input fields after successful upload
            callProfile();
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomecounter`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          setCounter(data);

        })
        .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            // navigate("/AdminLogin");
        }
    };

    const deleteCounter = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteHomeCounter/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Counter Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="my-5">
                <h1 className="fw-bold text-center fontcolordark">Home Counter</h1>

            </div>
            <div className="">
                <form method="PATCH" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="counter1"
                                type="number"
                                id="counter1"
                                className="form-control"
                                placeholder="Enter counter1 here"
                                value={counter.counter1}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="counter2"
                                type="number"
                                id="counter2"
                                className="form-control"
                                placeholder="Enter counter2 here"
                                value={counter.counter2}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right  mt-3">
                            <input
                                name="counter3"
                                type="number"
                                id="counter3"
                                className="form-control"
                                placeholder="Enter counter3 here"
                                value={counter.counter3}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-5 col-12 border-right  mt-3">
                            <input
                                name="counter4"
                                type="number"
                                id="counter4"
                                className="form-control"
                                placeholder="Enter counter4 here"
                                value={counter.counter4}
                                onChange={handleInput}
                                required
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Counter
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">


                <table class="table2">
                        <tr class="tr2">
                            <th class="th2 p-sm-2 p-1">Counter1 </th>
                            <th class="th2 p-sm-2 p-1">Counter2</th>
                            <th class="th2 p-sm-2 p-1">Counter3</th>
                            <th  class="th2 p-sm-2 p-1">Counter4</th>
                            <th class="th2 p-sm-2 p-1">Action</th>

                        </tr>
                        {Array.isArray(counter) && counter.length > 0 ? (
                                counter.map((ann, id) => (

                                <tr class="tr2" key={id}>
                                    <td class="td2  p-sm-2 p-1 ">{ann.counter1}</td>
                                    <td class="td2 p-sm-2 p-1">{ann.counter2}</td>
                                    <td class="td2  p-sm-2 p-1 ">{ann.counter3}</td>
                                    <td class="td2 p-sm-2 p-1">{ann.counter4}</td>
                                    
                                   

                                    <td class="td2 d-flex justify-content-start text-center">

                                        <Link to={`/UpdateCounter/${ann.id}`} ><i class="bi bi-pencil-square fs-5 text-primary fw-bold"></i></Link>
                                        <Link className="ms-sm-4 ms-1" onClick={() => deleteCounter(ann.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>

                                    </td>

                                </tr>
                            ))
                        ) : (
                            <p>Not Available</p>
                        )}
                    </table>





                    {/* <div className="shadow-lg">
                        <div className="p-2">
                            {Array.isArray(counter) && counter.length > 0 ? (
                                counter.map((ann, id) => (
                                    <li key={id} className="d-flex border rounded-2 justify-content-between shadow-sm mt-3 px-4 py-2">
                                        <div className="col-9">
                                            <span className="d-flex"><span className="fw-bold pe-3">{id + 1}.</span><p>{ann.counter1}</p><p className="ps-3">{ann.counter2}</p><p className="ps-3">{ann.counter3}</p><p className="ps-3">{ann.counter4}</p></span>
                                        </div>
                                        <div className="d-flex justify-content-end col-1">
                                            <Link to={`/UpdateCounter/${ann._id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                            <Link className="ms-4" onClick={() => deleteCounter(ann._id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <p>Not Available</p>
                            )}
                        </div>
                        <div></div>
                    </div> */}
                </form>
            </div>
        </>
    );
}

export default HomeCounter;
