import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { backendURL } from "../../../../constant";

function ACCACollegeFeeStructure() {
    const navigate = useNavigate();

    const [announcement, setAnnouncement] = useState({
        paper: "",
        part: "",
        tuition_fee: "",
        cbe_fee: ""
    });

    const [detail, setDetail] = useState([]);
    
    // Remove the search state
    // const [search, setSearch] = useState({ type: "" });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncement({ ...announcement, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendURL}/AdminACCAFeeStructure`, announcement);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAnnouncement({
                paper: "",
                part: "",
                tuition_fee: "",
                cbe_fee: ""
            }); // Clear the input fields after successful upload
            callProfile();
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getACCAFeeStructure`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                console.log("fee", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);
      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            // navigate("/AdminLogin");
        }
    };

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/deleteACCAFeeStructure/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Batch Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-center text-uppercase mb-5 fontcolordark">ACCA Fee Structure</h1>
                    </div>
                </div>
                <form method="PATCH" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="paper"
                                type="text"
                                id="paper"
                                className="form-control"
                                placeholder="Enter paper text here"
                                value={announcement.paper}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="part"
                                type="text"
                                id="part"
                                className="form-control"
                                placeholder="Enter part here"
                                value={announcement.part}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="tuition_fee"
                                type="number"
                                id="tuition_fee"
                                className="form-control"
                                placeholder="Enter Tuition Fee here"
                                value={announcement.tuition_fee}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-5 col-12 border-right mt-3">
                            <input
                                name="cbe_fee"
                                type="number"
                                id="cbe_fee"
                                className="form-control"
                                placeholder="Enter cbe Fee here"
                                value={announcement.cbe_fee}
                                onChange={handleInput}
                                required
                            />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Fee Structure
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container-md container-fluid ps-0">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        <div className=' mt-5' >
                            <table class="table2 mb-5">
                                <tr class="tr2">
                                    <th className="th2 p-sm-2 p-1">Paper</th>
                                    <th className="th2 p-sm-2 p-1">Part</th>
                                    <th class="th2 p-sm-2 p-1">Tuition Fee</th>
                                    <th class="th2 p-sm-2 p-1">CBE Fee</th>
                                    <th class="th2 p-sm-2 p-1">Action</th>
                                </tr>
                                {Array.isArray(detail) && detail.length > 0 ? (
                                    detail.map((ann, id) => (
                                        <tr class="tr2" key={id}>
                                            <td className="td2 p-sm-2 p-1 fw-bold">{ann.paper}</td>
                                            <td className="td2 p-sm-2 p-1">{ann.part}</td>
                                            <td class="td2 p-lg-2 p-1">{ann.tuition_fee}</td>
                                            <td class="td2 p-lg-2 p-1">{ann.cbe_fee}</td>
                                            <td class="th2 d-flex justify-content-start">
                                                <Link to={`/UpdateACCAFeeStructure/${ann.id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                                <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                            </td>
                                        </tr>
                                    )
                                    )
                                ) : (
                                    <p>Not Available</p>
                                )}
                            </table>
                        </div>
                        <div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default ACCACollegeFeeStructure;
