import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function CAFacultyST() {
    const navigate = useNavigate();

    const [faculty, setFaculty] = useState({
        photos: "",
        name: "",
        qualification: "",
        specialization: "",
        experience: "",
        designation: "",

    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFaculty({ ...faculty, [name]: value });
    };
    const handlePhotoChange = (e) => {
        setFaculty({ ...faculty, photos: e.target.files[0] });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("photos", faculty.photos);
            formData.append("name", faculty.name);
            formData.append("qualification", faculty.qualification);
            formData.append("specialization", faculty.specialization);
            formData.append("experience", faculty.experience);
            formData.append("designation", faculty.designation);
      
            const response = await axios.post(`${backendURL}/CAFacultyST`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setFaculty({
                photos: "",
                name: "",
                qualification: "",
                specialization: "",
                experience: "",
                designation: "",
            }); // Clear the input fields after successful upload
            setTimeout(() => {
                navigate("/CAFacultyST");
            }, 100);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getCAFacultyST`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setFaculty(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    };

    const deleteFaculty = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteCAFacultyST/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Member Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, []);
    const picurl = `${backendURL}/public/uploads/`;
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <h1 className="text-center fontcolordark fw-bold">CA Faculty ST</h1>
                <form method="POST" className="pt-3" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">

                        <div className="col-md-5 border-right">
                            <input
                                name="photos"
                                id="photos"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handlePhotoChange}
                                
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="name"
                                type="text"
                                id="name"
                                className="form-control"
                                placeholder="Enter name here"
                                value={faculty.name}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="qualification"
                                type="text"
                                id="qualification"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Qualification here"
                                value={faculty.qualification}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="specialization"
                                type="text"
                                id="specialization"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Specialization here"
                                value={faculty.specialization}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="experience"
                                type="text"
                                id="experience"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Experience here"
                                value={faculty.experience}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                        <div className="col-md-8 col-12 border-right mt-3">
                            <textarea
                                name="designation"
                                type="text"
                                id="designation"
                                rows="2"
                                className="form-control"
                                placeholder="Enter Designation here"
                                value={faculty.designation}
                                onChange={handleInput}
                                required
                            ></textarea>
                        </div>

                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Member
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        {Array.isArray(faculty) && faculty.length > 0 ? (
                            <table className="table2">
                                
                                    <tr className="tr2">
                                        <th  className="th2 p-sm-2 p-1">#</th>
                                        <th className="th2 p-sm-2 p-1">Photo</th>
                                        <th className="th2 p-sm-2 p-1">Name</th>
                                        <th className="th2 p-sm-2 p-1">Action</th>
                                    </tr>
                                
                                    {faculty.map((ann, id) => (
                                        <tr key={id} className="tr2">
                                            <td className="td2 p-sm-2 p-1">{id + 1}</td>
                                            <td className="td2 p-sm-2 p-1">
                                            {ann.photos? (<img className='rounded-circle img-fluid' width="100"  src={picurl + ann.photos}></img>):(<img className='rounded-circle img-fluid' width="100"  src="https://www.pngmart.com/files/22/User-Avatar-Profile-PNG-Isolated-Transparent-Picture.png"></img>)}
                                            </td>
                                            <td className="td2 p-sm-2 p-1">{ann.name}</td>
                                            <td className="td2 p-sm-2 p-1">
                                                <Link to={`/UpdateCAFacultyST/${ann.id}`} className=" me-3 ">
                                                <i className="bi bi-pencil-square fs-5 text-primary"></i>
                                                </Link>
                                                <Link
                                                    onClick={() => deleteFaculty(ann.id)}
                                                    className=""
                                                >
                                                    <i className="bi bi-trash fs-5 text-danger"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                
                            </table>
                        ) : (
                            <p>Not Available</p>
                        )}
                    </div>


                </form>
            </div>
        </>
    );
}

export default CAFacultyST;
