import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../Sidebar';
import { backendURL } from '../../../../constant';


function UpdateACCAFeeStructure() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Announcment, setAnnouncment] = useState({
        paper: "",
        part: "",
        tuition_fee: "",
        cbe_fee:""
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncment({ ...Announcment, [name]: value });
    };

    const callIndAnnouncement = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewACCAFeeStructure/${id.trim()}`);
            const announcementData = response.data;
            setAnnouncment(announcementData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndAnnouncement();
    }, [callIndAnnouncement]);

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/updateACCAFeeStructure/${id.trim()}`, Announcment);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });

            setTimeout(() => {
                navigate("/AdminACCAFeeStructure");
            }, 1000);
        } catch (error) {
            console.error(error);
            toast.error("Failed to Update Fee Structure", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center fontcolordark"> Update ACCA FEE STRUCTURE </h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-md-5 ms-0 pb-1 col-md-11 shadow-lg mb-4">
                    <form method='PATCH' onSubmit={handleDataUpload} encType="multipart/form-data">
                        <div className="container d-flex flex-wrap justify-content-around pt-5">
                            <div className="col-md-5 col-12 border-right">
                                <input
                                    name="paper"
                                    type="text"
                                    id="paper"
                                    className="form-control"
                                    placeholder="Enter paper here"
                                    value={Announcment.paper}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="part"
                                    type="text"
                                    id="part"
                                    className="form-control"
                                    placeholder="Enter part here"
                                    value={Announcment.part}
                                    onChange={handleInput}
                                    required
                                />
                            </div>

                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="tuition_fee"
                                    type="number"
                                    id="tuition_fee"
                                    className="form-control"
                                    placeholder="Enter tuition_fee here"
                                    value={Announcment.tuition_fee}
                                    onChange={handleInput}
                                    required
                                />
                            </div>

                            <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                                <input
                                    name="cbe_fee"
                                    type="number"
                                    id="cbe_fee"
                                    className="form-control"
                                    placeholder="Enter cbe_fee here"
                                    value={Announcment.cbe_fee}
                                    onChange={handleInput}
                                    required
                                />
                            </div>
                            
                            {/* <div class="input-group mb-3">
                            <label class="input-group-text" for="inputGroupSelect01">Program</label>
                            <select name="type" class="form-select" id="inputGroupSelect01" value={Announcment.type}
                                onChange={handleInput}>
                                <option >Choose...</option>
                                <option value="CA Fee">CA Fee</option>
                                <option value="ACCA Fee">ACCA Fee</option>
                                

                            </select>
                        </div> */}
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary profile-button">
                                Update FeeStructure
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateACCAFeeStructure;
