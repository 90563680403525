import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { backendURL } from "../../../constant";

function HomeHighlights() {
    const navigate = useNavigate();

    const [highlight, setHighlight] = useState("");

    const handleInput = (e) => {
        const { name, value } = e.target;
        setHighlight({ ...highlight, [name]: value });
    };



    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${backendURL}/homehighlight`, { highlight });
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setHighlight("");
             // Clear the input field after successful upload
             callProfile();

        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

// const res = await fetch("/getHomeHighlight", {
    const callProfile = async () => {
        try {

            const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      
      
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      
        redirect: "follow"
      };
      
      fetch(`${backendURL}/getHomeHighlight`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("HomeHighlight", result);
      
          const data = JSON.parse(result);
          console.log(data);
          setHighlight(data);
        
      
        })
        .catch((error) => console.error(error));
            
            
        } catch (err) {
            console.log(err);
            // navigate("/AdminLogin");
        }
    };



    const DeleteHighlight = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/DeleteHomeHighlight/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedata = await res2.json();
            console.log(deletedata);

            if (res2.status === 422 || !deletedata) {
                console.log("error");
            } else {
                toast.success("Highlight Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        callProfile();
    }, []);
    //   const picurl = "http://localhost:5000/public/uploads/";

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="my-5">
                <h1 className="fw-bold text-center fontcolordark">Home Highlights</h1>

            </div>
            <div className="">
                <h2 className="text-center mb-5">Highlights</h2>
                <form method="PATCH" className="d-flex justify-content-around align-items-center" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="col-md-4 border-right">
                        <textarea
                            name="highlight"
                            type="text"
                            id="highlight"
                            rows="4" // You can adjust the number of rows as needed
                            className="form-control"
                            placeholder="Enter or paste text here"
                            // value={highlight}
                            onChange={handleInput}
                            required
                        ></textarea>
                    </div>

                    <div className="text-center">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload Highlight
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container">
                <form className="" method="GET">
                    <div className="shadow-lg">
                        <div className="p-2">
                            {
                                Array.isArray(highlight) && highlight.length > 0
                                    ? highlight.map((high, id) => (
                                        <li key={id} className="d-flex border rounded-2 justify-content-between shadow-sm mt-3 px-4 py-2">
                                            <div className="col-9 d-flex">
                                                <span className="fw-bold">{id + 1}.</span> <p className="mb-0">{high.highlight}</p>
                                            </div>
                                            <div className="d-flex justify-content-end col-1">
                                                <Link to={`/UpdateHighlight/${high.id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                                <Link className="ms-4" onClick={() => DeleteHighlight(high.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                            </div>
                                        </li>
                                    )) : <p>Not Available</p>}
                        </div>
                        <div></div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default HomeHighlights;
