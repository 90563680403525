import React from 'react'
import Navbar from '../Components/navbar';
import Sectiontitle1 from "../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import English from "../Photos/eng.png"
import Urdu from "../Photos/urdu.png"
import Science from "../Photos/Science.png"
import SocialStudies from "../Photos/SocialStudies.png"
import Mathematics from "../Photos/mathematics.png"
import Geography from "../Photos/geography.png"
import History from "../Photos/history.png"
import Computer from "../Photos/computer.png"
import Arts from "../Photos/art.png"
import { currentYear } from '../constant';
import SchoolNavbar from '../Components/SchoolNavbar';





function Cirriculum() {
    return (
        <>
            <SchoolNavbar />
            <div className='bg-lightgrey'>

                <div className='programbgSchool overflow-hidden position-relative'>
                    <div className=' container-fluid text-light filter13 text-center'>
                    <p >Home / Curriculum</p><br></br>
                    <h1 className='fw-bold d-md-block d-none text-uppercase'>Curriculum</h1>
                    <h3 className='fw-bold d-md-none d-block fs-2 text-uppercase'>Curriculum</h3>

                </div>
                </div>

                <div className='container lh-lg text-justify'>
                    <div className='d-flex align-items-center ' data-aos="fade-up">
                        <div className='b'>

                        </div>
                        <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-sm-5 px-3'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0 text-uppercase d-md-block d-none'>Curriculum</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                            <h5 className='text-light fw-bold mb-0 text-uppercase d-md-none d-block'>Curriculum</h5>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>
                    <p data-aos="fade-up">We are a cooperative academy with an ethos that promotes excellence for all. Everything we do is underpinned by cooperative values that guides and develops our students through their education to become successful, responsible and confident adults.</p>
                    <p data-aos="fade-up">Our reputation is one of the very high academic standards and outstanding pupil conduct. At SKANS our teachers promote creativity and individuality, enabling pupils to shine through in many areas.</p>
                    <p data-aos="fade-up">The curriculum is designed to equip students with the skill to enquire, challenge and be creative during their lessons.</p>
                    <p data-aos="fade-up">We have a creative curriculum which is inspired by a theme. Our curriculum includes a wide range of planned and informal learning experiences, driven by the ideas of staff and children opportunities both inside and outside the classroom.</p>
                    <p data-aos="fade-up">At SKANS School System SSS we believe that education should be exciting, innovation and active. This means we use a variety of teaching and learning styles. Child will also be given many opportunities for physical activity. Educational visits and visitors support the creative curriculum your child studies. Such experiences fix learning in a child’s memory. Our aim is to develop every child’s self-esteem, social skills and academic potential.</p>
                    <h4 className='fontcolordark fw-bold' data-aos="fade-up">EARLY YEARS</h4>
                    <p data-aos="fade-up">The early year’s curriculum that the children work on is called the Foundation Stage. The eight areas that the Foundation cover are:</p>

                    <ul className='list-unstyled ' data-aos="fade-up">
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Communication and Language
                                </div>
                            </div>
                        </li>
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Personal and Social Development
                                </div>
                            </div>
                        </li>
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Physical Development
                                </div>
                            </div>
                        </li>
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Expressive Arts and Designs
                                </div>
                            </div>
                        </li>
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Knowledge and Understanding of the World
                                </div>
                            </div>
                        </li>
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Mathematical Development
                                </div>
                            </div>
                        </li>
                        <li className='px-lg-5 px-1'>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Islamic Knowledge
                                </div>
                            </div>
                        </li>


                    </ul>
                    <p data-aos="fade-up">The curriculum is taught through a balance of free and guided, practical investigation and sometimes recorded work. As the foundation year progresses children begin to work towards a more structured English and Math lessons.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">English</h4>
                    <p data-aos="fade-up">English is not only one of the core subjects, but also essential to all areas of learning undertaken in the primary school. Throughout the school day we use a variety of methods and resources to teach the elements of reading and writing. Daily opportunities also develop the skills of speaking and listening through such activities as class discussion, paired talk, drama and circle time.</p>
                    <p data-aos="fade-up">In reading emphasis is placed on encouraging the skills of phonic knowledge word recognition and contextual understanding as well as reading for pleasure. Parents can play a huge supporting role in developing their child’s reading through at home on a regular basis.</p>
                    <p data-aos="fade-up">In writing, children are encouraged to write for a variety of audiences and purpose. Spelling, grammar, punctuation and creative writing skills are developed through a range of stimulating activities. Children are also taught how to improve the quality of their work.</p>
                    <p data-aos="fade-up">Our overall aim is enable children to develop the essential skills necessary to access the whole curriculum confidently.</p>

                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">Mathematics</h4>
                    <p data-aos="fade-up">Math is a core subject allowing children to have a greater understanding of their work and has a direct relevance to everyday life. Skills and knowledge learned are used across the curriculum.</p>
                    <p data-aos="fade-up">Children learn to apply skills and solve problems in all areas of math. They are given opportunities to use and apply math in practical tasks and real-life problems. They are encouraged to explain their thinking to support the development of their reasoning.</p>
                    <p data-aos="fade-up">Teachers draw upon a range of resources and teaching styles to deliver our curriculum, an emphasis is given to developing methods of solving calculation problems. Homework will be set on to rein enforce current topics and to encourage students to be fluent and confident with number bonds and tables.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">Science</h4>
                    <p data-aos="fade-up">Science at SKANS Junior School System ,SJS is concerned with the study of living and non-living world. Children are taught to question why things happen and why things are like they are.  They use a wide range of skills, which enable them to investigate questions, find answers and discuss results. Children learn by doing. They are actively encourage to talk through their ideas, plans their method, carry out their inquiry and evaluate their work.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">Computing</h4>
                    <p data-aos="fade-up">Computing is taught as a distinct subject and as a tool to be used appropriately throughout the curriculum to support and enrich children’s learning.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">Art</h4>
                    <p data-aos="fade-up">All children have the opportunity to experience a wide range of art. Children will have experience including printing, drawing, and collage work. Children’s work is valued and displayed around classroom. In lesson times the children have opportunity to look at examples from various artists to find out about techniques and style. Children are involved in activities and projects to enhance the school environment.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">GEOGRAPHY AND HISTORY</h4>
                    <p data-aos="fade-up">Our geography teaching develops a broad range of geographical understanding and skill. We study physical, human and environmental geography, broadening the knowledge and understanding of places by looking for similarities and differences. Our local area is well used resource.</p>
                    <p data-aos="fade-up">We provide an exciting and varied history program to ensure the children and promote an interest in the past. Children are taught the skills that we will enable them to discover facts and information for themselves and to begin to understand the present by looking at the past. We use a number of approaches to teach History. These include inviting visitors into school, going in visits to places of interest, DVDs, artifacts, activity days, books and internet.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">PHYSICAL EDUCATION</h4>
                    <p data-aos="fade-up">Children participate in a wide variety of activities in their PR lessons. Within PE we teach the children the value of participating in physical activity at school and throughout life. Extra-curricular activities are available and sporting fixtures take place. Children should have appropriate clothing for indoor and outdoor PE.</p>
                    <h4 className='fontcolordark fw-bold text-uppercase' data-aos="fade-up">Grades Curriculum</h4>
                    <p data-aos="fade-up">Curriculum is a framework that sets expectations for student learning. It serves as a guide for teachers; it is a road-map that establishes standards for student performance and teacher accountability. SKANS is working following activity based and student centered curriculum.</p>

                    <div>
                        <ul data-aos="fade-up" className=' lh-lg list-unstyled text-center d-flex flex-wrap align-items-center justify-content-around'>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mt-3' data-aos="fade-up">
                                {/* <div className='enghover' >

                                </div> */}
                                <img className='py-3' src={English} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0'>ENGLISH</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10  borderblue rounded mx-3 mt-3' data-aos="fade-up">
                                <img className='py-3' src={Urdu} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0'>URDU</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mt-3' data-aos="fade-up">
                                <img className='py-3' src={Science} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0'>SCIENCE</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mx-3 mt-3' data-aos="fade-up">
                                <img className='py-3' src={SocialStudies} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0 text-uppercase'>Social Studies</p>
                            </li>



                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mt-3' data-aos="fade-up">
                                <img className='py-3' src={Mathematics} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0 text-uppercase'>Mathematics</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mx-3 mt-3' data-aos="fade-up">
                                <img className='py-3' src={History} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0 text-uppercase'>History</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mx-3 mt-3' data-aos="fade-up">
                                <img className='py-3' src={Geography} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0 text-uppercase'>Geography</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mx-3 mt-3' data-aos="fade-up">
                                <img className='py-3' src={Computer} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0 text-uppercase'>Computer</p>
                            </li>
                            <li className='col-lg-2 col-md-6 col-10 borderblue rounded mx-3 mt-3' data-aos="fade-up">
                                <img className='py-3' src={Arts} width={"70%"}></img>
                                <p className='fw-bold fs-5 py-1 bgdarkblue text-white mb-0 text-uppercase'>Arts</p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div className='bgdarkblue py-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                    © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
        </>
    )
}

export default Cirriculum
