import React from 'react';
import Navbar from '../../Components/navbar';
import '../../CSS/Applyonline.css'; // Import your CSS file
import applyonline from "../../Photos/about-img.png"

function Applyonline() {
    const buttonText1 = 'Existing Student';
    const buttonText2 = 'New Student';
    const buttonText3 = 'CBE Outsider Student';

    const mapTextWithSpacing = (text) => {
        return text.split('').map((char, index) => (
            <span key={index} className="text-with-spacing">
                {char === ' ' ? '\u00A0' : char}
            </span>
        ));
    };

    return (
        <>
            <Navbar />
            <div className='applyonlinebg'>
                <div className='container-fluid  ps-5 text-light filterapplyonline text-center'>
                    <p className='fs-5'>Home / Apply Online</p><br></br>
                    <h1 className='fw-bold'>APPLY ONLINE</h1>

                </div>


            </div>
            <div className='fact overflow-hidden pt-3'>
               <div className='d-flex container px-md-1 px-0 container-fluid justify-content-around flex-wrap mt-3'>
                    <a href="http://58.65.172.36/onlineadmissions/existingstudents.aspx" className="btnunique button col-3" data-aos="fade-up">
                        <div className='d-flex'>
                            <div>
                                {mapTextWithSpacing(buttonText1)}
                            </div>&nbsp;&nbsp;&nbsp;
                            <div className='btnhidden d-md-block d-none'><span><i class="fs-2 bi bi-person-fill"></i></span></div>
                            <div className='btnhidden d-md-none d-block'><span><i class="fs-5 bi bi-person-fill"></i></span></div>

                        </div>

                    </a>
                    <a href="http://58.65.172.36/onlineadmissions/default.aspx" className="btnunique button col-3 mt-md-0 mt-3" data-aos="fade-up">
                        <div className='d-flex'>
                            <div>
                                {mapTextWithSpacing(buttonText2)}
                            </div>&nbsp;&nbsp;&nbsp;
                            <div className='btnhidden'><span><i class="fs-2 bi bi-person-fill"></i></span></div>
                        </div>

                    </a>
                    <a href="http://58.65.172.36/cbeoutsiders/default.aspx" className="btnunique button col-3 mt-3" data-aos="fade-up">
                        <div className='d-flex'>
                            <div>
                                {mapTextWithSpacing(buttonText3)}
                            </div>&nbsp;&nbsp;&nbsp;
                            <div className='btnhidden'><span><i class="fs-2 bi bi-person-fill"></i></span></div>
                        </div>

                    </a>
                </div> 
                
                <div className='d-flex flex-wrap container px-md-1 px-0 lh-sm justify-content-around align-items-center mt-5' data-aos="fade-up">
                    <div className='text-justify col-lg-7 lh-lg col-11' data-aos="fade-right">
                        <p className='pe-md-5 pe-0 fw-bold fs-6 opacity-75'>
                        At Skans School of Accountancy, we're dedicated to empowering individuals to shape their own success stories. Our online application is your gateway to a world of opportunities, offering expertly designed educational programs that will equip you with the knowledge and skills needed to excel in the competitive field of finance and accounting. We're not just about education; we're about transformation. Within our vibrant community, you'll find like-minded peers, passionate educators, and abundant resources to help you reach your full potential.<br></br>

Choosing Skans School of Accountancy means choosing a partner on your journey toward a prosperous future. Join us, and together, we'll mark your career on the pinnacle of achievement, ensuring your success is not just a destination but a fulfilling journey. Your success story begins here as you unlock the doors to a successful future and embark on a path of growth and opportunity.
                        </p>
                    </div>
                    <div className='text-center col-lg-5 col-11' data-aos="flip-right">
                        <img className='img-fluid'   src={applyonline}></img>
                    </div>

                </div>

                

            </div>



            {/* <div class="section tabbar_menu">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="tab_menu">
          <ul>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i1.png" alt="website template image"/></span><span>University Life</span></a></li>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i2.png" alt="website template image"/></span><span>Graduation</span></a></li>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i3.png" alt="website template image"/></span><span>Athletics</span></a></li>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i4.png" alt="website template image"/></span><span>Social</span></a></li>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i5.png" alt="website template image"/></span><span>Location</span></a></li>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i6.png" alt="website template image"/></span><span>Call us</span></a></li>
            <li><a className='anchortag1' href="https://www.free-css.com/free-css-templates"><span class="icon"><img src="assets/images/i7.png" alt="website template image"/></span><span>Email</span></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> */}
        </>
    );
}

export default Applyonline;
