import React from 'react'
import Navbar from '../Components/navbar'
import "../CSS/ContactUs.css"
import contactuspic from "../Photos/contactuspic.jpg"
import { currentYear } from '../constant'
import SchoolNavbar from '../Components/SchoolNavbar'

function ContactUs() {
    return (
        <>
            <SchoolNavbar />
            <div className='ContactUsbg'>
                <div className='container-fluid  ps-5 text-light filter4 '>
                    <p className='fs-5'>Home / Contact Us</p><br></br>
                    <h1 className='fw-bold'>CONTACT US</h1>

                </div>


            </div>


            <div className='container lh-lg mt-3'>
                <div className='d-flex flex-wrap align-items-center justify-content-around' data-aos="fade-up">
                    <div className='col-md-6 col-12' data-aos="fade-right">
                        <p className='fw-bold opacity-75'>Contact your desired SKANS school system campus to get required information or to solve your query. You can also leave us a message via contact form below. Our team will get back to you in next 24 hours.</p>
                    </div>
                    <div className='col-md-6 col-12' data-aos="flip-right">
                        <img src={contactuspic} className='img-fluid'></img>
                    </div>

                </div>

                <div className='container  rounded-4'>
                    <div className='d-flex flex-wrap mt-5  mb-5 ' data-aos="fade-up">
                        <div className='d-flex flex-column  col-12 col-lg-6 bglightblue p-4 rounded-start-4'>
                            <p className='fs-4 text-center text-white fw-bold text-uppercase mb-2'>Junior Campus Harley</p>
                            {/* <div className='border border-white p-1 rounded-5'> */}

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>


                                <i class="bi bi-envelope-plus-fill fs-2 ps-3 me-md-5 me-2"></i>



                                <div className=' text-center'>
                                    <p className='mb-0 text-center fs-4'>school.info@skans.pk</p>
                                </div>
                            </div>

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>


                                <div className='col-3'>
                                    <i class="bi bi-geo-alt-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>

                                <div className=''>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0  fs-5'>House No 53-B Harley Street Campus – Rawalpindi</p>
                                </div>

                            </div>
                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>

                                <div className='col-3'>
                                    <i class="bi bi-phone-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>
                                <div className=' text-center py-2'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4 '>(+92)-332-8922225</p>
                                </div>

                            </div>
                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox '>

                                <div className='col-3'>
                                    <i class="bi bi-telephone-inbound-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill"></i> */}
                                </div>
                                <div className=' text-center'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4'>(+92)-51-5176138</p>
                                    <p className='mb-0 text-center fs-4'>(+92)-51-5176066</p>

                                </div>

                            </div>

                        </div>
                        <div className='col-12 col-lg-6 '>
                            <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1701960.273841957!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692351114046!5m2!1sen!2sus" width="550" height="470" style={{ border: 0, borderRadius: "10px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                </div>





                <div className='container  rounded-4' data-aos="fade-up">
                    <div className='d-flex flex-wrap mt-5  mb-5 '>


                        <div className='d-flex flex-column order-lg-end col-12 col-lg-6 bglightblue p-4 rounded-end-4'>
                            <p className='fs-4 text-center text-white fw-bold text-uppercase mb-2'>Senior Campus Harley</p>
                            {/* <div className='border border-white p-1 rounded-5'> */}

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>


                                <i class="bi bi-envelope-plus-fill fs-2 ps-3 me-md-5 me-2"></i>



                                <div className=' text-center'>
                                    <p className='mb-0 text-center fs-4'>school.info@skans.pk</p>
                                </div>
                            </div>

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>


                                <div className='col-3'>
                                    <i class="bi bi-geo-alt-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>

                                <div className=''>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0  fs-5'>House 76/3, Lane 5, Harley Street Campus – Rawalpindi</p>
                                </div>

                            </div>
                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>

                                <div className='col-3'>
                                    <i class="bi bi-phone-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>
                                <div className=' text-center py-2'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4 '>(+92)-51-5176257</p>
                                </div>

                            </div>
                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox '>

                                <div className='col-3'>
                                    <i class="bi bi-telephone-inbound-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill"></i> */}
                                </div>
                                <div className=' text-center'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4'>(+92)-51-5176258</p>
                                    {/* <p className='mb-0 text-center fs-4'>(+92)-51-5176066</p> */}

                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6 order-lg-first rounded-start-4'>
                            <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13296.31677696619!2d73.041392!3d33.577293!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9377bfa6f61b%3A0xaf16261408bd3dda!2sSKANS%20Schools%20Senior%20Campus!5e0!3m2!1sen!2sus!4v1692425135896!5m2!1sen!2sus" width="500" height="450" style={{ border: 0, }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>






                <div className='container  rounded-4' data-aos="fade-up">
                    <div className='d-flex flex-wrap mt-5  mb-5 '>


                        <div className='d-flex flex-column col-12 col-lg-6 bglightblue p-4 rounded-end-4'>
                            <p className='fs-4 text-center text-white fw-bold text-uppercase mb-2'>Gulzar-e-Quaid Campus</p>
                            {/* <div className='border border-white p-1 rounded-5'> */}

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>

                                <div className='col-3'>
                                    <i class="bi bi-envelope-plus-fill fs-2 ps-md-3 ps-1 me-0"></i>
                                </div>



                                <div className=' text-center'>

                                    <p className='mb-0 text-center fs-5'>school.info@skans.pk</p>
                                </div>
                            </div>

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-4'>


                                <div className='col-3'>
                                    <i class="bi bi-geo-alt-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>

                                <div className=''>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0  fs-5'>House # 119/2, Street # 4, Sector-2. Airport Housing Society, Gulzar-e-Quaid, RWP</p>
                                </div>

                            </div>

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox '>

                                <div className='col-3'>
                                    <i class="bi bi-telephone-inbound-fill fs-2 ps-3"></i>

                                </div>
                                <div className=' text-center'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4'>(+92)-51-5497155</p>
                                    {/* <p className='mb-0 text-center fs-4'>(+92)-51-5176066</p> */}

                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6 rounded-start-4'>
                            <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13296.31677696619!2d73.041392!3d33.577293!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9377bfa6f61b%3A0xaf16261408bd3dda!2sSKANS%20Schools%20Senior%20Campus!5e0!3m2!1sen!2sus!4v1692425135896!5m2!1sen!2sus" width="500" height="450" style={{ border: 0, }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </div>

                </div>


                <div className='container  rounded-4' data-aos="fade-up">
                    <div className='d-flex flex-wrap mt-5  mb-5 '>


                        <div className='d-flex flex-column order-lg-end col-12 col-lg-6 bglightblue p-4 rounded-end-4'>
                            <p className='fs-4 text-center text-white fw-bold text-uppercase mb-0'>Junior Campus Chakri</p>
                            {/* <div className='border border-white p-1 rounded-5'> */}

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-3'>


                                <i class="bi bi-envelope-plus-fill fs-2 ps-3 me-md-5 me-2"></i>



                                <div className=' text-center'>
                                    <p className='mb-0 text-center fs-5'>school.info@skans.pk</p>
                                </div>
                            </div>

                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-3'>


                                <div className='col-3'>
                                    <i class="bi bi-geo-alt-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>

                                <div className=''>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0  fs-5'>Raja Bashir Plaza, Moza Hayal Ranail, Opp. Pir Mehar Ali Shah Town, Chakri Road Rawalpindi.</p>
                                </div>

                            </div>
                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox mb-3'>

                                <div className='col-3'>
                                    <i class="bi bi-phone-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill "></i> */}
                                </div>
                                <div className=' text-center py-2'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4 '>0310-3331131</p>
                                </div>

                            </div>
                            <div className=' d-flex align-items-center rounded-5 text-white  px-3  glassbox '>

                                <div className='col-3'>
                                    <i class="bi bi-telephone-inbound-fill fs-2 ps-3"></i>
                                    {/* <i class="bi bi-envelope-plus-fill"></i> */}
                                </div>
                                <div className=' text-center'>
                                    {/* <h5 className='fw-bold'>Email</h5> */}
                                    <p className='mb-0 text-center fs-4'>051-5575082</p>
                                    {/* <p className='mb-0 text-center fs-4'>(+92)-51-5176066</p> */}

                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6 order-lg-first rounded-start-4'>
                            <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13301.370541124024!2d73.000486!3d33.544473!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df934165521853%3A0x349b6bf1c45cc4ec!2sRaja%20Bashir%20Plaza!5e0!3m2!1sen!2sus!4v1692431535809!5m2!1sen!2sus" width="500" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>








            </div>


            <div className='bgdarkblue py-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div>
        </>
    )
}

export default ContactUs
