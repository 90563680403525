import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { backendURL } from "../../../../constant";

function SchoolNoticeBoard() {
    const navigate = useNavigate();

    const [announcement, setAnnouncement] = useState({
        title: "",
        description: "",
        date: "",
        type: ""
    });
    const [search, setSearch] = useState({ type: "" });
    const [detail, setDetail] = useState({});

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAnnouncement({ ...announcement, [name]: value });
    };

    const searchInput = (e) => {
        const { name, value } = e.target;
        setSearch({ ...search, [name]: value });
    }

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendURL}/SchoolsNoticeBoard`, announcement);
            console.log(response);
            toast.success("Data Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setAnnouncement({
                title: "",
                description: "",
                date: "",
                type: ""
            }); // Clear the input fields after successful upload
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Highlight", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    const callProfile = useCallback( async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getSchoolsNoticeboard`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);
            navigate("/AdminLogin");
        }
    },[navigate,setDetail])

    const deleteAnnouncement = async (id) => {
        try {
            const res2 = await fetch(`${backendURL}/deleteSchoolsNoticeboard/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const deletedData = await res2.json();
            console.log(deletedData);

            if (res2.status === 422 || !deletedData) {
                console.log("error");
            } else {
                toast.success("Batch Deleted Successfully", {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 2000,
                    theme: "colored",
                });

                callProfile();
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        callProfile();
    }, [callProfile]);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
                <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-center text-uppercase mb-5 fontcolordark">School Noticeboard</h1>
                    </div>
                </div>
                <form method="PATCH" className="" onSubmit={handleDataUpload} encType="multipart/form-data">
                    <div className="container d-flex flex-wrap justify-content-around">
                        <div className="col-md-5 col-12 border-right">
                            <input
                                name="title"
                                type="text"
                                id="title"
                                className="form-control"
                                placeholder="Enter or paste text here"
                                value={announcement.title}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right mt-md-0 mt-3">
                            <input
                                name="date"
                                type="text"
                                id="date"
                                className="form-control"
                                placeholder="Enter date here"
                                value={announcement.date}
                                onChange={handleInput}
                                required
                            />
                        </div>
                        <div className="col-md-5 col-12 border-right  mt-3">
                            <textarea
                                name="description"
                                type="number"
                                id="description"
                                className="form-control"
                                placeholder="Enter Description  here"
                                value={announcement.description}
                                onChange={handleInput}
                                required
                            />
                        </div>

                        <div class="input-group my-3">
                            <label class="input-group-text" for="inputGroupSelect01">Options</label>
                            <select name="type" class="form-select" id="inputGroupSelect01" value={announcement.type}
                                onChange={handleInput}>
                                <option selected>Choose...</option>
                                <option value="Harley Street Campus">Harley Street Campus</option>
                                {/* <option value="Gulzar e Quaid Campus">Gulzar e Quaid Campus</option> */}
                                <option value="Junior Chakri Road Campus">Junior Chakri Road Campus</option>
                                <option value="Junior Westridge Campus">Junior Westridge Campus</option>
                                <option value="Senior Westridge Campus">Senior Westridge Campus</option>
                                <option value="Golra Mor Campus">Golra Mor Campus</option>
                                <option value="Peshawar Road Campus">Peshawar Road Campus</option>
                                <option value="Skans PWD Campus">Skans PWD Campus</option>
                                <option value="Skans AECHS Campus">Skans AECHS Campus</option>

                            </select>
                        </div>



                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary profile-button">
                            Upload NoticeBoard
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-5 container-md container-fluid ps-0">





                <form className="" method="GET">
                    <div class="input-group mb-3">
                        <label class="input-group-text" for="inputGroupSelect01">Search</label>
                        <select name="type" class="form-select" id="inputGroupSelect01" value={search.type}
                            onChange={searchInput}>
                            <option selected value="">Search here</option>
                            <option value="Harley Street Campus">Harley Street Campus</option>
                            {/* <option value="Gulzar e Quaid Campus">Gulzar e Quaid Campus</option> */}
                            <option value="Junior Chakri Road Campus">Junior Chakri Road Campus</option>
                            <option value="Junior Westridge Campus">Junior Westridge Campus</option>
                            <option value="Senior Westridge Campus">Senior Westridge Campus</option>
                            <option value="Golra Mor Campus">Golra Mor Campus</option>
                            <option value="Peshawar Road Campus">Peshawar Road Campus</option>
                            <option value="Skans PWD Campus">Skans PWD Campus</option>
                            <option value="Skans AECHS Campus">Skans AECHS Campus</option>

                        </select>
                    </div>

                    <div className="shadow-lg">

                        <div className=' mt-5' >

                            <table class="table2 mb-5">
                                <tr class="tr2">
                                    <th class="th2 p-sm-2 p-1">Title</th>
                                    <th colspan="2" class="th2 p-sm-2 p-1">Date</th>
                                    <th class="th2 p-sm-2 p-1">Type</th>
                                    {/* <th class="th2 p-sm-2 p-1">BATCH STARTED</th>
                                    <th class="th2 p-sm-2 p-1">BATCH END</th>*/}
                                    <th class="th2 p-sm-2 p-1">Action</th>

                                </tr>
                                {Array.isArray(detail) && detail.length > 0 ? (
                                    detail.map((ann, id) => {
                                        // Add a condition to check if the type is "rwp"
                                        if (!search.type || ann.type === search.type) {
                                            return (
                                                <tr class="tr2" key={id}>
                                                    <td class="td2  p-sm-2 p-1 fw-bold">{ann.title}</td>
                                                    <td class="td2 p-sm-2 p-1">{ann.date}</td>
                                                    <td class="td2 p-lg-2 p-1"></td>
                                                    <td class="th2 p-sm-2 p-1">{ann.type}</td>
                                                    {/* <td class="th2 p-sm-2 p-1 text-success">{ann.batchstart}</td>
                                                    <td class="th2 p-sm-2 p-1 text-danger">{ann.batchend}</td>
                                                    <td class="th2 p-sm-2 p-1 text-danger">{ann.type}</td> */}
                                                    <td class="th2 d-flex justify-content-start">
                                                        <Link to={`/UpdateSchoolNoticeBoard/${ann.id}`} ><i className="bi bi-pencil-square fs-5 text-primary"></i></Link>
                                                        <Link className="ms-sm-4 ms-1" onClick={() => deleteAnnouncement(ann.id)}><i className="bi bi-trash fs-5 text-danger"></i></Link>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;



                                    })
                                ) : (

                                    <p>Not Available</p>
                                )}
                            </table>
                        </div>

                        <div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default SchoolNoticeBoard;
