import React from 'react'
import "../CSS/imagehover.css"
import "../CSS/collegetour.css"
import { Link } from 'react-router-dom'
import Sectiontitle1 from '../Photos/section-title-shape-1.png';
import Sectiontitle2 from '../Photos/section-title-shape-2.png';
import NavbarNew from '../Components/navbar';
import CollegeNavbar from '../Components/CollegeNavbar';

function CollegeTour() {
    return (


        
        <div>
            <CollegeNavbar/>
            <div className='VirtualCollegebg'>
                <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-md-1 fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>COLLEGES <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
                            <h2 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>COLLEGES <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>


                            <div className='fw-bold fs-md-1 fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container mt-5 lh-lg'>
                <p>SKANS Schools of Accountancy is a leading institution offering specialized education in accountancy and finance. With modern campuses in Rawalpindi & Islamabad, we provide students with a conducive learning environment equipped with state-of-the-art facilities. Our focus on academic excellence and professional development ensures that students receive a high-quality education and are well-prepared for successful careers in their field.</p>
            </div>
            <div className='container rounded-4'>
                <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >1</h3>
                <div className="d-flex align-items-center" data-aos="fade-up">
                    <div className="b"></div>
                    <div data-aos="zoom-in" className="mb-5 mt-2 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
                        <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 className="text-light fw-bold mb-0 text-uppercase">F8 Campus Islamabad</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                        <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
                    </div>
                    <div className="b"></div>
                </div>
                <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
                    <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
                        <div className='imghvr-slide-right'>
                            <img src={require("../Photos/F8Campus - Copy.jpg")} className='rounded-start-4 ' width="550" height="400">

                            </img>
                            <figcaption className='d-flex justify-content-center align-items-center z-3'>
                                <Link to='/F8Campus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
                            </figcaption><a href="javascript:;"></a>
                        </div>
                    </div>
                    <div className='d-flex align-items-center col-12 col-lg-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13275.589552305766!2d73.035687!3d33.711604!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbe531e328d2d%3A0x2d7c5767270ff30!2sSkans%20School%20of%20Accountancy!5e0!3m2!1sen!2sus!4v1696331851547!5m2!1sen!2sus" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </div>

            <div className='container rounded-4'>
            <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >2</h3>

                <div className="d-flex align-items-center" data-aos="fade-up">
                    <div className="b"></div>
                    <div data-aos="zoom-in" className="mt-3 mb-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
                        <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 className="text-light fw-bold mb-0 text-uppercase">Sattelite town Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                        <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
                    </div>
                    <div className="b"></div>
                </div>
                <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
                    <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
                        <div className='imghvr-slide-right'>
                            <img src={require("../Photos/STCAMPUS.png")} className='rounded-start-4 ' width="550" height="400">

                            </img>
                            <figcaption className='d-flex justify-content-center align-items-center z-3'>
                                <Link to='/SatelliteTownCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
                            </figcaption><a href="javascript:;"></a>
                        </div>
                    </div>
                    <div className='d-flex align-items-center col-12 col-lg-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d53147.89052576665!2d73.07676700000002!3d33.637901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df955662cb3185%3A0x560236c8cbc6bf77!2sSKANS%20School%20of%20Accountancy%20New%20Campus!5e0!3m2!1sen!2sus!4v1696332039984!5m2!1sen!2sus" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </div>

            <div className='container rounded-4'>
            <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >3</h3>

                <div className="d-flex align-items-center" data-aos="fade-up">
                    <div className="b"></div>
                    <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
                        <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 className="text-light fw-bold mb-0 text-uppercase">Raja Akram Road Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                        <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
                    </div>
                    <div className="b"></div>
                </div>
                <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
                    <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
                        <div className='imghvr-slide-right'>
                            <img src={require("../Photos/RAR campus.jpeg")} className='rounded-start-4 ' width="550" height="400">

                            </img>
                            <figcaption className='d-flex justify-content-center align-items-center z-3'>
                                <Link to='/RARCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
                            </figcaption><a href="javascript:;"></a>
                        </div>
                    </div>
                    <div className='d-flex align-items-center col-12 col-lg-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13292.449289700802!2d73.039474!3d33.60239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df946297a95cf3%3A0x8aead128e41e7ea8!2sSKANS%20School%20of%20Accountancy!5e0!3m2!1sen!2sus!4v1696331964422!5m2!1sen!2sus" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </div>

            <div className='container rounded-4'>
            <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >4</h3>

                <div className="d-flex align-items-center" data-aos="fade-up">
                    <div className="b"></div>
                    <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
                        <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 className="text-light fw-bold mb-0 text-uppercase">Peshawar Road Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                        <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
                    </div>
                    <div className="b"></div>
                </div>
                <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
                    <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
                        <div className='imghvr-slide-right'>
                            <img src={require("../Photos/bgpeshawarroad.jpeg")} className='rounded-start-4 ' width="550" height="400">

                            </img>
                            <figcaption className='d-flex justify-content-center align-items-center z-3'>
                                <Link to='/ACCAPeshawarRoadCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
                            </figcaption><a href="javascript:;"></a>
                        </div>
                    </div>
                    <div className='d-flex align-items-center col-12 col-lg-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106338.0499483005!2d72.94330480059205!3d33.603642286689485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df95ca5f4bb601%3A0x18bf86bcf8bfd9b1!2sJ23G%2BC7J%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.603669599999996!2d73.025706!5e0!3m2!1sen!2s!4v1711686353545!5m2!1sen!2s" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CollegeTour
