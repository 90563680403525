import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import achiever1 from "../Photos/achiever1.jpeg"
// import achiever2 from "../Photos/achiever2.jpeg"
// import achiever3 from "../Photos/achiever3.jpeg"
// import achiever4 from "../Photos/achiever4.jpeg"
// import achiever5 from "../Photos/achiever5.jpeg"
// import achiever6 from "../Photos/achiever6.jpeg"
// import "./styles.css";
import "../CSS/multiCarousel.css"
import { backendURL } from "../constant";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
// const sliderImageUrl = [
//   //First image url
//   {
//     url:
//       achiever1
//   },
//   {
//     url:
//     achiever2
//   },
//   //Second image url
//   {
//     url:
//     achiever3
//   },
//   //Third image url
//   {
//     url:
//     achiever4
//   },

//   //Fourth image url

//   {
//     url:
//     achiever5
//   },
//   {
//     url:
//     achiever6
//   },
// ];
const Slider = () => {

  const [highachiever,setHighAchiever]= useState({photos:""});

  const callAchievers = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomeHighAchiever`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          setHighAchiever(data);

        })
        .catch((error) => console.error(error));
      // const res = await fetch("/getHomeHighAchiever", {
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   credentials: "include",
      // });

      // if (res.status === 200) {
      //   const data = await res.json();
      //   console.log(data);
      //   setHighAchiever(data);
      // } else {
      //   const error = new Error(res.statusText);
      //   throw error;
      // }
    } catch (err) {
      console.log(err);

    }
  };



  useEffect(() => {

    callAchievers();
  }, []);
  const picurl = `${backendURL}/public/uploads/`;
  return (
    <div  style={{width:"100%"}}>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={2000}
        swipeable={true}
        draggable={true}
        // showDots={true}
        infinite={true}
        partialVisible={false}
        className="z-1"
        // dotListClass="custom-dot-list-style"
      >
       {Array.isArray(highachiever) && highachiever.length>0 ? highachiever.map((imageUrl, index) => {
          return (
            <div className="slider mx-sm-3 m-0" key={index}>
              <img className="img-fluid" src={picurl+imageUrl.photos } alt="movie" />
            </div>
          );
        }):(<p>loading...</p>)}
      </Carousel>
    </div>
  );
};
export default Slider;
