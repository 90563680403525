import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import { backendURL } from '../../../constant';

function UpdateScreenimage() {
    const { id } = useParams();
    console.log(id);

    const navigate = useNavigate();

    const [photos, setPhotos] = useState(null); // Initialize as null

    const handlePhotoChange = (e) => {
        setPhotos(e.target.files[0]);
    };

    const callIndScreenImage = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/ViewScreenImage/${id}`);
            const ScreenImageData = response.data;
            setPhotos(ScreenImageData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndScreenImage();
    }, [callIndScreenImage]);

    const UpdateScreenImage = async (e) => {
        e.preventDefault();
        
        try {
            const formData = new FormData();
            formData.append('photos', photos, 'postman-cloud:///1eedf7d2-e362-4330-98ec-239297a4d079');
    
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
    
            const response = await axios.patch(`${backendURL}/updateScreenImage/${id}`, formData, config);
            console.log(response.data);
            toast.success('Image Updated Successfully', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 2000,
                theme: 'colored'
            });
            console.log("Image Updated Successfully");
            setTimeout(() => {
                navigate("/HomeScreen");
            }, 2000);
        } catch (err) {
            console.error(err);
            toast.error('Image not Added. Please Try Again', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 2000,
                theme: 'colored'
            });
        }
    };
    

    const picurl = `${backendURL}/public/uploads/`;

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="mt-5">
            <div className="Head">
                    <div className="overlay">
                        <h1 className="fw-bold fs-1 text-uppercase text-center pb-3 fontcolordark">Update Home Screen Images</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="ms-5 pb-1 col-md-11 shadow-lg mb-4">
                    <form onSubmit={UpdateScreenImage}>
                        <div className="p-3">
                            <div className="ribbon786 mb-2">
                                <span className="ribbonadd2">Update Image</span>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <label className="labels">Image</label>
                                    <input
                                        name="photos"
                                        id="photo"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        className="form-control"
                                        onChange={handlePhotoChange}
                                        required
                                    />
                                </div>
                            </div>
                            {photos && (
                                <div className='mt-5'>
                                    <img className='img-fluid' width={'20%'} src={picurl + photos.photos} alt="Image" />
                                </div>
                            )}
                            <div className="my-3 text-center">
                                <button className="btn btn-primary profile-button" type="submit">Update Image</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateScreenimage;
